import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Pause, Play } from '@clds/icon';
import { ReactComponent as TimelineIcon } from '../../icons/timeline_icon.svg';

export const Video = styled.div`
  width: 100%;
  height: 100%;

  .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
    height: 100%;
  }

  .vjs-tech {
    background-color: ${globalTheme.palette.surface};
    pointer-events: none;
  }

  .cld-video-player.cld-fluid .vjs-poster {
    background-color: ${globalTheme.palette.surface};
  }

  .video-js.vjs-16-9:not(.vjs-audio-only-mode),
  .video-js.vjs-4-3:not(.vjs-audio-only-mode),
  .video-js.vjs-9-16:not(.vjs-audio-only-mode),
  .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
    padding: 0;
  }

  .vjs-paused {
    object-fit: contain;
  }

  .cld-video-player.cld-fluid .vjs-poster img {
    object-fit: contain;
  }
`;

export const Controls = styled.div`
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  user-select: none;
  background-color: ${globalTheme.palette.background};
`;

export const TimeSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const VolumeSection = styled.div`
  position: relative;
  display: flex;
  align-self: center;
`;

export const VolumeBar = styled.input`
  position: absolute;
  transform: rotate(270deg);
  width: 3rem;
  height: 0.2rem;
  bottom: 3rem;
  right: -0.9rem;
  margin: 0;
  z-index: 2;
  outline: none;

  & {
    appearance: none;
    background-color: #fffffe;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    cursor: pointer;
    background: ${globalTheme.palette.primary};
  }
`;

const PlayButtonStyle = css`
  color: ${globalTheme.palette.background};
  background-color: #fffffe;
  padding: 0.5rem;
  box-sizing: content-box;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledPlayButton = styled(Play)`
  ${PlayButtonStyle}
`;

export const StyledPauseButton = styled(Pause)`
  ${PlayButtonStyle}
`;

export const ControlsSideActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  > * {
    cursor: pointer;
  }
`;

export const StyledTimelineIcon = styled(TimelineIcon)`
  cursor: pointer;
`;

export const OnboardingVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${globalTheme.palette.surface};

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const OnboardingVideo = styled.div`
  height: 100%;

  .vjs-tech {
    height: 100%;
    background-color: ${globalTheme.palette.surface};
  }

  .video-js {
    height: inherit;
  }

  .vjs-text-track-display {
    display: none;
  }

  .vjs-fluid:not(.vjs-audio-only-mode) {
    padding: 0;
  }

  .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
    height: 100%;
  }

  .cld-video-player.cld-fluid .vjs-poster img {
    object-fit: contain;
  }
`;
