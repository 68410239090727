import React from 'react';

const FilmIcon = ({ fillColor = '#D2D6DF', width = '12', height = '12' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
  >
    <mask id="mask0_572_5534" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <rect width="12" height="12" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_572_5534)">
      <path
        d="M2 10.5V1.5H3V2.5H4V1.5H8V2.5H9V1.5H10V10.5H9V9.5H8V10.5H4V9.5H3V10.5H2ZM3 8.5H4V7.5H3V8.5ZM3 6.5H4V5.5H3V6.5ZM3 4.5H4V3.5H3V4.5ZM8 8.5H9V7.5H8V8.5ZM8 6.5H9V5.5H8V6.5ZM8 4.5H9V3.5H8V4.5ZM5 9.5H7V2.5H5V9.5Z"
        fill={fillColor}
      />
    </g>
  </svg>
);

export default FilmIcon;
