import React, { useState, useEffect } from 'react';
import Typography from '@clds/typography';
import { useDropzone } from 'react-dropzone';
import { Search } from '@clds/icon';
import { ToastContainer } from 'react-toastify';
import WizardSteps from '../../WizardSteps';
import OnboardingTrackingPreviewPlayer from '../../../Player/OnboardingTrackingPreviewPlayer';
import Timeline from './Timeline';
import { ReactComponent as SearchByImage } from '../../assets/search_by_image.svg';
import { fetchAndParseVtt } from '../../../../utils/parseVttDataToTimeframes';
import { useVideoUpload } from '../../../../hooks/useVideoUpload';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import useImageUploadAndCreateVTT from '../../../../hooks/useImageUploadAndCreateVTT';
import useTrackAnythingByHintAndCreateVTT from '../../../../hooks/useTrackAnythingByHintAndCreateVTT';
import { getImageUrlByPublicId } from '../../../../utils/getUrlByPublicId';

import {
  Content,
  Wrapper,
  Banner,
  Container,
  PlayerWrapper,
  Rows,
  LoadingLabel,
  Row,
  Label,
  RightSideWrapper,
  Actions,
  InputWrapper,
  StyledInput,
  SearchIconWrapper,
  StyledButton,
  NextButtonWrapper,
  NextButton,
  ImageSearchPreviewWrapper,
  ImageSearchPreview,
  Spinner,
  GeneralLoadingWrapper,
  LoadingWrapper
} from './Track.styled';
import { AnimatedTypography } from '../../../Timeline/Timeline.styled';

const loadingMessages = [
  'Preparing the video...',
  'Fetching tracking data...',
  'Analyzing video frames...',
  'Mapping object movements...',
  'Applying interactive elements...'
];

const GeneralLoadingSection = () => {
  const [fetchingStatusMessage, setFetchingStatusMessage] = useState(loadingMessages[0]);

  useEffect(() => {
    const timeouts = loadingMessages.map((message, index) =>
      setTimeout(() => setFetchingStatusMessage(message), 5000 * (index + 1))
    );
    return () => timeouts.forEach(clearTimeout);
  }, []);
  return (
    <GeneralLoadingWrapper>
      <Spinner />
      <Typography size="lg">This may take a few minutes, but it will be worth the wait.</Typography>
      <AnimatedTypography>{fetchingStatusMessage}</AnimatedTypography>
    </GeneralLoadingWrapper>
  );
};

const Track = ({ defaultIsFetchingVtts = true }) => {
  const [timeframesData, setTimeframesData] = useState(null);
  const [customTrackingTimeframesData, setCustomTrackingTimeframesData] = useState({});
  const [selectedTrackingObject, setSelectedTrackingObject] = useState({
    name: null,
    vttUrl: null,
    firstAppearanceTime: null
  });

  const [isFetchingVtts, setIsFetchingVtts] = useState(defaultIsFetchingVtts);
  const [interactionAreaPluginState, setInteractionAreaPluginState] = useState(null);
  const [vttByImage, setVttByImage] = useState(null);
  const [vttByHint, setVttByHint] = useState(null);
  const [objectNameToTrack, setObjectNameToTrack] = useState('');

  const onSetInteractionAreaPlugin = interactionAreaPlugin => {
    setInteractionAreaPluginState(interactionAreaPlugin);
  };

  const {
    config,
    currentStep,
    vttsData,
    goToNextStep,
    persistDataInOnboardingState,
    isMobileDevice,
    setMobileBreakpoint
  } = useOnboarding();

  const { isUploading } = useVideoUpload();
  const {
    uploadImageAndCreateVTT,
    isUploading: isImageUploading,
    isCreatingVtt: isCreatingVttByImageTracking,
    imagePublicId
  } = useImageUploadAndCreateVTT(config);

  const { createVttByHint, isCreatingVtt: isCreatingVttByHintTracking } =
    useTrackAnythingByHintAndCreateVTT(config);

  const isCreatingVtt = isCreatingVttByImageTracking || isCreatingVttByHintTracking;

  const onObjectToTrack = async () => {
    try {
      const vttByHint = await createVttByHint({ objectNameToTrack });
      setObjectNameToTrack('');
      setVttByHint(vttByHint);
    } catch (error) {
      console.error('Error in onObjectToTrack:', error);
    }
  };

  const persistStateAndGoToNextStep = () => {
    const vttByImageKey =
      vttByImage && Object.keys(vttByImage).length > 0 ? Object.keys(vttByImage)[0] : null;
    const vttByHintKey =
      vttByHint && Object.keys(vttByHint).length > 0 ? Object.keys(vttByHint)[0] : null;

    const newConfig = {
      ...config,
      objectDescription: objectNameToTrack || selectedTrackingObject.name,
      vtt: selectedTrackingObject.vttUrl,
      objectTrackingResultsWithVtts: encodeURIComponent(JSON.stringify(vttsData)),
      ...(imagePublicId && {
        hotspotActionTypeImageUrl: getImageUrlByPublicId(imagePublicId)
      }),
      ...(vttByImageKey &&
        selectedTrackingObject.name === vttByImageKey && {
          vttByImageData: encodeURIComponent(JSON.stringify(vttByImage))
        }),
      ...(vttByHintKey &&
        selectedTrackingObject.name === vttByHintKey && {
          vttByHintData: encodeURIComponent(JSON.stringify(vttByHint))
        })
    };

    persistDataInOnboardingState(newConfig);

    goToNextStep();
  };

  useEffect(() => {
    setMobileBreakpoint(700);
  }, [setMobileBreakpoint]);

  useEffect(() => {
    const fetchAllVtts = async () => {
      setIsFetchingVtts(true);
      const allTimeframes = {};

      await Promise.all(
        vttsData.map(async vttObject => {
          if (!vttObject) {
            return null;
          }

          const objectName = Object.keys(vttObject)[0];
          const vttUrl = vttObject[objectName];
          if (vttUrl) {
            const timeframes = await fetchAndParseVtt(vttUrl);
            allTimeframes[objectName] = timeframes;
          }
        })
      );

      setTimeframesData(allTimeframes);
      setIsFetchingVtts(false);
    };

    if (vttsData.length > 0) {
      fetchAllVtts();
    } else {
      setIsFetchingVtts(false);
    }
  }, [vttsData]);

  useEffect(() => {
    const addVttByImageToTimeframes = async () => {
      if (vttByImage) {
        const objectName = Object.keys(vttByImage)[0];
        const vttUrl = vttByImage[objectName];
        if (vttUrl) {
          const newTimeframes = await fetchAndParseVtt(vttUrl).catch(error => {
            console.error('Error fetching or parsing VTT:', error);
          });
          setCustomTrackingTimeframesData(prevTimeframesData => ({
            [objectName]: newTimeframes,
            ...prevTimeframesData
          }));
        }
      }
    };

    addVttByImageToTimeframes();
  }, [vttByImage]);

  useEffect(() => {
    const addVttByHintToTimeframes = async () => {
      if (vttByHint) {
        const objectName = Object.keys(vttByHint)[0];
        const vttUrl = vttByHint[objectName];
        if (vttUrl) {
          const newTimeframes = await fetchAndParseVtt(vttUrl).catch(error => {
            console.error('Error fetching or parsing VTT:', error);
          });
          setCustomTrackingTimeframesData(prevTimeframesData => ({
            [objectName]: newTimeframes,
            ...prevTimeframesData
          }));
        }
      }
    };

    addVttByHintToTimeframes();
  }, [vttByHint]);

  const getVttUrlByName = objectName => {
    const objectEntryInList = vttsData.find(entry => entry.hasOwnProperty(objectName));
    return objectEntryInList
      ? objectEntryInList[objectName]
      : vttByImage?.[objectName] || vttByHint?.[objectName];
  };

  const onFileSelected = async acceptedFiles => {
    const file = acceptedFiles[0];
    if (!file) {
      console.error('No file selected');
      return;
    }
    const vttData = await uploadImageAndCreateVTT(file);
    setVttByImage(vttData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileSelected,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    }
  });

  return (
    <>
      <Content>
        <ToastContainer />
        {!isMobileDevice && (
          <WizardSteps currentStep={currentStep} isVideoUploading={isUploading} />
        )}
        <Wrapper>
          {!isMobileDevice && (
            <Banner>
              <Typography size="xxl">Track Anything</Typography>
              <Typography size="md">Next-Gen AI-Powered Object Tracking Technology</Typography>
            </Banner>
          )}
          <Container>
            <PlayerWrapper>
              <OnboardingTrackingPreviewPlayer
                onSetInteractionAreaPlugin={onSetInteractionAreaPlugin}
                initialTimestamp={selectedTrackingObject.firstAppearanceTime}
              />
              {((isFetchingVtts && !timeframesData) || !vttsData.length) && <LoadingWrapper />}
            </PlayerWrapper>

            <RightSideWrapper>
              <>
                <Typography>
                  Looking for something specific? Type the object name or upload its image.
                </Typography>
                <Actions>
                  <InputWrapper>
                    <StyledInput
                      placeholder="Search by object name"
                      value={objectNameToTrack}
                      onChange={e => setObjectNameToTrack(e.target.value)}
                      onKeyDown={async e => {
                        if (e.key === 'Enter') onObjectToTrack();
                      }}
                      disabled={!config.publicId || isCreatingVtt}
                    />
                    <SearchIconWrapper onClick={() => onObjectToTrack()}>
                      <Search size="sm" />
                    </SearchIconWrapper>
                  </InputWrapper>
                  Or
                  <div style={{ width: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <StyledButton disabled={!config.publicId || isCreatingVtt}>
                      {isImageUploading ? (
                        'Loading...'
                      ) : (
                        <>
                          <SearchByImage /> Search by image
                        </>
                      )}
                    </StyledButton>
                  </div>
                </Actions>
                {!isMobileDevice && (
                  <>
                    {(isCreatingVtt ||
                      timeframesData ||
                      customTrackingTimeframesData.length > 0) && (
                      <Typography>Here are the objects we detected:</Typography>
                    )}
                    <Rows>
                      {isCreatingVtt && (
                        <Row disabled>
                          {isCreatingVttByHintTracking && (
                            <>
                              <Label>{objectNameToTrack}</Label>
                              <LoadingLabel>
                                <Spinner />
                                <Typography>Mapping Object Movements</Typography>
                              </LoadingLabel>
                            </>
                          )}

                          {isCreatingVttByImageTracking && (
                            <span style={{ margin: '1rem' }}>
                              <LoadingLabel>
                                <Spinner />
                                <Typography>Mapping Object Movements</Typography>
                              </LoadingLabel>
                            </span>
                          )}
                        </Row>
                      )}

                      {Object.entries(customTrackingTimeframesData).map(
                        ([objectName, timeframes]) => (
                          <Row
                            key={objectName}
                            onClick={() => {
                              const vttUrl = getVttUrlByName(objectName);
                              const firstAppearanceTime =
                                customTrackingTimeframesData[objectName][0].startTime;
                              console.log(
                                `First appearance of ${objectName} is at ${firstAppearanceTime} seconds`
                              );

                              setSelectedTrackingObject({
                                name: objectName,
                                vttUrl,
                                firstAppearanceTime
                              });
                              interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
                                [vttUrl],
                                true,
                                config.hotspotId
                              ); // second param for isOnboarding flag
                            }}
                            $isSelected={selectedTrackingObject.name === objectName}
                          >
                            {objectName !== imagePublicId && (
                              <Label>
                                {objectName.charAt(0).toUpperCase() + objectName.slice(1)}
                              </Label>
                            )}

                            {objectName === imagePublicId && (
                              <ImageSearchPreviewWrapper>
                                <ImageSearchPreview
                                  alt=""
                                  src={getImageUrlByPublicId(imagePublicId)}
                                />
                              </ImageSearchPreviewWrapper>
                            )}
                            {config.videoDuration && timeframes && (
                              <Timeline timeframes={timeframes} />
                            )}
                          </Row>
                        )
                      )}
                      {timeframesData &&
                        Object.entries(timeframesData)
                          .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                          .map(([objectName, timeframes]) => (
                            <Row
                              key={objectName}
                              onClick={() => {
                                const vttUrl = getVttUrlByName(objectName);
                                const firstAppearanceTime = timeframesData[objectName][0].startTime;
                                console.log(
                                  `First appearance of ${objectName} is at ${firstAppearanceTime} seconds`
                                );

                                setSelectedTrackingObject({
                                  name: objectName,
                                  vttUrl,
                                  firstAppearanceTime
                                });
                                interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
                                  [vttUrl],
                                  true,
                                  config.hotspotId
                                ); // second param for isOnboarding flag
                              }}
                              $isSelected={selectedTrackingObject.name === objectName}
                            >
                              <Label>
                                {objectName.charAt(0).toUpperCase() + objectName.slice(1)}
                              </Label>
                              <Timeline
                                timeframes={timeframes}
                                videoDuration={config.originalDurationSeconds}
                              />
                            </Row>
                          ))}
                    </Rows>
                  </>
                )}

                {((isFetchingVtts && !timeframesData) || !vttsData.length) && (
                  <GeneralLoadingSection />
                )}

                {!isFetchingVtts && timeframesData && isMobileDevice && (
                  <div style={{ padding: '2rem' }}>
                    <Typography>
                      Looking for something specific? Type the object name or upload its image.
                    </Typography>
                    <Actions>
                      <InputWrapper>
                        <StyledInput
                          placeholder="Search by object name"
                          value={objectNameToTrack}
                          onChange={e => setObjectNameToTrack(e.target.value)}
                          onKeyDown={async e => {
                            if (e.key === 'Enter') {
                              await onObjectToTrack();
                            }
                          }}
                          disabled={!config.publicId || isCreatingVtt}
                        />
                        <SearchIconWrapper
                          onClick={async () => {
                            await onObjectToTrack();
                          }}
                        >
                          <Search size="sm" />
                        </SearchIconWrapper>
                      </InputWrapper>
                      Or
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <StyledButton disabled={!config.publicId || isCreatingVtt}>
                          {isImageUploading ? (
                            'Loading...'
                          ) : (
                            <>
                              <SearchByImage /> Search by image
                            </>
                          )}
                        </StyledButton>
                      </div>
                    </Actions>
                    &nbsp;
                    <Typography>Here are the objects we detected:</Typography>
                    <Rows>
                      {isCreatingVtt && (
                        <Row>
                          {isCreatingVttByHintTracking && (
                            <>
                              <Label>
                                {objectNameToTrack.charAt(0).toUpperCase() +
                                  objectNameToTrack.slice(1)}
                              </Label>
                              <LoadingLabel>
                                <Spinner />
                                <Typography>Mapping Object Movements</Typography>
                              </LoadingLabel>
                            </>
                          )}

                          {isCreatingVttByImageTracking && (
                            <span style={{ margin: '1rem' }}>
                              <LoadingLabel>
                                <Spinner />
                                <Typography>Mapping Object Movements</Typography>
                              </LoadingLabel>
                            </span>
                          )}
                        </Row>
                      )}

                      {Object.entries(customTrackingTimeframesData).map(
                        ([objectName, timeframes]) => (
                          <Row
                            key={objectName}
                            onClick={() => {
                              const vttUrl = getVttUrlByName(objectName);
                              const firstAppearanceTime =
                                customTrackingTimeframesData[objectName][0].startTime;
                              console.log(
                                `First appearance of ${objectName} is at ${firstAppearanceTime} seconds`
                              );

                              setSelectedTrackingObject({
                                name: objectName,
                                vttUrl,
                                firstAppearanceTime
                              });
                              interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
                                [vttUrl],
                                true,
                                config.hotspotId
                              ); // second param for isOnboarding flag
                            }}
                            $isSelected={selectedTrackingObject.name === objectName}
                          >
                            {objectName !== imagePublicId && (
                              <Label>
                                {objectName.charAt(0).toUpperCase() + objectName.slice(1)}
                              </Label>
                            )}

                            {objectName === imagePublicId && (
                              <ImageSearchPreviewWrapper>
                                <ImageSearchPreview
                                  alt=""
                                  src={getImageUrlByPublicId(imagePublicId)}
                                />
                              </ImageSearchPreviewWrapper>
                            )}
                            {config.videoDuration && timeframes && (
                              <Timeline timeframes={timeframes} />
                            )}
                          </Row>
                        )
                      )}
                      {Object.entries(timeframesData)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                        .map(([objectName, timeframes]) => (
                          <Row
                            key={objectName}
                            onClick={() => {
                              const vttUrl = getVttUrlByName(objectName);
                              const firstAppearanceTime = timeframesData[objectName][0].startTime;
                              console.log(
                                `First appearance of ${objectName} is at ${firstAppearanceTime} seconds`
                              );

                              setSelectedTrackingObject({
                                name: objectName,
                                vttUrl,
                                firstAppearanceTime
                              });
                              interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
                                [vttUrl],
                                true,
                                config.hotspotId
                              ); // second param for isOnboarding flag
                            }}
                            $isSelected={selectedTrackingObject.name === objectName}
                          >
                            <Label>
                              {objectName.charAt(0).toUpperCase() + objectName.slice(1)}
                            </Label>
                            <Timeline
                              timeframes={timeframes}
                              videoDuration={config.originalDurationSeconds}
                            />
                          </Row>
                        ))}
                    </Rows>
                  </div>
                )}
              </>
            </RightSideWrapper>
            {isMobileDevice && (
              <NextButtonWrapper isMobileDevice={isMobileDevice}>
                <NextButton
                  $isVisible={selectedTrackingObject.name && selectedTrackingObject.vttUrl}
                  onClick={persistStateAndGoToNextStep}
                >
                  Next
                </NextButton>
              </NextButtonWrapper>
            )}
          </Container>
          {!isMobileDevice && (
            <NextButtonWrapper isMobileDevice={isMobileDevice}>
              <NextButton
                $isVisible={selectedTrackingObject.name && selectedTrackingObject.vttUrl}
                onClick={persistStateAndGoToNextStep}
              >
                Next
              </NextButton>
            </NextButtonWrapper>
          )}
        </Wrapper>
      </Content>
    </>
  );
};

export default Track;
