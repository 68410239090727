import { useState } from 'react';
import { toast } from 'react-toastify';
import { INTERACTIVE_VIDEO_API } from '../const';

const useImageUploadAndCreateVTT = config => {
  const [isUploading, setIsUploading] = useState(false);
  const [isCreatingVtt, setIsCreatingVtt] = useState(false);
  const [error, setError] = useState(null);
  const [imagePublicId, setImagePublicId] = useState(null);

  const uploadImage = async file => {
    const formData = new FormData();
    formData.append('image', file);

    setIsUploading(true);

    try {
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Image upload successful', data);

        setIsUploading(false);
        return data.publicId;
      } else {
        console.error('Image upload failed', response);
      }
    } catch (error) {
      console.error('Error during an image upload', error);
      toast.error('Error during image upload.');
    } finally {
      setIsUploading(false);
    }
  };

  const uploadImageAndCreateVTT = async file => {
    setError(null);

    try {
      const imagePublicId = await uploadImage(file);
      if (!imagePublicId) {
        throw new Error('Image upload failed, no public ID returned');
      }

      setImagePublicId(imagePublicId);
      setIsCreatingVtt(true);

      const response = await fetch(`${INTERACTIVE_VIDEO_API}/track-anything-by-image-or-hint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          videoPublicId: config.publicId,
          imagePublicId
        })
      });

      if (!response.ok) {
        setIsCreatingVtt(false);
        toast.error('Failed to process image.');
        throw new Error('Failed to process image and video IDs');
      }

      const result = await response.json();
      const objectTrackingResultsUrl = result.trackAnythingResultsUrl;

      const vttResponse = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt-from-track-anything`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          objectTrackingResultsUrl,
          hotspotId: config.hotspotId
        })
      });

      if (!vttResponse.ok) {
        setIsCreatingVtt(false);
        throw new Error('Failed to create VTT file');
      }

      const vttResult = await vttResponse.json();
      setIsCreatingVtt(false);

      return { [imagePublicId]: vttResult.vttUrl };
    } catch (error) {
      setError(error);
    } finally {
      setIsUploading(false);
    }
  };

  return { uploadImageAndCreateVTT, isUploading, isCreatingVtt, error, imagePublicId };
};

export default useImageUploadAndCreateVTT;
