import React, { useState, useEffect } from 'react';
import Typography from '@clds/typography';
import { Button } from '@clds/button';
import { Copy, Share } from '@clds/icon';
import Tooltip from '@clds/tooltip';
import ReactConfetti from 'react-confetti';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import Player from '../../../Player';
import { CLOUD_NAME } from '../../../../const';
import { openPlayerInNewTab } from '../../../../utils/utils';

import {
  Root,
  Banner,
  Text,
  PlayerWrapper,
  Buttons,
  StyledShareButton,
  ShareModalRoot,
  ShareModalHeading,
  ShareModalCloseIcon,
  ShareModalLinkWrapper,
  ShareModalLink,
  ShareModalCopyIconWrapper,
  StyledAnchor
} from './Preview.styled';

const confettiColors = ['#9593fe'];

const ShareModal = ({ link, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const [isCopied, setIsCopied] = useState(false);

  return (
    <ShareModalRoot>
      <ShareModalHeading>
        <Typography size="md">Share your video</Typography>
        <ShareModalCloseIcon onClick={onClose}>✕</ShareModalCloseIcon>
      </ShareModalHeading>
      <ShareModalLinkWrapper>
        <ShareModalCopyIconWrapper>
          {!isCopied && <Copy onClick={() => copyToClipboard(link)} />}
          {isCopied && (
            <Tooltip placement={'bottom'} title={'Copied'}>
              <Copy />
            </Tooltip>
          )}
        </ShareModalCopyIconWrapper>

        <ShareModalLink href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </ShareModalLink>
      </ShareModalLinkWrapper>
      <Text>
        <Typography>
          IVE Studio on
          <StyledAnchor
            href="https://www.producthunt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Product Hunt
          </StyledAnchor>
        </Typography>
        <Typography>We’d greatly appreciate your feedback and support.</Typography>
      </Text>
    </ShareModalRoot>
  );
};

const Preview = ({ closeOnboarding }) => {
  const { config, isMobileDevice } = useOnboarding();

  const [interactionAreaPluginState, setInteractionAreaPluginState] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const onSetInteractionAreaPlugin = interactionAreaPlugin => {
    setInteractionAreaPluginState(interactionAreaPlugin);
  };

  useEffect(() => {
    if (interactionAreaPluginState) {
      const vttUrl = config.vtt;
      setTimeout(() => {
        interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
          [vttUrl],
          true,
          config.hotspotId
        );
      }, 3000);
    }
  }, [config, interactionAreaPluginState]);

  const { playerLink } = openPlayerInNewTab(config);

  return (
    <Root>
      <Banner>
        <Text>
          <Typography size={isMobileDevice ? 'xl' : 'xxl'}>VIDEO READY!</Typography>
          {!isMobileDevice && (
            <Typography size={'md'}>
              Watch your interactive video come to life! Share it with your audience and let the
              engagement begin.
            </Typography>
          )}
        </Text>
        <Buttons>
          <Button variant="secondary" onClick={() => closeOnboarding({ config })}>
            Edit in studio
          </Button>
          <StyledShareButton onClick={() => setIsShareModalOpen(true)}>
            <Share />
            Share link
          </StyledShareButton>
        </Buttons>

        {isShareModalOpen && (
          <ShareModal link={playerLink} onClose={() => setIsShareModalOpen(false)} />
        )}
      </Banner>
      <PlayerWrapper>
        <ReactConfetti
          colors={confettiColors}
          width={window.screen.width}
          height={window.screen.height}
          numberOfPieces={700}
          tweenDuration={3000}
          recycle={false}
        />

        <Player
          cloud={CLOUD_NAME}
          onOnboardingSetInteractionAreaPlugin={onSetInteractionAreaPlugin}
          isOnboarding={true}
          isLastStepOnboarding={true}
          onboardingConfig={config}
        />
      </PlayerWrapper>
    </Root>
  );
};

export default Preview;
