import React, { useState } from 'react';
import styled from 'styled-components';
import { PaletteModal, PaletteRow } from '../../../EditHotspot/ThemeColorsPreview';

const ColorSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: center;
`;

const PaletteRowWrapper = styled.div`
  position: relative;
`;

export const ColorSection = ({ hotspotTheme, onHotspotThemeChange, isOnboarding = false }) => {
  const [isPaletteModalOpen, setIsPaletteModalOpen] = useState(false);

  return (
    <ColorSectionWrapper>
      <PaletteRowWrapper onClick={() => setIsPaletteModalOpen(!isPaletteModalOpen)}>
        <PaletteRow selectedTheme={hotspotTheme} />
        {isPaletteModalOpen && (
          <PaletteModal onHotspotThemeChange={onHotspotThemeChange} isOnboarding={isOnboarding} />
        )}
      </PaletteRowWrapper>
    </ColorSectionWrapper>
  );
};
