import React, { useEffect, useRef, useState } from 'react';
import ReactRuler from '@scena/react-ruler';
import { globalTheme, useMixin } from '@clds/component-theme';

export const Ruler = ({ totalDuration }) => {
  const rulerRef = useRef(null);
  const [unit, setUnit] = useState(0);
  const { surfaceAlt } = useMixin(globalTheme.palette);

  useEffect(() => {
    const resize = () => {
      rulerRef.current.resize();
      setUnit(rulerRef.current.width / totalDuration);
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [totalDuration]);

  return (
    <ReactRuler
      textFormat={scale => `${Math.ceil(scale / unit)}s`}
      backgroundColor={surfaceAlt}
      zoom={1}
      unit={unit}
      height={30}
      ref={rulerRef}
      type="horizontal"
    />
  );
};
