import isElement from 'lodash/isElement';
import castArray from 'lodash/castArray';
import { setInteractionAreasContainer } from '../plugins/interaction-area/interaction-area.utils';
import { INTERACTION_AREAS_CONTAINER_CLASS_NAME } from '../plugins/interaction-area/interaction-area.const';

export const createElement = (elementName, attributes = {}, children) => {
  const element = document.createElement(elementName);

  for (let key in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, key)) {
      element.setAttribute(key, attributes[key]);
    }
  }

  castArray(children).forEach(child => appendChild(child, element));

  return element;
};

const appendChild = (child, element) => {
  if (isElement(child)) {
    element.appendChild(child);
  } else if (child && typeof child !== 'object') {
    const text = document.createTextNode(child);
    element.appendChild(text);
  }
};

export const styleElement = (element, style) => {
  for (let key in style) {
    if (Object.prototype.hasOwnProperty.call(style, key)) {
      element.style[key] = style[key];
    }
  }

  return element;
};

export const elementsCreator = item => {
  const children = Array.isArray(item.children)
    ? item.children.map(elementsCreator)
    : item.children;

  const element = isElement(item) ? item : createElement(item.tag, item.attr, children);

  if (item.onClick) {
    item.event = { name: 'click', callback: item.onClick };
  }

  if (item.event) {
    element.addEventListener(item.event.name, item.event.callback, false);
  }

  if (item.style) {
    styleElement(element, item.style);
  }

  return element;
};

export const addEventListener = (element, name, cb) => {
  element.addEventListener(name, cb, false);

  return () => {
    element.removeEventListener(name, cb, false);
  };
};

export const setCloudinaryWatermark = player => {
  const { videoHeight, videoWidth, clientHeight, clientWidth } = player.videoElement;

  const videoAspectRatio = videoWidth / videoHeight;
  const width = videoAspectRatio * clientHeight;

  const container = elementsCreator({
    tag: 'a',
    attr: { id: 'watermark', href: 'https://ive.cloudinary.com', target: '_blank' },
    style: {
      position: 'absolute',
      width: '6rem',
      bottom: 0,
      right: 0,
      margin: '0.6rem',
      cursor: 'pointer'
    },
    children: [
      {
        tag: 'img',
        attr: {
          src: 'https://res.cloudinary.com/prod/image/upload/v1714121881/video-player/interactive-video/cloudinary_logo.png',
          alt: 'Cloudinary IVE'
        }
      }
    ]
  });

  const interactionAreasContainer = document.querySelector(INTERACTION_AREAS_CONTAINER_CLASS_NAME);

  if (!interactionAreasContainer) {
    setInteractionAreasContainer(
      player.videojs,
      createElement(
        'div',
        {
          class: INTERACTION_AREAS_CONTAINER_CLASS_NAME,
          style: `width: ${clientWidth < width ? '100%' : `${width}px`}; height: ${
            clientWidth < width ? `${clientWidth / videoAspectRatio}px` : '100%'
          }`
        },
        container
      )
    );
  }

  return container;
};
