import React, { useState } from 'react';
import { Button } from '@clds/button';
import Typography from '@clds/typography';
import capitalize from 'lodash/capitalize';
import { ToastContainer } from 'react-toastify';
import TrackingIcon from '../../icons/TrackingIconBig';
import { ReactComponent as SelectIcon } from '../../icons/select_icon.svg';
import { ReactComponent as ColorIcon } from '../../icons/color_icon.svg';
import { useStore } from '../../utils/store';
import StyleTabContent from './StyleTabContent';
import InteractionTabContent from './InteractionTabContent';
import TrackAnythingTabContent from './TrackAnythingTabContent/TrackAnythingTabContent';
import useEditHotspot from './useEditHotspot';
import { PanelHeader, PreviewSection } from '../shared.style';
import { Header } from '../Header/Header';
import { useVideoUpload } from '../../hooks/useVideoUpload';
import useResponsiveMediaQuery from '../../hooks/useResponsiveMediaQuery';
import { openPlayerInNewTab } from '../../utils/utils';
import { CollapsibleHeader, CollapsibleSection } from '../CollapsibleSection/CollapsibleSection';

import {
  Root,
  StyledArrowBackIcon,
  Content,
  PlayerWrapper,
  TimelineWrapper,
  StyledSpinner,
  StyledCollapsibleWrapper,
  StyledPanel,
  StyledNoneCollapsibleWrapper
} from './EditHotspot.styled';

export const EditHotspot = ({ player, timeline }) => {
  const { store } = useStore();
  const { objectDescription, navigateBackToPreview, videoContainerRef } = useEditHotspot();
  const { isUploading, isObjectTracking } = useVideoUpload();

  const isSmallDevice = useResponsiveMediaQuery('(max-width: 700px)');

  const isLoading = isUploading || isObjectTracking;
  const { openPlayerInNewTab: onOpenPlayerInNewTab } = openPlayerInNewTab();

  const [isInteractionSectionOpen, setIsInteractionSectionOpen] = useState(true);
  const [isTrackAnythingSectionOpen, setIsTrackAnythingSectionOpen] = useState(true);
  const [isStyleSectionOpen, setIsStyleSectionOpen] = useState(true);

  if (isSmallDevice) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography>IVE studio is not available on mobile devices.</Typography>
      </div>
    );
  }

  return (
    <Root>
      <ToastContainer />
      <Header rightSlot={<Button onClick={onOpenPlayerInNewTab}>Export</Button>} withSeparator />
      <Content>
        <StyledPanel>
          <StyledNoneCollapsibleWrapper>
            <PanelHeader>
              <StyledArrowBackIcon size="lg" onClick={navigateBackToPreview} />
              <Typography>{capitalize(objectDescription) || 'Edit hotspot'}</Typography>
            </PanelHeader>
          </StyledNoneCollapsibleWrapper>

          <StyledCollapsibleWrapper>
            <CollapsibleSection
              trigger={
                <CollapsibleHeader
                  isOpen={isInteractionSectionOpen}
                  icon={<SelectIcon />}
                  text="Interaction"
                />
              }
              isOpen={isInteractionSectionOpen}
              setIsOpen={setIsInteractionSectionOpen}
            >
              <InteractionTabContent />
            </CollapsibleSection>

            <div
              style={{
                border: '0.5px solid #535f7a',
                width: '100%',
                marginBottom: '1rem',
                marginTop: isInteractionSectionOpen ? 0 : '1rem'
              }}
            />

            <CollapsibleSection
              trigger={
                <CollapsibleHeader
                  isOpen={isTrackAnythingSectionOpen}
                  icon={<TrackingIcon />}
                  text="Track anything"
                />
              }
              isOpen={isTrackAnythingSectionOpen}
              setIsOpen={setIsTrackAnythingSectionOpen}
            >
              <TrackAnythingTabContent />
            </CollapsibleSection>

            <div
              style={{
                border: '0.5px solid #535f7a',
                width: '100%',
                marginBottom: '1rem',
                marginTop: isTrackAnythingSectionOpen ? 0 : '1rem'
              }}
            />

            <CollapsibleSection
              trigger={
                <CollapsibleHeader isOpen={isStyleSectionOpen} icon={<ColorIcon />} text="Style" />
              }
              isOpen={isStyleSectionOpen}
              setIsOpen={setIsStyleSectionOpen}
            >
              <StyleTabContent />
            </CollapsibleSection>
          </StyledCollapsibleWrapper>

          {isLoading && <StyledSpinner />}
        </StyledPanel>

        <PreviewSection>
          <PlayerWrapper ref={videoContainerRef}>{player}</PlayerWrapper>
          {store.isTimelineVisible && <TimelineWrapper>{timeline}</TimelineWrapper>}
        </PreviewSection>
      </Content>
    </Root>
  );
};
