import { createUrlFromArrayObjects } from './createUrlFromArrayObjects';

export const getConfigFromUrl = () => {
  const url = new URL(window.location.href);
  const queryString = url.search.slice(1); // Removing '?' at the start
  const params = queryString.split('&');

  const configs = [];
  let currentConfig = {};
  let globalConfig = {};

  params.forEach(param => {
    const [key, value] = param.split('=');

    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent((value || '').replace(/\+/g, ' '));

    if (
      decodedKey === 'currentlyPlayingPublicId' ||
      decodedKey === 'selectedHotspotId' ||
      decodedKey === 'autoplayEnabled' ||
      decodedKey === 'loopEnabled' ||
      decodedKey === 'playerControlsEnabled'
    ) {
      globalConfig[decodedKey] = decodedValue;
    } else {
      if (decodedKey.startsWith('hotspotId') && Object.keys(currentConfig).length > 0) {
        configs.push(currentConfig);
        currentConfig = {};
      }

      currentConfig[decodedKey] = decodedValue;
    }
  });

  if (Object.keys(currentConfig).length > 0) {
    configs.push(currentConfig);
  }

  const isEmptyConfig = configs.length === 0 || (configs.length === 1 && configs[0][''] === '');
  const isEmptyGlobalConfig =
    Object.keys(globalConfig).length === 0 ||
    (Object.keys(globalConfig).length === 1 && globalConfig[''] === '');

  function updateUrl(updatedConfig, updatedGlobalConfig = globalConfig) {
    const newUrl = createUrlFromArrayObjects(
      window.location.href,
      updatedConfig,
      updatedGlobalConfig
    );
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }

  return {
    configs: isEmptyConfig ? [] : configs,
    globalConfig: isEmptyGlobalConfig ? {} : globalConfig,
    updateUrl
  };
};
