import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { INTERACTIVE_VIDEO_API, SAMPLE_VIDEOS } from '../const';
import { delay } from '../utils/utils';

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children, initialStep = 0, initialConfig = {} }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(initialStep);
  const [config, setConfig] = useState(initialConfig);
  console.log('state in onboarding provider:', config);

  const [vttsData, setVttsData] = useState([]);
  const [error, setError] = useState(null);
  const [mobileBreakpoint, setMobileBreakpoint] = useState(640);

  const goToNextStep = () => setCurrentStep(currentStep + 1);
  const goToPreviousStep = () => setCurrentStep(currentStep - 1);

  const persistDataInOnboardingState = data => {
    console.log('data to be saved in onboarding state:', data);
    setConfig({ ...config, ...data });
  };

  const generateVtt = useCallback(
    async ({ hotspotId, publicId, requestObject, objectTrackingResultsUrl }) => {
      try {
        const response = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ hotspotId, publicId, requestObject, objectTrackingResultsUrl })
        });

        if (response.ok) {
          console.log('Generate VTT response ok:', response);
          const data = await response.json();
          console.log('Generate VTT data:', data);
          return { requestObject, vtt: data.vttUrl };
        } else {
          const errorData = await response.json();
          console.error(' 2 Generate VTT error:', errorData.errors[0].message);
          setError(errorData.errors[0].message);
        }
      } catch (error) {
        console.error('Failed POST request to /generate-vtt', error);
        setError(error);
      }
    },
    []
  );

  const generateVttsForDetectedObjects = useCallback(async () => {
    if (!SAMPLE_VIDEOS.some(video => video.publicId === config.publicId)) {
      const objects = decodeURIComponent(config.detectedObjects).split(',');
      const vttsDataArray = [];

      for (const objectName of objects) {
        const vttData = await generateVtt({
          hotspotId: config.hotspotId,
          publicId: config.publicId,
          requestObject: objectName,
          objectTrackingResultsUrl: config.objectTrackingResultsUrl
        });
        vttsDataArray.push({ [objectName]: vttData ? vttData.vtt : null });

        await delay(3000); // for some reason some requests failing when using promiseAll for vtt gen. Also if no delay between requests.
      }

      console.log('Generated VTTs:', vttsDataArray);
      setVttsData(vttsDataArray);
    } else {
      const sampleVideo = SAMPLE_VIDEOS.find(video => video.publicId === config.publicId);
      await delay(2000); // just to simulate timeout
      setVttsData(sampleVideo.vttsDataArray);
    }
  }, [
    config.detectedObjects,
    config.hotspotId,
    config.objectTrackingResultsUrl,
    config.publicId,
    generateVtt
  ]);

  const handleSampleVideo = publicId => {
    goToNextStep();

    return new Promise(resolve => {
      setTimeout(() => {
        const sampleVideo = SAMPLE_VIDEOS.find(video => video.publicId === publicId);

        const newUrlConfig = `hotspotId=${sampleVideo.hotspotId}&publicId=${sampleVideo.publicId}&videoDuration=${sampleVideo.videoDuration}&type=action&hotspotStateOpen=true&position=center&animation=fade-in&pauseOnClick=true&openInNewTab=true&hotspotTheme=theme_1&currentlyPlayingPublicId=${sampleVideo.publicId}&selectedHotspotId=${sampleVideo.hotspotId}&autoplayEnabled=false&loopEnabled=false&playerControlsEnabled=true`;
        const existingSearchParams = window.location.search;
        const separator = existingSearchParams ? '&' : '?';
        const newUrl = `${existingSearchParams}${separator}${newUrlConfig}`;

        navigate(newUrl, { replace: true });

        persistDataInOnboardingState(sampleVideo);
        resolve();
      }, 6000);
    });
  };

  useEffect(() => {
    if (config?.detectedObjects) {
      (async () => {
        await delay(3000);
        generateVttsForDetectedObjects();
      })();
    }
  }, [config, config?.detectedObjects, generateVttsForDetectedObjects]);

  const [isMobileDevice, setIsMobileDevice] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) || window.innerWidth < mobileBreakpoint
      );
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [mobileBreakpoint]);

  useEffect(() => {
    setMobileBreakpoint(mobileBreakpoint);
  }, [mobileBreakpoint, setMobileBreakpoint]);

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        goToNextStep,
        goToPreviousStep,
        setStep: setCurrentStep,
        persistDataInOnboardingState,
        config,
        generateVttsForDetectedObjects,
        vttsData,
        error,
        handleSampleVideo,
        isMobileDevice,
        setMobileBreakpoint
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);
