import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';

import cloudinaryLogo from '../../assets/cloudinary-logo.svg';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${globalTheme.palette.background};
  height: 4rem;
  padding: 1rem;
  font-family: 'Zen Kaku Gothic Antique';
  border-bottom: ${props => (props.withSeparator ? '1px solid #3d475c' : 'none')};
  position: ${props => (props.isSticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 999;
`;

const Separator = styled.div.attrs({ className: 'header-separator' })`
  display: inline-block;
  padding: 0 .5rem;
`;

const Wrapper = styled.div.attrs({ className: 'header-wrapper' })`
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;

    ${Separator} {
      display: none;
    }
  }
`;

const CloudinaryLogo = styled.img.attrs({ src: cloudinaryLogo, alt: 'Cloudinary' })`
  height: 1.5em;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.2em;
  word-break: no-break;
`;

export const Header = ({ rightSlot, withSeparator, isSticky }) => (
  <StyledHeader withSeparator={withSeparator} isSticky={isSticky}>
    <Wrapper>
      <StyledAnchor href="https://cloudinary.com/labs" target="_blank" rel="noopener noreferrer">
        <CloudinaryLogo />
        <Typography>LABS</Typography>
      </StyledAnchor>
      <Separator>\</Separator>
      <StyledAnchor href="/" target="_blank" rel="noopener noreferrer">
        <Typography>Interactive Video Experience [IVE Studio]</Typography>
      </StyledAnchor>
    </Wrapper>
    {rightSlot && <Wrapper>{rightSlot}</Wrapper>}
  </StyledHeader>
);
