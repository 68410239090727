const UploadCloud = ({ color = '#D2D6DF' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="upload-cloud" clipPath="url(#clip0_2106_898)">
        <path
          id="Vector"
          d="M16 16.5L12 12.5L8 16.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M12 12.5V21.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M20.39 18.89C21.3653 18.3583 22.1358 17.5169 22.5799 16.4986C23.0239 15.4804 23.1162 14.3432 22.8422 13.2667C22.5682 12.1901 21.9435 11.2355 21.0667 10.5534C20.1899 9.87137 19.1109 9.50072 18 9.49998H16.74C16.4373 8.32923 15.8732 7.24232 15.0899 6.32098C14.3067 5.39964 13.3248 4.66783 12.2181 4.18059C11.1114 3.69335 9.90855 3.46334 8.70012 3.50787C7.49168 3.55239 6.30907 3.87028 5.24118 4.43765C4.17328 5.00501 3.24791 5.80709 2.53462 6.78357C1.82133 7.76004 1.33869 8.88552 1.12298 10.0754C0.907268 11.2652 0.964104 12.4885 1.28921 13.6532C1.61432 14.818 2.19924 15.8938 3 16.8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M16 16.5L12 12.5L8 16.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2106_898">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadCloud;
