import { CLOUD_NAME } from '../const';

export const getVideoUrlByPublicId = publicId =>
  `https://res.cloudinary.com/${CLOUD_NAME}/video/upload/${publicId}.mp4`;

export const getImageUrlFromVideoByPublicId = publicId =>
  `https://res.cloudinary.com/${CLOUD_NAME}/video/upload/${publicId}.png`;

export const getImageUrlByPublicId = publicId =>
  `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/${publicId}.png`;
