import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import editorEmptyState from './assets/editor_empty_state.png';
import editorEmptyState1800 from './assets/editor_empty_state_1800.png';
import editorEmptyState1200 from './assets/editor_empty_state_1200.png';

export const EditorEmptyStateBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  background-size: cover;
  background-color: ${globalTheme.palette.surface};

  @media (min-width: 700px) {
    background: ${globalTheme.palette.background} url(${editorEmptyState1200}) no-repeat center
      center;
    background-size: cover;
  }

  @media (min-width: 1201px) {
    background-image: url(${editorEmptyState1800});
  }

  @media (min-width: 1801px) {
    background-image: url(${editorEmptyState});
  }
`;

export const Root = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 90vw;
  padding: 0 2rem 1rem 2rem;
  border-radius: 1rem;
  background-color: ${globalTheme.palette.background};
  border: 1px solid ${globalTheme.palette.primary};

  @media (min-width: 640) {
    padding: 0 2rem 2rem;
  }

  @media (min-height: 935px), (min-width: 450px) {
    height: 95vh;
  }
`;

export const CloseButton = styled.div`
  place-self: center;
  cursor: pointer;
`;

export const MobileLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: scroll;
`;
