import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { ReactComponent as QuestionsIcon } from '../../icons/AddNewItemModal/questions_icon.svg';
import { ReactComponent as PopupIcon } from '../../icons/AddNewItemModal/popup_icon.svg';
import { ReactComponent as CarouselIcon } from '../../icons/AddNewItemModal/carousel_icon.svg';
import { ReactComponent as ImageIcon } from '../../icons/AddNewItemModal/image_icon.svg';

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 1.5rem;
  cursor: pointer;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 3rem;
  width: 20rem;
  z-index: 1;
  padding: 1rem;
  box-shadow: 0 0 20px 0 rgba(174, 247, 252, 0.25);
  background-color: ${globalTheme.palette.background};
`;

const Row = ({ icon, label, onClick }) => (
  <RowWrapper onClick={onClick}>
    {icon} {label}
  </RowWrapper>
);

const AddNewInteractionModal = () => {
  return (
    <ModalWrapper>
      <Row icon={<QuestionsIcon />} label="Choice" />
      <Row icon={<PopupIcon />} label="Link" />
      <Row icon={<CarouselIcon />} label="Carousel" />
      <Row icon={<ImageIcon />} label="Image" />
    </ModalWrapper>
  );
};

export default AddNewInteractionModal;
