import get from 'lodash/get';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import {
  CLOSE_INTERACTION_AREA_LAYOUT_DELAY,
  DEFAULT_INTERACTION_ARE_TRANSITION,
  INTERACTION_AREAS_CONTAINER_CLASS_NAME
} from './interaction-area.const';
import {
  clearAllTextTracks,
  createInteractionAreaLayoutMessage,
  getInteractionAreaItem,
  removeInteractionAreasContainer,
  setInteractionAreasContainer,
  setInteractionAreasContainerSize,
  shouldShowAreaLayoutMessage,
  updateInteractionAreasItem
} from './interaction-area.utils';
import { addEventListener, createElement, setCloudinaryWatermark } from '../../utils/dom';
import './interaction-area.scss';

export function InteractionAreaPlugin(player /*updateStore*/) {
  const { playerOptions, videojsOptions } = player.options;

  let currentSource = null;
  let currentTrack = null;

  const addMetadataTrack = vttSource => {
    return player.videojs.addRemoteTextTrack(
      {
        kind: 'metadata',
        srclang: 'en',
        src: vttSource
      },
      true
    ).track;
  };

  const shouldLayoutMessage = () => shouldShowAreaLayoutMessage(videojsOptions.interactionDisplay);

  function isInteractionAreasEnabled(enabled = false) {
    const interactionAreasConfig = getInteractionAreasConfig();
    return enabled || (interactionAreasConfig && interactionAreasConfig.on);
  }

  function setUpTracks(tracks) {
    currentTrack && player.videojs.removeRemoteTextTrack(currentTrack);
    const isEnabled = isInteractionAreasEnabled();
    const interactionAreasConfig = getInteractionAreasConfig();

    if (!isEnabled) {
      return null;
    }

    if (Array.isArray(interactionAreasConfig.template)) {
      addInteractionAreasItems(interactionAreasConfig.template);
      setContainerSize();
    } else if (tracks) {
      addCueListener(tracks);
    }
  }

  function setAreasPositionListener(vttUrls = [], isOnboarding = false, hotspotId = null) {
    let vtts = vttUrls;

    const isNonOnboardingUrlPathname =
      window.location.pathname.includes('edit') || window.location.pathname.includes('preview');
    const isOnboardingActive = isOnboarding || !isNonOnboardingUrlPathname;

    const interactionAreasConfig = getInteractionAreasConfig();
    const { configs, globalConfig } = interactionAreasConfig?.getConfigs();

    if (!isOnboardingActive) {
      const currentConfig = configs?.find(
        config => config.publicId === globalConfig?.currentlyPlayingPublicId
      );
      // Find and add VTTs where parentConfigId equals currentConfig.hotspotId
      const childHotspots = configs.filter(
        config => config.parentConfigId === currentConfig?.hotspotId
      );

      const vttHotspotPairs = [
        { vtt: currentConfig?.vtt, hotspotId: currentConfig?.hotspotId },
        ...childHotspots.map(config => ({ vtt: config.vtt, hotspotId: config.hotspotId }))
      ];

      clearAllTextTracks(player.videojs);

      console.log(
        `Tracking vtt in setAreasPositionListener: ${vttHotspotPairs.map(pair => pair.vtt)}`
      );

      const tracks = vttHotspotPairs.map(pair => {
        const track = addMetadataTrack(pair.vtt);
        return { track, hotspotId: pair.hotspotId };
      });

      setUpTracks(tracks);
    }

    if (isOnboardingActive && hotspotId) {
      const vttHotspotPairs = [{ vtt: vtts[0] /*always one from onboarding*/, hotspotId }];

      console.log(
        `Tracking vtt in setAreasPositionListener: ${vttHotspotPairs.map(pair => pair.vtt)}`
      );

      const tracks = vttHotspotPairs.map(pair => {
        const track = addMetadataTrack(pair.vtt);
        return { track, hotspotId: pair.hotspotId };
      });

      setUpTracks(tracks);
    }
  }

  function getInteractionAreasConfig() {
    const { cldSrc } = currentSource;
    return cldSrc && cldSrc.getInteractionAreas();
  }

  function removeLayoutMessage() {
    removeInteractionAreasContainer(player.videojs);
    setAreasPositionListener();
    player.play();
    setCloudinaryWatermark(player);
  }

  function setLayoutMessage() {
    if (!isInteractionAreasEnabled()) {
      return;
    }

    if (shouldLayoutMessage()) {
      let layoutMessageTimeout = null;
      const showItAgainCheckbox = get(videojsOptions, 'interactionDisplay.layout.showAgain', false);
      player.pause();

      createInteractionAreaLayoutMessage(
        player.videojs,
        () => {
          clearTimeout(layoutMessageTimeout);
          removeLayoutMessage();
        },
        showItAgainCheckbox
      );

      if (!showItAgainCheckbox) {
        layoutMessageTimeout = setTimeout(removeLayoutMessage, CLOSE_INTERACTION_AREA_LAYOUT_DELAY);
      }
    } else {
      removeLayoutMessage();
    }
  }

  function handleAds() {
    player.on('adsready', () => {
      player.videojs.ima.addEventListener(
        window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        setLayoutMessage
      );
    });
  }

  function init() {
    currentSource = currentSource || player.videojs.currentSource();

    if (isInteractionAreasEnabled()) {
      player.videojs.el().classList.add('interaction-areas');

      player.videojs.on('loadeddata', () => {
        setCloudinaryWatermark(player);
      });

      player.videojs.one('play', () => {
        if (typeof player.videojs.ima === 'object') {
          handleAds();
        } else {
          setLayoutMessage();
        }
      });

      const setInteractionAreasContainerSize = throttle(setContainerSize, 100);

      player.videojs.on('videoChanged', () => {
        const container = document.querySelector(`.${INTERACTION_AREAS_CONTAINER_CLASS_NAME}`);
        setCloudinaryWatermark(player);

        if (container) {
          container.innerHTML = '';
        }
        setAreasPositionListener();

        const watermark = document.getElementById('watermark');
        if (!watermark) {
          const newWatermarkContainer = setCloudinaryWatermark(player);
          container.appendChild(newWatermarkContainer);
        }
      });

      player.videojs.on('fullscreenchange', () => {
        // waiting for fullscreen will end
        setTimeout(setInteractionAreasContainerSize, 100);
      });

      addEventListener(window, 'resize', () => {
        document.getElementById('watermark')?.remove();
        setCloudinaryWatermark(player);
      });

      const resizeDestroy = () => addEventListener(window, 'resize', setContainerSize, false);

      player.videojs.on('dispose', resizeDestroy);
    }

    player.videojs.on('error', () => {
      player.pause();
    });
  }

  function interactionAreasApiFormatting(interactionAreasData) {
    const { videoHeight, videoWidth } = player.videoElement;

    if (!videoHeight && !videoWidth) {
      return interactionAreasData.map(item => {
        return {
          id: item.id,
          left: (item.topLeftX * 100) / item.originalAspectRatioX,
          top: (item.topLeftY * 100) / item.originalAspectRatioY,
          width: (item.width * 100) / item.originalAspectRatioX,
          height: (item.height * 100) / item.originalAspectRatioY
        };
      });
    } else {
      return interactionAreasData.map(item => {
        return {
          id: item.id,
          left: (item.topLeftX * 100) / videoWidth,
          top: (item.topLeftY * 100) / videoHeight,
          width: (item.width * 100) / videoWidth,
          height: (item.height * 100) / videoHeight
        };
      });
    }
  }

  function onInteractionAreasClick({ event, item, index }) {
    const interactionAreasConfig = getInteractionAreasConfig();

    interactionAreasConfig.onClick &&
      interactionAreasConfig.onClick({
        item,
        index,
        event
      });
  }

  function addInteractionAreasItems(
    interactionAreasData,
    previousInteractionAreasData,
    durationTime = 0
  ) {
    const configs = { playerOptions, videojsOptions };
    if (previousInteractionAreasData) {
      updateInteractionAreasItem(
        player.videojs,
        configs,
        interactionAreasData,
        previousInteractionAreasData,
        durationTime,
        onInteractionAreasClick
      );
    } else {
      const interactionAreasItems = interactionAreasData.map((item, index) => {
        return getInteractionAreaItem(configs, item, index, durationTime, event => {
          onInteractionAreasClick({ event, item, index });
        });
      });

      setInteractionAreasContainer(
        player.videojs,
        createElement(
          'div',
          { class: INTERACTION_AREAS_CONTAINER_CLASS_NAME },
          interactionAreasItems
        )
      );
    }
  }

  function setContainerSize() {
    if (isInteractionAreasEnabled()) {
      setInteractionAreasContainerSize(player.videojs, player.videoElement);
    }
  }

  function addCueListener(tracks) {
    if (!tracks || !Array.isArray(tracks)) {
      return;
    }

    let previousTracksData = null;

    function processCues() {
      const allTracksData = tracks.reduce((acc, { track, hotspotId }) => {
        const activeCues = Array.from(track.activeCues || []);
        activeCues.forEach(cue => {
          try {
            const cueData = JSON.parse(cue.text);
            const cueDataWithAdjustedIds = cueData.map(item => ({
              ...item,
              hotspotId,
              id: hotspotId
            }));

            acc.push(...cueDataWithAdjustedIds);
          } catch (e) {
            console.error('Error parsing cue data:', e);
          }
        });

        return acc;
      }, []);

      const uniqueTracksData = interactionAreasApiFormatting(uniqBy(allTracksData, 'hotspotId'));

      if (uniqueTracksData.length > 0) {
        addInteractionAreasItems(
          uniqueTracksData,
          previousTracksData,
          DEFAULT_INTERACTION_ARE_TRANSITION
        );
        setContainerSize();
        previousTracksData = uniqueTracksData;
      } else {
        removeInteractionAreasContainer(player.videojs);
        previousTracksData = null;
      }
    }

    tracks.forEach(trackObject => {
      trackObject.track.addEventListener('cuechange', processCues);
    });
  }

  init();

  return {
    init,
    setAreasPositionListener
  };
}
