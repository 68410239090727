import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@clds/icon';
import { Button } from '@clds/button';
import { LinkButton } from '@clds/button';

const BaseRootWrapperStyles = css`
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
`;

export const Root = styled.div`
  justify-content: space-around;
  flex-direction: column;
  border-radius: 1rem;
  padding: 1rem;
  font-family: ${props => props.fontFamily};
  background: ${props => props.background};
  backdrop-filter: ${props => props.backdropFilter};
  -webkit-backdrop-filter: ${props => props.backdropFilter};
  animation: ${props => props.animation};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  ${BaseRootWrapperStyles};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-200px);
      transform: translateZ(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  @keyframes flip-in-diag-2-br {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, -80deg);
      transform: rotate3d(-1, 1, 0, -80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate3d(1, 1, 0, 0deg);
      transform: rotate3d(1, 1, 0, 0deg);
      opacity: 1;
    }
  }

  @keyframes tilt-in-top-1 {
    0% {
      -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
      transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }

  @keyframes rotate-in-top {
    0% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
`;

export const Title = styled.div`
  color: ${props => props.color};

  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
`;

export const StyledImage = styled.img`
  max-width: 5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: ${globalTheme.palette.background};
`;

export const UpperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledKeyboardArrowLeft = styled(KeyboardArrowLeft)`
  margin-right: 0.5rem;
  color: #fffffe;
  cursor: pointer;
`;

export const StyledKeyboardArrowRight = styled(KeyboardArrowRight)`
  margin-left: 0.5rem;
  color: #fffffe;
  cursor: pointer;
`;

export const DotIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.span`
  margin-right: 0.3rem;
  cursor: pointer;
  border-radius: 50%;
  background: ${props => (props.active ? 'darkgrey' : '#FFFFFE')};
  width: 0.5rem;
  height: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  margin-left: 0.5rem;
  color: #fffffe;
  cursor: pointer;
`;

export const Content = styled.div`
  animation: scale-in-hor-center 1s ease;

  @keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }
`;

export const ChoiceHorizontalWrapper = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  ${BaseRootWrapperStyles}
`;

export const ChoiceHorizontalOptions = styled.div`
  display: flex;
  align-items: center;
  min-height: 5rem;
  gap: 0.5rem;
  margin-left: 0.5rem;
`;

export const SmallImage = styled.img`
  height: 2rem;
  border-radius: 10%;
  transition: transform 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: ${globalTheme.palette.surface} 0px 2px 8px 0px;
  }
`;

export const ChoiceVerticalImage = styled.img`
  height: 2rem;
  border-radius: 10%;
`;

export const ChoiceVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`;

export const ChoiceVerticalTitle = styled.div`
  position: relative;
  border-radius: 0.5rem;
  padding: 1rem 3rem;
  color: ${globalTheme.palette.background};
  ${BaseRootWrapperStyles};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
`;

export const ChoiceVerticalRow = styled.div`
  gap: 0.5rem;
  transition: transform 0.3s;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.5rem 3rem;
  ${BaseRootWrapperStyles}

  &:hover {
    transform: scale(1.1);
    box-shadow: ${globalTheme.palette.surface} 0px 2px 8px 0px;
  }

  > img {
    height: 2rem;
    border-radius: 10%;
  }
`;

export const StyledButton = styled(Button)`
  background: ${props => props.background};
  pointer-events: all;

  & > div {
    color: ${props => props.color};
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  background: ${props => props.backgroundColor};
  pointer-events: all;

  & > div {
    color: ${props => props.buttonTextColor};

    @media (min-width: 640px) {
      font-size: 1.5rem;
    }
  }
`;
