import React from 'react';

const ShadowIcon = ({ color = '#FFFFFE' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <mask id="mask0_572_4306" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <rect width="12" height="12" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_572_4306)">
      <path
        d="M2 11C1.725 11 1.48958 10.9021 1.29375 10.7063C1.09792 10.5104 1 10.275 1 10V4C1 3.725 1.09792 3.48958 1.29375 3.29375C1.48958 3.09792 1.725 3 2 3H3V2C3 1.725 3.09792 1.48958 3.29375 1.29375C3.48958 1.09792 3.725 1 4 1H10C10.275 1 10.5104 1.09792 10.7063 1.29375C10.9021 1.48958 11 1.725 11 2V8C11 8.275 10.9021 8.51042 10.7063 8.70625C10.5104 8.90208 10.275 9 10 9H9V10C9 10.275 8.90208 10.5104 8.70625 10.7063C8.51042 10.9021 8.275 11 8 11H2ZM4 8H10V2H4V8Z"
        fill={color}
      />
    </g>
  </svg>
);

export default ShadowIcon;
