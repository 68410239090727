import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import noop from 'lodash/noop';
import { toast } from 'react-toastify';
import { getConfigFromUrl } from '../utils/getConfigFromUrl';
import { createUrlFromArrayObjects } from '../utils/createUrlFromArrayObjects';
import { INTERACTIVE_VIDEO_API } from '../const';

const videoUploadContext = createContext(undefined);

export const useVideoUpload = () => {
  const value = useContext(videoUploadContext);

  if (!value) {
    throw new Error('You need to wrap your component with VideoUploadProvider');
  }

  return value;
};

export const VideoUploadProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isObjectTracking, setIsObjectTracking] = useState(false);
  const onUploadDoneRef = useRef();
  const navigate = useNavigate();

  const onUploaded = useCallback(callback => (onUploadDoneRef.current = callback), []);

  const updateWithTrackingResult = useCallback((trackingResult, hotspotId) => {
    const { configs, globalConfig } = getConfigFromUrl();

    const updatedConfig = configs.map(item => {
      if (item.hotspotId === hotspotId) {
        return {
          ...item,
          objectTrackingResultsUrl: trackingResult.adjustedObjectTrackingResultsUrl,
          detectedObjects: encodeURIComponent(trackingResult.detectedObjects)
        };
      }
      return item;
    });

    const newUrl = createUrlFromArrayObjects(window.location.href, updatedConfig, globalConfig);
    window.history.replaceState({ path: newUrl }, '', newUrl);
    window.location.reload();
  }, []);

  const uploadVideo = useCallback(async file => {
    const formData = new FormData();
    formData.append('video', file);

    setIsUploading(true);

    try {
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        const data = await response.json();
        setIsUploading(false);
        console.log('Video upload successful', data);
        onUploadDoneRef.current?.(data);
        return data;
      }
    } catch (error) {
      toast.error('Error during video upload.');
      console.error('Error during video upload.', error);
    }
  }, []);

  const uploadAndObjectTrackingVideo = useCallback(
    async ({ file, isUploadFromOnboarding = false, persistDataInOnboardingState = noop }) => {
      try {
        if (!isUploadFromOnboarding) {
          // if upload from the editor

          const hotspotId = uuidv4();
          const data = await uploadVideo(file);
          const pathname = '/edit';
          const newUrlConfig = `hotspotId=${hotspotId}&publicId=${data.publicId}&videoDuration=${data.originalDurationSeconds}&type=action&hotspotStateOpen=true&position=center&animation=fade-in&pauseOnClick=true&openInNewTab=true&hotspotTheme=theme_1&currentlyPlayingPublicId=${data.publicId}&selectedHotspotId=${hotspotId}&autoplayEnabled=false&loopEnabled=false&playerControlsEnabled=true`;
          const existingSearchParams = window.location.search;
          const separator = existingSearchParams ? '&' : '?';
          const newUrl = `${pathname}${existingSearchParams}${separator}${newUrlConfig}`;

          navigate(newUrl, { replace: true });

          requestObjectTracking({ publicId: data.publicId })
            .then(trackingResult => {
              console.log('Tracking result:', JSON.stringify(trackingResult));
              updateWithTrackingResult(trackingResult, hotspotId);
            })
            .catch(error => {
              console.error('Object tracking error:', error);
              toast.error('Object tracking error.');
            });
        }

        if (isUploadFromOnboarding) {
          // if upload from the wizard

          const hotspotId = uuidv4();
          const data = await uploadVideo(file);
          const pathname = '/';
          const newUrlConfig = `hotspotId=${hotspotId}&publicId=${data.publicId}&videoDuration=${data.originalDurationSeconds}&type=action&hotspotStateOpen=true&position=center&animation=fade-in&pauseOnClick=true&openInNewTab=true&hotspotTheme=theme_1&currentlyPlayingPublicId=${data.publicId}&selectedHotspotId=${hotspotId}&autoplayEnabled=false&loopEnabled=false&playerControlsEnabled=true`;
          const existingSearchParams = window.location.search;
          const separator = existingSearchParams ? '&' : '?';
          const newUrl = `${pathname}${existingSearchParams}${separator}${newUrlConfig}`;

          persistDataInOnboardingState({
            publicId: data.publicId,
            videoDuration: data.originalDurationSeconds
          });

          navigate(newUrl, { replace: true });

          try {
            const trackingResult = await requestObjectTracking({ publicId: data.publicId });
            console.log('Tracking result:', JSON.stringify(trackingResult));

            if (persistDataInOnboardingState) {
              persistDataInOnboardingState({
                hotspotId,
                publicId: data.publicId,
                type: 'action',
                videoDuration: data.originalDurationSeconds,
                hotspotStateOpen: true,
                position: 'center',
                animation: 'fade-in',
                pauseOnClick: true,
                openInNewTab: true,
                hotspotTheme: 'theme_1',
                objectTrackingResultsUrl: trackingResult.adjustedObjectTrackingResultsUrl,
                detectedObjects: encodeURIComponent(trackingResult.detectedObjects)
              });
            }
          } catch (error) {
            toast.error('Object tracking error.');
            console.error('Object tracking error:', error);
          }
        }
      } catch (error) {
        console.error('Error during a video upload', error);
      }
    },
    [navigate, updateWithTrackingResult, uploadVideo]
  );

  const requestObjectTracking = async ({ publicId }) => {
    try {
      setIsObjectTracking(true);
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/objects-tracking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ publicId })
      });

      if (response.ok) {
        const data = await response.json();
        setIsObjectTracking(false);
        console.log('Object tracking data:', data);
        return data;
      } else {
        const errorData = await response.json();
        console.error('Object tracking error:', errorData.message);
        setError(errorData.message);
        return null;
      }
    } catch (error) {
      console.error('Object tracking error:', error);
      return null;
    }
  };

  const onDrop = useCallback(
    acceptedFiles => uploadAndObjectTrackingVideo({ file: acceptedFiles[0] }),
    [uploadAndObjectTrackingVideo]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'video/*': ['.mp4', '.mkv', '.avi', '.mov']
    }
  });

  const manualUpload = useCallback(file => uploadVideo(file), [uploadVideo]);

  const value = {
    manualUpload,
    uploadAndObjectTrackingVideo,
    getRootProps,
    getInputProps,
    isDragActive,
    isUploading,
    isObjectTracking,
    onUploadDoneRef,
    onUploaded,
    error,
    setError
  };

  return <videoUploadContext.Provider value={value}>{children}</videoUploadContext.Provider>;
};
