import { useState, useCallback, useMemo, createContext, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfigFromUrl } from '../../utils/getConfigFromUrl';
import { createUrlFromArrayObjects } from '../../utils/createUrlFromArrayObjects';
import { ANIMATIONS_ENUMS, TRIGGERS_TYPES_ENUMS } from '../../const';
import { themes } from './hotspotWidgetThemes';
import { getGroupedHotspots, getHotSpotText } from '../../utils/utils';

const editHotspotContext = createContext(undefined);

export const useEditHotspot = () => {
  const value = useContext(editHotspotContext);

  if (!value) {
    throw new Error('You need to wrap your component with EditHotspotProvider');
  }

  return value;
};

export const EditHotspotProvider = ({ children }) => {
  const navigate = useNavigate();
  const videoContainerRef = useRef(null);
  const { configs, globalConfig, updateUrl } = getConfigFromUrl();
  const groupedHotspots = useMemo(() => getGroupedHotspots(configs), [configs]);
  const selectedHotspotId = globalConfig.selectedHotspotId;
  const selectedHotspotConfig = useMemo(
    () => configs?.find(hotspot => hotspot.hotspotId === selectedHotspotId),
    [configs, selectedHotspotId]
  );

  const [hotspotTitle, setHotspotTitle] = useState(selectedHotspotConfig?.hotspotTitle || '');
  const [hotspotTargetUrl, setHotspotTargetUrl] = useState(selectedHotspotConfig?.targetUrl || '');
  const [hotspotCtaText, setHotspotCtaText] = useState(selectedHotspotConfig?.hotspotCtaText || '');

  const [hotspotActionTypeImageUrl, setHotspotActionTypeImageUrl] = useState(
    selectedHotspotConfig?.hotspotActionTypeImageUrl || ''
  );

  const [hotspotPosition, setHotspotPosition] = useState(
    selectedHotspotConfig?.position || 'north'
  );
  const [selectedTriggerType, setSelectedTriggerType] = useState(
    selectedHotspotConfig?.trigger || TRIGGERS_TYPES_ENUMS.OBJECT
  );
  const [hotspotAnimation, setHotspotAnimation] = useState(
    selectedHotspotConfig?.animation || ANIMATIONS_ENUMS['fade-in']
  );
  const [hotspotTheme, setHotspotTheme] = useState(
    selectedHotspotConfig?.hotspotTheme || themes[0].name
  );

  const [objectDescription, setObjectDescription] = useState(
    (selectedHotspotConfig && getHotSpotText(selectedHotspotConfig)) || ''
  );
  const [vtt, setVtt] = useState(selectedHotspotConfig?.vtt || '');

  const [objectTrackingResultsWithVtts, setObjectTrackingResultsWithVtts] = useState(
    selectedHotspotConfig?.objectTrackingResultsWithVtts || null
  );

  const [vttByImageData, setVttByImageData] = useState(
    selectedHotspotConfig?.vttByImageData || null
  );

  const [vttByHintData, setVttByHintData] = useState(selectedHotspotConfig?.vttByHintData || null);

  const [pauseOnClick, setPauseOnClick] = useState(selectedHotspotConfig?.pauseOnClick || true);
  const [openInNewTab, setOpenInNewTab] = useState(selectedHotspotConfig?.openInNewTab || true);
  const [autoplayEnabled, setAutoplayEnabled] = useState(globalConfig?.autoplayEnabled || false);
  const [loopEnabled, setLoopEnabled] = useState(globalConfig?.loopEnabled || false);
  const [playerControlsEnabled, setPlayerControlsEnabled] = useState(
    globalConfig?.playerControlsEnabled || true
  );

  const selectedHotspotType = selectedHotspotConfig?.type;

  const navigateBackToPreview = () => {
    const { configs, globalConfig } = getConfigFromUrl();

    const publicIds = Object.keys(groupedHotspots);

    const newUrl = createUrlFromArrayObjects(window.location.href, configs, {
      ...globalConfig,
      currentlyPlayingPublicId: publicIds[0]
    });
    window.history.replaceState({ path: newUrl }, '', newUrl);

    const url = new URL(newUrl, window.location.origin);
    const path = '/preview' + url.search;
    navigate(path);
  };

  const onObjectTrackingResultsWithVttsChange = useCallback(
    data => {
      setObjectTrackingResultsWithVtts(data);

      const stringifiedData = JSON.stringify(data);
      const encodedData = encodeURIComponent(stringifiedData);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, objectTrackingResultsWithVtts: encodedData };
        }
        return item;
      });

      updateUrl(updatedConfig);
      window.location.reload();
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onVttByImageChange = useCallback(
    vttByImageData => {
      setVttByImageData(vttByImageData); // it is an object {...imagePublicId: ...vttUrl}

      const stringifiedData = JSON.stringify(vttByImageData);
      const encodedData = encodeURIComponent(stringifiedData);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, vttByImageData: encodedData };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onVttByHintChange = useCallback(
    vttByHintData => {
      setVttByHintData(vttByHintData); // it is an object {...hintString: ...vttUrl}

      const stringifiedData = JSON.stringify(vttByHintData);
      const encodedData = encodeURIComponent(stringifiedData);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, vttByHintData: encodedData };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onVttChange = useCallback(
    (vttUrl, matchingObject) => {
      setVtt(vttUrl);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, objectDescription: matchingObject, vtt: vttUrl };
        }
        return item;
      });

      updateUrl(updatedConfig);
      window.location.reload();
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotThemeChange = useCallback(
    themeName => {
      setHotspotTheme(themeName);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, hotspotTheme: themeName };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotTitleChange = useCallback(
    title => {
      setHotspotTitle(title);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, hotspotTitle: title };
        }
        return item;
      });
      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotCtaTextChange = useCallback(
    text => {
      setHotspotCtaText(text);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, hotspotCtaText: text };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotAnimationChange = useCallback(
    animation => {
      setHotspotAnimation(animation);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, animation };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotTriggerChange = useCallback(
    trigger => {
      setSelectedTriggerType(trigger);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, trigger };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotPositionChange = useCallback(
    position => {
      setHotspotPosition(position);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, position };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onHotspotTargetUrlChange = useCallback(
    targetUrl => {
      setHotspotTargetUrl(targetUrl);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, targetUrl };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, selectedHotspotId, updateUrl]
  );

  const onObjectDescriptionChange = useCallback(
    objectDescription => {
      setObjectDescription(objectDescription);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, objectDescription };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, updateUrl, selectedHotspotId]
  );

  const onObjectDescriptionChangeWithVtt = useCallback(
    (objectDescription, vtt) => {
      setObjectDescription(objectDescription);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, objectDescription, vtt };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, updateUrl, selectedHotspotId]
  );

  const onPauseOnClickChange = useCallback(
    value => {
      setPauseOnClick(value);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, pauseOnClick: value };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, updateUrl, selectedHotspotId]
  );

  const onOpenInNewTabChange = useCallback(
    value => {
      setOpenInNewTab(value);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, openInNewTab: value };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, updateUrl, selectedHotspotId]
  );

  const onAutoplayEnabledChange = useCallback(
    value => {
      setAutoplayEnabled(value);
      updateUrl(configs, { ...globalConfig, autoplayEnabled: value });
    },
    [updateUrl, configs, globalConfig]
  );

  const onLoopEnabledChange = useCallback(
    value => {
      setLoopEnabled(value);
      updateUrl(configs, { ...globalConfig, loopEnabled: value });
    },
    [updateUrl, configs, globalConfig]
  );

  const onPlayerControlsEnabledChange = useCallback(
    value => {
      setPlayerControlsEnabled(value);
      updateUrl(configs, { ...globalConfig, playerControlsEnabled: value });
    },
    [updateUrl, configs, globalConfig]
  );

  const onHotspotActionTypeImageUrlChange = useCallback(
    value => {
      setHotspotActionTypeImageUrl(value);

      const updatedConfig = configs.map(item => {
        if (item.hotspotId === selectedHotspotId) {
          return { ...item, hotspotActionTypeImageUrl: value };
        }
        return item;
      });

      updateUrl(updatedConfig);
    },
    [configs, updateUrl, selectedHotspotId]
  );

  const value = {
    selectedHotspotConfig,
    navigateBackToPreview,
    videoContainerRef,
    selectedTriggerType,
    selectedHotspotType,
    hotspotPosition,
    hotspotAnimation,
    hotspotTargetUrl,
    hotspotTitle,
    hotspotCtaText,
    hotspotTheme,
    hotspotActionTypeImageUrl,
    objectDescription,
    vtt,
    vttByImageData,
    vttByHintData,
    objectTrackingResultsWithVtts,
    pauseOnClick,
    openInNewTab,
    autoplayEnabled,
    loopEnabled,
    playerControlsEnabled,
    onHotspotThemeChange,
    onHotspotActionTypeImageUrlChange,
    onOpenInNewTabChange,
    onPauseOnClickChange,
    onObjectDescriptionChange,
    onObjectDescriptionChangeWithVtt,
    onHotspotAnimationChange,
    onHotspotTriggerChange,
    onHotspotTitleChange,
    onHotspotCtaTextChange,
    onHotspotPositionChange,
    onHotspotTargetUrlChange,
    onVttChange,
    onVttByHintChange,
    onVttByImageChange,
    onObjectTrackingResultsWithVttsChange,
    onAutoplayEnabledChange,
    onLoopEnabledChange,
    onPlayerControlsEnabledChange
  };

  return <editHotspotContext.Provider value={value}>{children}</editHotspotContext.Provider>;
};

export default useEditHotspot;
