import React from 'react';

const TrackingIconBig = ({ color = '#FFFFFE' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00166 11.2983C3.07455 11.2983 0.70166 8.92545 0.70166 5.99834C0.70166 3.07128 3.07455 0.69832 6.00166 0.69832C8.92872 0.69832 11.3017 3.07128 11.3017 5.99834C11.3017 8.92545 8.92872 11.2983 6.00166 11.2983ZM5.60002 10.5208C3.41311 10.3291 1.67088 8.58689 1.47918 6.39998L3.60002 6.39998C3.82094 6.39998 4.00002 6.2209 4.00002 5.99998C4.00002 5.77906 3.82094 5.59998 3.60002 5.59998L1.4789 5.59998C1.66914 3.41152 3.41202 1.66768 5.60002 1.47584L5.60002 3.6C5.60002 3.82088 5.7791 4 6.00002 4C6.22094 4 6.40002 3.82088 6.40002 3.6L6.40002 1.4756C8.58952 1.66592 10.3341 3.41048 10.5244 5.59998L8.4 5.59998C8.17912 5.59998 8 5.77906 8 5.99998C8 6.2209 8.17912 6.39998 8.4 6.39998L10.5242 6.39998C10.3323 8.58798 8.58848 10.3309 6.40002 10.5211L6.40002 8.39998C6.40002 8.17906 6.22094 7.99998 6.00002 7.99998C5.7791 7.99998 5.60002 8.17906 5.60002 8.39998L5.60002 10.5208Z"
        fill={color}
      />
    </svg>
  );
};

export default TrackingIconBig;
