import React from 'react';
import Typography from '@clds/typography';
import { Search } from '@clds/icon';
import { useVideoUpload } from '../../../hooks/useVideoUpload';
import Timeline from './Timeline';
import useTrackAnythingTabContent from './useTrackAnythingTabContent';
import { ReactComponent as SearchByImage } from '../../OnboardingWizard/assets/search_by_image.svg';
import { getImageUrlByPublicId } from '../../../utils/getUrlByPublicId';
import { useStore } from '../../../utils/store';

import {
  ContentWrapper,
  Rows,
  Row,
  Label,
  LoadingLabel,
  Spinner,
  Actions,
  InputWrapper,
  StyledInput,
  SearchIconWrapper,
  StyledButton,
  ImageSearchPreviewWrapper,
  ImageSearchPreview
} from './TrackAnythingTabContent.styled';

const TrackAnythingTabContent = () => {
  const { isUploading, isObjectTracking } = useVideoUpload();
  const { store } = useStore();

  const {
    isFetchingVtts,
    isGeneratingVtts,
    vttByHintData,
    vttByImageData,
    timeframesData,
    customTrackingTimeframesData,
    selectedHotspotConfig,
    isCreatingVttByImageTracking,
    isCreatingVttByHintTracking,
    objectNameToTrack,
    setObjectNameToTrack,
    onObjectToTrack,
    getRootProps,
    getInputProps,
    isImageUploading,
    getVttUrlByName,
    onObjectDescriptionChangeWithVtt
  } = useTrackAnythingTabContent();

  const isCreatingVtt = isCreatingVttByImageTracking || isCreatingVttByHintTracking;

  return (
    <ContentWrapper
      $isDisabled={isUploading || isObjectTracking || isFetchingVtts || isGeneratingVtts}
    >
      <Typography>Here are the objects we detected:</Typography>

      <Rows>
        {isCreatingVtt && (
          <Row>
            {isCreatingVttByHintTracking && (
              <>
                <Label>
                  {objectNameToTrack.charAt(0).toUpperCase() + objectNameToTrack.slice(1)}
                </Label>
                <LoadingLabel>
                  <Spinner />
                  <Typography>Mapping Object Movements</Typography>
                </LoadingLabel>
              </>
            )}

            {isCreatingVttByImageTracking && (
              <span style={{ margin: '1rem' }}>
                <LoadingLabel>
                  <Spinner />
                  <Typography>Mapping Object Movements</Typography>
                </LoadingLabel>
              </span>
            )}
          </Row>
        )}

        {customTrackingTimeframesData &&
          Object.entries(customTrackingTimeframesData).map(([objectName, timeframes]) => (
            <Row
              key={objectName}
              onClick={() => {
                const vttUrl = getVttUrlByName(objectName);
                onObjectDescriptionChangeWithVtt(objectName, vttUrl);

                store.interactionAreaPlugin.setAreasPositionListener([vttUrl]);
              }}
              $isSelected={selectedHotspotConfig?.objectDescription === objectName}
            >
              {vttByHintData && objectName === Object.keys(vttByHintData)[0] && (
                <Label>{objectName.charAt(0).toUpperCase() + objectName.slice(1)}</Label>
              )}

              {vttByImageData && objectName === Object.keys(vttByImageData)[0] && (
                <ImageSearchPreviewWrapper>
                  <ImageSearchPreview alt="" src={getImageUrlByPublicId(objectName)} />
                </ImageSearchPreviewWrapper>
              )}
              <Timeline
                timeframes={timeframes}
                videoDuration={selectedHotspotConfig.videoDuration}
              />
            </Row>
          ))}

        {timeframesData &&
          Object.entries(timeframesData)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([objectName, timeframes]) => (
              <Row
                key={objectName}
                onClick={() => {
                  const vttUrl = getVttUrlByName(objectName);
                  onObjectDescriptionChangeWithVtt(objectName, vttUrl);

                  store.interactionAreaPlugin.setAreasPositionListener([vttUrl]);
                }}
                $isSelected={selectedHotspotConfig?.objectDescription === objectName}
              >
                <Label>{objectName.charAt(0).toUpperCase() + objectName.slice(1)}</Label>
                <Timeline
                  timeframes={timeframes}
                  videoDuration={selectedHotspotConfig.videoDuration}
                />
              </Row>
            ))}

        <Typography>
          Want something else? Type the object you are looking for or upload it's image
        </Typography>
        <Actions>
          <InputWrapper>
            <StyledInput
              placeholder="Search by object name"
              value={objectNameToTrack}
              onChange={e => setObjectNameToTrack(e.target.value)}
              onKeyDown={async e => {
                if (e.key === 'Enter') {
                  await onObjectToTrack();
                  setObjectNameToTrack('');
                }
              }}
            />
            <SearchIconWrapper
              onClick={async () => {
                await onObjectToTrack();
                setObjectNameToTrack('');
              }}
            >
              <Search size="sm" />
            </SearchIconWrapper>
          </InputWrapper>
          or
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <StyledButton>
              {isImageUploading ? (
                'Loading...'
              ) : (
                <>
                  <SearchByImage /> Search by image
                </>
              )}
            </StyledButton>
          </div>
        </Actions>
      </Rows>
    </ContentWrapper>
  );
};

export default TrackAnythingTabContent;
