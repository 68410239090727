import React, { useState, useCallback, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-toastify';
import { Add, Expand } from '@clds/icon';
import { Button } from '@clds/button';
import Typography from '@clds/typography';
import AddNewInteractionModal from '../AddNewInteractionModal';
import { ReactComponent as AddInteractionIcon } from '../../icons/add_interaction_icon.svg';
import FilmIcon from '../../icons/FilmIcon';
import { getConfigFromUrl } from '../../utils/getConfigFromUrl';
import { getImageUrlFromVideoByPublicId } from '../../utils/getUrlByPublicId';
import { useStore } from '../../utils/store';
import { createUrlFromArrayObjects } from '../../utils/createUrlFromArrayObjects';
import { useVideoUpload } from '../../hooks/useVideoUpload';
import { Header } from '../Header/Header';
import useVtt from '../../hooks/useVtt';
import useResponsiveMediaQuery from '../../hooks/useResponsiveMediaQuery';
import { getHotSpotText, openPlayerInNewTab } from '../../utils/utils';
import { themes } from '../EditHotspot/hotspotWidgetThemes';

import { Panel, PanelHeaderWrapper, PreviewSection } from '../shared.style';
import {
  Root,
  Content,
  StyledAddButton,
  PlayerWrapper,
  TimelineWrapper,
  VideoPreviewRow,
  StyledImage,
  Hotspot,
  StyledShadowIcon,
  AddNewInteractionModalWrapper,
  StyledDeleteIcon,
  HotspotLeftSlot,
  ExpandButtonWrapper
} from './Preview.styled';

export const Preview = ({ player, timeline }) => {
  const isSmallDevice = useResponsiveMediaQuery('(max-width: 700px)');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { configs, globalConfig } = getConfigFromUrl();
  const { vttResults } = useVtt();
  const [addModalOpenForPublicId, setAddModalOpenForPublicId] = useState(null);
  const [, setUrl] = useState(0); // for nicer visual effect when changing order of a video
  const { store } = useStore();

  const onHotspotSelect = ({ hotspotId, publicId }) => {
    searchParams.set('selectedHotspotId', hotspotId);
    searchParams.set('currentlyPlayingPublicId', publicId);

    navigate(`/edit?${searchParams.toString()}`);
  };

  const onAddNewHotspot = ({ hotspot, publicId }) => {
    const newHotspotId = uuidv4();

    const newConfig = {
      hotspotId: newHotspotId,
      animation: 'fade-in',
      type: 'tracking',
      position: 'center',
      hotspotTheme: themes[0].name,
      objectTrackingResultsWithVtts: hotspot.objectTrackingResultsWithVtts,
      objectTrackingResultsUrl: hotspot.objectTrackingResultsUrl,
      detectedObjects: hotspot.detectedObjects,
      publicId,
      parentConfigId: hotspot.hotspotId,
      videoDuration: hotspot.videoDuration,
      autoplayEnabled: globalConfig?.autoplayEnabled,
      loopEnabled: globalConfig?.loopEnabled,
      playerControlsEnabled: globalConfig?.playerControlsEnabled
    };

    const updatedConfig = [...configs, newConfig];

    const newUrl = createUrlFromArrayObjects(window.location.href, updatedConfig, {
      selectedHotspotId: newHotspotId,
      currentlyPlayingPublicId: publicId
    });

    const urlObject = new URL(newUrl);
    urlObject.pathname = urlObject.pathname.replace('preview', 'edit');
    window.location.href = urlObject.href;
  };

  const { uploadAndObjectTrackingVideo, isUploading } = useVideoUpload();

  const handleFileSelect = event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    uploadAndObjectTrackingVideo({ file });
  };

  const onAddClipItemClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'video/*';
    fileInput.style.display = 'none';
    fileInput.onchange = handleFileSelect;
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);

    setAddModalOpenForPublicId(null);
  };

  const onDragEnd = useCallback(
    result => {
      const { destination, source } = result;

      if (!destination) {
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      // Filter configs to get only parent hotspots (those without a parentConfigId)
      const parentConfigs = configs.filter(config => !config.parentConfigId);
      const childConfigs = configs.filter(config => config.parentConfigId);

      const newParentConfigs = Array.from(parentConfigs);
      const [moved] = newParentConfigs.splice(source.index, 1);
      newParentConfigs.splice(destination.index, 0, moved);

      const newUrl = createUrlFromArrayObjects(
        window.location.href,
        [...newParentConfigs, ...childConfigs],
        globalConfig
      );
      window.history.replaceState({ path: newUrl }, '', newUrl);

      setUrl(prevVersion => prevVersion + 1);
      window.location.reload();
    },
    [configs, globalConfig]
  );

  const onHotspotDeleteClick = useCallback((event, hotspot) => {
    event.stopPropagation();
    console.log(hotspot);
  }, []);

  const { openPlayerInNewTab: onOpenPlayerInNewTab } = openPlayerInNewTab();

  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  if (isSmallDevice) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography>IVE studio is not available on mobile devices.</Typography>
      </div>
    );
  }

  return (
    <Root>
      <ToastContainer />
      <Header rightSlot={<Button isDisabled={globalConfig.currentlyPlayingPublicId === 'undefined'} onClick={onOpenPlayerInNewTab}>Export</Button>} withSeparator />

      <Content>
        <Panel $isExpanded={isPanelExpanded}>
          {!isSmallDevice && (
            <PanelHeaderWrapper>
              {!configs.length && (
                <StyledAddButton leftSlot={<Add size="sm" />} onClick={onAddClipItemClick}>
                  Video
                </StyledAddButton>
              )}
            </PanelHeaderWrapper>
          )}

          {isSmallDevice && (
            <ExpandButtonWrapper>
              <Expand onClick={() => setIsPanelExpanded(!isPanelExpanded)} />
            </ExpandButtonWrapper>
          )}

          {isPanelExpanded && isSmallDevice && (
            <PanelHeaderWrapper>
              {!configs.length && (
                <StyledAddButton leftSlot={<Add size="sm" />} onClick={onAddClipItemClick}>
                  Video
                </StyledAddButton>
              )}
            </PanelHeaderWrapper>
          )}

          {(!isSmallDevice || (isSmallDevice && isPanelExpanded)) && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="videos">
                {provided => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                  >
                    {Object.entries(vttResults).map(([publicId, hotspotGroup], index) => {
                      return (
                        <Draggable key={publicId} draggableId={publicId} index={index}>
                          {provided => (
                            <div
                              key={publicId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <VideoPreviewRow>
                                <FilmIcon />
                                <StyledImage
                                  src={getImageUrlFromVideoByPublicId(publicId)}
                                  alt=""
                                  loading="lazy"
                                />
                              </VideoPreviewRow>
                              {hotspotGroup.map(([hotspot], hotspotIndex) => {
                                return (
                                  <Fragment key={hotspot.hotspotId}>
                                    {hotspot?.type && (
                                      <Hotspot
                                        key={hotspot.hotspotId}
                                        $isHoveredInTimeline={
                                          store.hoveredItemInTimeline === hotspot.hotspotId
                                        }
                                        onClick={() =>
                                          onHotspotSelect({
                                            hotspotId: hotspot.hotspotId,
                                            publicId
                                          })
                                        }
                                      >
                                        <HotspotLeftSlot>
                                          <StyledShadowIcon />
                                          {getHotSpotText(hotspot, index)}
                                        </HotspotLeftSlot>
                                        <StyledDeleteIcon
                                          onClick={event => onHotspotDeleteClick(event, hotspot)}
                                        />
                                      </Hotspot>
                                    )}

                                    {hotspotIndex === hotspotGroup.length - 1 && (
                                      <AddNewInteractionModalWrapper>
                                        <Hotspot
                                          $isHighlighted
                                          onClick={() =>
                                            onAddNewHotspot({
                                              hotspot: hotspotGroup[0][0],
                                              publicId
                                            })
                                          }
                                        >
                                          <HotspotLeftSlot>
                                            <AddInteractionIcon />
                                            Add interaction
                                          </HotspotLeftSlot>
                                        </Hotspot>
                                        {addModalOpenForPublicId === publicId && (
                                          <AddNewInteractionModal />
                                        )}
                                      </AddNewInteractionModalWrapper>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {isUploading && (
            <VideoPreviewRow $isUploading>
              <FilmIcon />
              Uploading...
            </VideoPreviewRow>
          )}
        </Panel>

        <PreviewSection>
          <PlayerWrapper>{player}</PlayerWrapper>
          {store.isTimelineVisible && <TimelineWrapper>{timeline}</TimelineWrapper>}
        </PreviewSection>
      </Content>
    </Root>
  );
};
