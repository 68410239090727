import React, { useState, useEffect } from 'react';
import Typography from '@clds/typography';
import { Button } from '@clds/button';
import { Add } from '@clds/icon';
import { Tooltip } from '@clds/tooltip';
import WizardSteps from '../../WizardSteps';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import { themes } from '../../../EditHotspot/hotspotWidgetThemes';
import useImageUpload from './useImageUpload';
import Link from '../../../Hotspots/Link';
import { ColorSection } from './ColorSection';
import { createUrlFromArrayObjects } from '../../../../utils/createUrlFromArrayObjects';
import { getImageUrlFromVideoByPublicId } from '../../../../utils/getUrlByPublicId';

import {
  Root,
  Wrapper,
  PreviewSection,
  SettingsSection,
  Container,
  UploadSection,
  HotspotActionTypeImage,
  HotspotActionTypeImageWrapper,
  Dropzone
} from './Edit.styled';
import {
  CleanButton,
  ContentSectionWrapper,
  InlineSection,
  Separator,
  Spinner,
  StyledInput,
  StyledTypography,
  UploadText
} from '../../../EditHotspot/EditHotspot.styled';

const Edit = () => {
  const {
    config,
    currentStep,
    goToNextStep,
    persistDataInOnboardingState,
    setMobileBreakpoint,
    isMobileDevice
  } = useOnboarding();

  const { imageUrl, isUploading, getRootProps, getInputProps, isDragActive } = useImageUpload();

  const [hotspotTitle, setHotspotTitle] = useState('Title');
  const [hotspotActionTypeImageUrl, setHotspotActionTypeImageUrl] = useState(
    config?.hotspotActionTypeImageUrl || ''
  );
  const [hotspotCtaText, setHotspotCtaText] = useState('Buy Now');
  const [selectedThemeName, setSelectedThemeName] = useState(themes[1].name);

  const isNextButtonDisabled = !hotspotTitle && !hotspotActionTypeImageUrl && !hotspotCtaText;

  const videoThumbnail = getImageUrlFromVideoByPublicId(config.publicId);

  useEffect(() => {
    setMobileBreakpoint(700);
  }, [setMobileBreakpoint]);

  useEffect(() => {
    if (imageUrl) {
      setHotspotActionTypeImageUrl(imageUrl);
    }
  }, [imageUrl]);

  const persistDataAndGoToNextStep = () => {
    const dataToPersist = {
      hotspotTitle,
      hotspotActionTypeImageUrl,
      hotspotCtaText,
      hotspotTheme: selectedThemeName
    };

    const newConfig = { ...config, ...dataToPersist };

    persistDataInOnboardingState(newConfig);
    const newUrl = createUrlFromArrayObjects(window.location.href, [newConfig], {
      currentlyPlayingPublicId: config.publicId,
      selectedHotspotId: config.hotspotId
    });

    window.history.replaceState({ path: newUrl }, '', newUrl);
    goToNextStep();
  };

  return (
    <Root>
      {!isMobileDevice && <WizardSteps currentStep={currentStep} isVideoUploading={isUploading} />}
      <Container>
        <Typography size="xl">Edit options</Typography>
        <Wrapper>
          <PreviewSection id="hotspot-preview" videoThumbnail={videoThumbnail}>
            <Link
              title={hotspotTitle}
              image={hotspotActionTypeImageUrl}
              checkOutText={hotspotCtaText}
              theme={selectedThemeName}
            />
          </PreviewSection>
          <SettingsSection>
            <ContentSectionWrapper>
              <StyledTypography size="xs">Title</StyledTypography>
              <InlineSection>
                <StyledInput
                  placeholder="Enter a title text"
                  value={hotspotTitle}
                  onChange={event => setHotspotTitle(event.target.value)}
                />
                <CleanButton onClick={() => setHotspotTitle('')}>✕</CleanButton>
              </InlineSection>

              <StyledTypography size="xs">Button</StyledTypography>
              <InlineSection>
                <StyledInput
                  placeholder="Enter a CTA text"
                  value={hotspotCtaText}
                  onChange={event => setHotspotCtaText(event.target.value)}
                />
                <CleanButton onClick={() => setHotspotCtaText('')}>✕</CleanButton>
              </InlineSection>

              {isUploading && (
                <HotspotActionTypeImageWrapper>
                  <Spinner />
                </HotspotActionTypeImageWrapper>
              )}

              {hotspotActionTypeImageUrl && !isUploading && (
                <HotspotActionTypeImageWrapper>
                  <HotspotActionTypeImage src={hotspotActionTypeImageUrl} alt="" />
                  <CleanButton onClick={() => setHotspotActionTypeImageUrl('')}>✕</CleanButton>
                </HotspotActionTypeImageWrapper>
              )}

              {!hotspotActionTypeImageUrl && !isUploading && (
                <UploadSection>
                  <Dropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <UploadText>
                        <Add size="xs" />
                        <Typography size="xs">Add an image</Typography>
                      </UploadText>
                    )}
                  </Dropzone>
                  <CleanButton>✕</CleanButton>
                </UploadSection>
              )}

              {!isMobileDevice && <Separator />}

              <StyledTypography size="xs">Style</StyledTypography>
              <StyledTypography size="xxs">Choose the color and font style for your interactive element</StyledTypography>
              <ColorSection
                isOnboarding={true}
                hotspotTheme={selectedThemeName}
                onHotspotThemeChange={setSelectedThemeName}
              />
            </ContentSectionWrapper>
          </SettingsSection>
        </Wrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {isNextButtonDisabled && (
            <Tooltip placement={'top'} title={'At least one field must be filled'}>
              <Button style={{ cursor: 'not-allowed', opacity: 0.5 }}>Next</Button>
            </Tooltip>
          )}

          {!isNextButtonDisabled && <Button onClick={persistDataAndGoToNextStep}>Next</Button>}
        </div>
      </Container>
    </Root>
  );
};

export default Edit;
