import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Button } from '@clds/button';
import Typography from '@clds/typography';
import bannerBackground from '../../assets/banner_background.png';

export const Content = styled.div`
  display: flex;
  padding-top: 1rem;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 3rem;
  gap: 1.5rem;
  overflow: hidden;

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  overflow: scroll;
  padding: 1rem 0;

  @media (min-width: 700px) {
    width: 55%;
    height: 100%;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;
  height: 7rem;
  gap: 1rem;
  width: 100%;
  background-color: #392766;
  background-image: url(${bannerBackground});
  background-size: cover;
  background-position: center;

  &:first-child {
    font-weight: bold;
  }

  &:last-child {
    font-weight: 100;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 3rem;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const PlayerWrapper = styled.div`
  position: relative;
  width: 45%;
  display: flex;
  height: 50%;
  align-items: baseline;

  @media (min-width: 700px) {
    height: 100%;
    max-height: 35rem;
  }
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 2rem 0;

  @media (min-width: 640px) {
    gap: 1.5rem;
  }
`;

export const LoadingLabel = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  * {
    color: ${globalTheme.palette.primary};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid;
  transition:
    border-color 0.6s ease,
    filter 0.3s ease;

  * {
    font-weight: 300;
  }

  border-color: ${({ $isSelected }) =>
    $isSelected ? globalTheme.palette.primary : globalTheme.palette.secondary};

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    border-color: ${globalTheme.palette.primary};
    filter: brightness(120%);
  }
`;

export const Label = styled.div`
  margin: 1rem;
  max-width: 6rem;
  width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;

  @media (min-width: 700px) {
    margin-top: 2rem;
    gap: 1rem;
  }

  @media (max-width: 1220px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const StyledInput = styled.input`
  height: 40px;
  flex-grow: 1;
  outline: none;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
  transition: opacity 0.3s ease;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  @media (min-width: 700px) {
    width: 100%;
  }
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  background-color: #fffffe;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
`;

export const NextButtonWrapper = styled.div`
  padding: ${({ isMobileDevice }) => (isMobileDevice ? '2rem' : '0')};
`;

export const NextButton = styled(Button)`
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
`;

export const ImageSearchPreviewWrapper = styled.div`
  margin: 1rem;
  width: 6rem;
  max-width: 6rem;
  display: flex;
`;

export const ImageSearchPreview = styled.img`
  height: 40px;
  border-radius: 0.3rem;
  object-fit: contain;
`;

export const Spinner = styled.div`
  border: 0.2rem solid ${globalTheme.palette.primary};
  border-radius: 50%;
  border-top: 0.2rem solid ${globalTheme.palette.surface};
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const AnimatedTypography = styled(Typography)`
  animation: pulsate-fwd 0.5s ease-in-out infinite both;

  @keyframes pulsate-fwd {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const GeneralLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #5c5d9e;
  opacity: 0.7;
  animation: loadAnimation 3s linear infinite;
  z-index: 2;

  @keyframes loadAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;
