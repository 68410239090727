import { globalTheme } from '@clds/component-theme';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SettingsSection = styled.div`
  display: flex;
  width: 60%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: flex-start;

  @media (min-width: 700px) {
    padding-left: 2rem;
    padding-right: 0;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const Separator = styled.div`
  border: 0.5px solid ${globalTheme.palette.secondaryAlt};
  width: 100%;
  margin: 1rem 0;
`;

export const InlineSection = styled(Section)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
`;

export const PreviewSection = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${globalTheme.palette.surfaceAlt};
  background-image: url(${props => props.videoThumbnail});
  background-size: cover;
  background-position: center;
  perspective: 1000px;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
