import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import isUrl from 'is-url';
import { Check } from '@clds/icon';
import Tooltip from '@clds/tooltip';
import Typography from '@clds/typography';
import { Button } from '@clds/button';
import { ToastContainer } from 'react-toastify';
import UploadCloudIcon from '../../assets/upload_cloud.svg';
import { ReactComponent as AiZoomIcon } from '../../../../icons/ai_zoom_icon.svg';
import { ReactComponent as FilmIcon } from '../../../../icons/film_icon.svg';
import { CLOUD_NAME, SAMPLE_VIDEOS, TERMS_OF_USE_PUBLIC_ID } from '../../../../const';
import { useVideoUpload } from '../../../../hooks/useVideoUpload';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import VideoIcon from '../../../../icons/VideoIcon';
import { getImageUrlFromVideoByPublicId } from '../../../../utils/getUrlByPublicId';
import { formatDuration } from '../../../../utils/formatDurationFromSeconds';

import {
  CloudinaryLogo,
  Sections,
  Section,
  InputSectionWrapper,
  SectionLabel,
  LeftSectionBanner,
  IconWrapper,
  MainContentWrapper,
  BannerFooterWrapper,
  BrandWrapper,
  InlineSeparatorsWrapper,
  Separator,
  DropzoneSectionWrapper,
  DropzoneWithSamplesWrapper,
  DragAndDropSection,
  SamplesSection,
  PosterWrapper,
  DurationLabel,
  StyledVideoPoster,
  TermsSection,
  StyledAnchor,
  AnchorsWrapper,
  InputForm,
  NextButtonWrapper,
  StyledInput,
  StyledButton
} from './StartPage.styled';

const StartPage = () => {
  const termsOfUse = `https://res-s.cloudinary.com/${CLOUD_NAME}/image/upload/${TERMS_OF_USE_PUBLIC_ID}`;

  const [targetUrl, setTargetUrl] = useState('');

  const {
    isMobileDevice,
    setMobileBreakpoint,
    persistDataInOnboardingState,
    goToNextStep,
    handleSampleVideo,
    setStep
  } = useOnboarding();
  const { uploadAndObjectTrackingVideo, isUploading } = useVideoUpload();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'video/*': ['.mp4', '.mkv', '.avi', '.mov']
    },
    onDrop: async acceptedFiles => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          goToNextStep();

          await uploadAndObjectTrackingVideo({
            file,
            isUploadFromOnboarding: true,
            persistDataInOnboardingState
          });
        } catch (error) {
          console.error('Error during upload and object tracking:', error);
        }
      }
    }
  });

  const submitTargetUrl = () => {
    if (targetUrl) {
      persistDataInOnboardingState({ hotspotId: uuidv4(), targetUrl });
      setStep('scraper');
    }
  };

  useEffect(() => {
    setMobileBreakpoint('890');
  }, [setMobileBreakpoint]);

  return (
    <Sections>
      <ToastContainer />
      {!isMobileDevice && (
        <Section>
          <LeftSectionBanner>
            <MainContentWrapper>
              <IconWrapper>
                <VideoIcon />
              </IconWrapper>
              <Typography size="xxl">MAKE YOUR VIDEOS INTERACTIVE</Typography>
              <Typography size="lg">
                <p>
                  Automatically transform any video into an engaging, interactive experience with
                  just a few clicks.
                </p>
                <p>
                  Turn viewers into participants with 'shop-the-look', UGC, and affiliate links.
                </p>
                <p>
                  Captivate your audience effortlessly.
                </p>
              </Typography>
            </MainContentWrapper>

            <BannerFooterWrapper>
              <BrandWrapper>
                <CloudinaryLogo />
              </BrandWrapper>

              <TermsSection>
                <Typography>
                  By continuing, you agree to our
                </Typography>
                <AnchorsWrapper>
                  <StyledAnchor href={termsOfUse} target="_blank">
                    Terms Of Service
                  </StyledAnchor>
                  and
                  <StyledAnchor href={termsOfUse} target="_blank">
                    Privacy Policy
                  </StyledAnchor>
                </AnchorsWrapper>
              </TermsSection>
            </BannerFooterWrapper>
          </LeftSectionBanner>
        </Section>
      )}

      <Section>
        <InputSectionWrapper>
          <SectionLabel>
            <AiZoomIcon />
            <Typography size="xl">Start with a Product Page</Typography>
          </SectionLabel>
          <InputForm width="100%">
            <StyledInput
              type="text"
              placeholder="Enter target URL here"
              value={targetUrl}
              onChange={e => setTargetUrl(e.target.value)}
            />

            <Tooltip placement={'bottom'} title={'Provided value must be a valid URL'}>
              <NextButtonWrapper isDisabled={!targetUrl || !isUrl(targetUrl)}>
                <Button onClick={!targetUrl || !isUrl(targetUrl) ? null : submitTargetUrl}>
                  <Check />
                </Button>
              </NextButtonWrapper>
            </Tooltip>
          </InputForm>
          <Typography>Enter your product page URL and watch as we magically transform it into an interactive video.</Typography>
          <Typography>Please keep in mind that your product page needs to have a video on it.</Typography>
        </InputSectionWrapper>

        <InlineSeparatorsWrapper>
          <Separator />
          <Typography size="xl">or</Typography>
          <Separator />
        </InlineSeparatorsWrapper>

        <DropzoneSectionWrapper>
          <SectionLabel>
            <FilmIcon />
            <Typography size="xl">Start with a Video</Typography>
          </SectionLabel>
          <Typography>Drag and drop or choose a video to begin creating your interactive content.</Typography>

          <DropzoneWithSamplesWrapper>
            <DragAndDropSection {...getRootProps()}>
              {!isUploading && (
                <>
                  <input {...getInputProps()} />
                  <img src={UploadCloudIcon} alt="Upload Icon" />
                  {isDragActive ? (
                    <>
                      <Typography>Drop the video here ...</Typography>
                      <div style={{ visibility: 'hidden' }}>
                        <Button />
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography>Drag and drop or</Typography>
                      <StyledButton>Choose a video</StyledButton>
                    </>
                  )}
                </>
              )}

              {isUploading && (
                <>
                  <img src={UploadCloudIcon} alt="Upload Icon" />
                  <Typography>Uploading...</Typography>
                  <div style={{ visibility: 'hidden' }}>
                    <Button />
                  </div>
                </>
              )}
            </DragAndDropSection>
            <SamplesSection>
              {SAMPLE_VIDEOS.map(video => (
                <PosterWrapper
                  key={video.publicId}
                  onClick={() => handleSampleVideo(video.publicId)}
                >
                  <StyledVideoPoster
                    src={getImageUrlFromVideoByPublicId(video.publicId)}
                    alt="poster"
                  />
                  <DurationLabel>{formatDuration(video.videoDuration)}</DurationLabel>
                </PosterWrapper>
              ))}
            </SamplesSection>
          </DropzoneWithSamplesWrapper>
          {isMobileDevice && (
            <TermsSection>
              <Typography>By continuing, you are indicating that you accept our</Typography>
              <AnchorsWrapper>
                <StyledAnchor href={termsOfUse} target="_blank">
                  Terms Of Service
                </StyledAnchor>
                and
                <StyledAnchor href={termsOfUse} target="_blank">
                  Privacy Policy
                </StyledAnchor>
              </AnchorsWrapper>
            </TermsSection>
          )}
        </DropzoneSectionWrapper>
      </Section>
    </Sections>
  );
};

export default StartPage;
