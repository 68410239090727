import { Button } from '@clds/button';
import Typography from '@clds/typography';
import React from 'react';
import styled from 'styled-components';
import bannerBackground from '../../assets/banner_background.png';
import { globalTheme } from '@clds/component-theme';

const Root = styled.div`
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 86vw;
  height: 7rem;
  background-color: #392766;
  background-color: #392766;
  background-image: url(${bannerBackground});
  background-size: cover;
  background-position: center;
  opacity: 0.9;

  * {
    font-weight: bold;
  }
`;

const StyledButton = styled(Button)`
  background-color: #d1d6e0;
  color: ${globalTheme.palette.backgroundAlt};
`;

const VideoReadyBanner = ({ onClick }) => {
  return (
    <Root>
      <Typography size="xxl">VIDEO READY!</Typography>
      <StyledButton onClick={onClick}>Preview</StyledButton>
    </Root>
  );
};

export default VideoReadyBanner;
