import React from 'react';
import styled from 'styled-components';
import { Section, StyledTypography } from './EditHotspot.styled';
import Compass from './Compass';
import { ContentSection } from './ContentSection';
import useEditHotspot from './useEditHotspot';

export const PositionSectionWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0 1rem;
`;

const CompassWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PositionSection = () => {
  const { onHotspotPositionChange, hotspotPosition } = useEditHotspot();

  return (
    <PositionSectionWrapper>
      <StyledTypography size="xs">Position</StyledTypography>
      <CompassWrapper>
        <Compass
          onHotspotPositionChange={onHotspotPositionChange}
          hotspotPosition={hotspotPosition}
        />
      </CompassWrapper>
      <ContentSection />
    </PositionSectionWrapper>
  );
};
