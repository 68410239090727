import { elementsCreator } from '../../utils/dom';
import { getLegendHorizontalPosition, getLegendVerticalPosition } from '../EditHotspot/Compass';
import {
  INTERACTION_AREAS_CONTAINER_CLASS_NAME,
  INTERACTION_AREAS_PREFIX
} from '../../plugins/interaction-area/interaction-area.const';

export const getWidgetItem = (
  clickedHotspot,
  theme = {},
  isOnboarding = false,
  onboardingConfig,
  itemHotspotMarkerElement
) => {
  const isOpenInNewTab = clickedHotspot?.openInNewTab === 'true';
  const containerClass = `${INTERACTION_AREAS_PREFIX}-widget`;

  function getItemElement() {
    return document.querySelector(`.${containerClass}`);
  }

  function remove() {
    const widgetElement = getItemElement();
    if (widgetElement) {
      widgetElement.remove();
    }
    if (itemHotspotMarkerElement) {
      itemHotspotMarkerElement.style.visibility = 'visible';
    }
  }

  function create() {
    const widget = elementsCreator({
      tag: 'div',
      attr: { class: containerClass, 'data-id': clickedHotspot.hotspotId },
      children: [
        {
          tag: 'div',
          attr: { class: `${INTERACTION_AREAS_PREFIX}-inner-widget` },
          style: {
            backgroundColor: theme['background-color'],
            fontFamily: theme['font-family'],
            animationName: isOnboarding ? onboardingConfig.animation : clickedHotspot.animation,
            backdropFilter: theme['backdrop-filter'],
            '-webkit-backdrop-filter': theme['backdrop-filter']
          },
          children: [
            {
              tag: 'div',
              style: {
                display: 'flex',
                position: 'relative',
                width: '100%',
                height: '100%'
              },
              children: [
                {
                  tag: 'button',
                  children: '✕',
                  attr: { class: `${INTERACTION_AREAS_PREFIX}-close-widget` },
                  style: {
                    color: theme['text-color'],
                    padding: '3px 4px'
                  },
                  onClick: remove
                },
                {
                  tag: 'label',
                  children: clickedHotspot.hotspotTitle,
                  style: {
                    color: theme['text-color'],
                    padding: '0 1.5rem',
                    width: '100%',
                    height: '100%'
                  }
                }
              ]
            },
            ...(clickedHotspot.hotspotActionTypeImageUrl &&
            clickedHotspot.hotspotActionTypeImageUrl !== 'null'
              ? [
                  {
                    tag: 'img',
                    attr: { src: clickedHotspot.hotspotActionTypeImageUrl }
                  }
                ]
              : []),
            {
              tag: 'a',
              attr: {
                href: clickedHotspot.targetUrl,
                target: isOpenInNewTab && '_blank'
              },
              children: clickedHotspot.hotspotCtaText,
              style: {
                color: theme['button-text-color'],
                backgroundColor: theme['button-color'],
                borderRadius: theme['button-shape'] === 'pill' ? '20px' : '5px'
              }
            }
          ]
        }
      ]
    });

    const x = getLegendHorizontalPosition(clickedHotspot.position);
    const y = getLegendVerticalPosition(clickedHotspot.position);
    widget.style.cssText = `${widget.style.cssText};${x};${y}`;

    const element = getItemElement();
    if (element) {
      element.replaceWith(widget);
    } else {
      document.querySelector(`.${INTERACTION_AREAS_CONTAINER_CLASS_NAME}`).append(widget);
    }
  }

  return { create, remove };
};
