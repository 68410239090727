import { useState, useMemo, useCallback, useEffect } from 'react';
import useEditHotspot from '../useEditHotspot';
import useImageUploadAndCreateVTT from '../../../hooks/useImageUploadAndCreateVTT';
import { getConfigFromUrl } from '../../../utils/getConfigFromUrl';
import { useDropzone } from 'react-dropzone';
import { INTERACTIVE_VIDEO_API } from '../../../const';
import { delay } from '../../../utils/utils';
import { fetchAndParseVtt } from '../../../utils/parseVttDataToTimeframes';
import { getImageUrlByPublicId } from '../../../utils/getUrlByPublicId';
import useTrackAnythingByHintAndCreateVTT from '../../../hooks/useTrackAnythingByHintAndCreateVTT';

const useTrackAnythingTabContent = () => {
  const { configs, globalConfig } = getConfigFromUrl();
  const selectedHotspotConfig = useMemo(
    () => configs?.find(videoConfig => videoConfig.hotspotId === globalConfig.selectedHotspotId),
    [configs, globalConfig]
  );

  const {
    vttByImageData: fetchedVttByImageData,
    vttByHintData: fetchedVttByHintData,
    objectTrackingResultsWithVtts,
    onObjectTrackingResultsWithVttsChange,
    onObjectDescriptionChangeWithVtt,
    onVttByImageChange,
    onVttByHintChange,
    onHotspotActionTypeImageUrlChange
  } = useEditHotspot();

  const [vttsData, setVttsData] = useState(() => {
    try {
      const parsedData = JSON.parse(decodeURIComponent(objectTrackingResultsWithVtts));
      return parsedData || [];
    } catch (error) {
      console.error('Failed to parse vttsData:', error);
      return [];
    }
  });
  const [isGeneratingVtts, setIsGeneratingVtts] = useState(false);
  const [isFetchingVtts, setIsFetchingVtts] = useState(false);
  const [timeframesData, setTimeframesData] = useState(null);
  const [customTrackingTimeframesData, setCustomTrackingTimeframesData] = useState(null);
  const [objectNameToTrack, setObjectNameToTrack] = useState('');

  const [vttByImageData, setVttByImageData] = useState(() => {
    try {
      return fetchedVttByImageData ? JSON.parse(decodeURIComponent(fetchedVttByImageData)) : null;
    } catch (error) {
      console.error('Error parsing fetchedVttByImageData:', error);
      return null;
    }
  });

  const [vttByHintData, setVttByHintData] = useState(() => {
    try {
      return fetchedVttByHintData ? JSON.parse(decodeURIComponent(fetchedVttByHintData)) : null;
    } catch (error) {
      console.error('Error parsing fetchedVttByHintData:', error);
      return null;
    }
  });

  const {
    uploadImageAndCreateVTT,
    isUploading: isImageUploading,
    isCreatingVtt: isCreatingVttByImageTracking,
    imagePublicId
  } = useImageUploadAndCreateVTT(selectedHotspotConfig);

  const onFileSelected = async acceptedFiles => {
    const file = acceptedFiles[0];
    if (!file) {
      console.error('No file selected');
      return;
    }
    const vttData = await uploadImageAndCreateVTT(file);
    onVttByImageChange(vttData);

    if (
      !selectedHotspotConfig.hotspotActionTypeImageUrl &&
      selectedHotspotConfig.hotspotActionTypeImageUrl !== 'null'
    ) {
      onHotspotActionTypeImageUrlChange(getImageUrlByPublicId(Object.keys(vttData)[0]));
    }

    setVttByImageData(vttData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileSelected,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    }
  });

  const { createVttByHint, isCreatingVtt: isCreatingVttByHintTracking } =
    useTrackAnythingByHintAndCreateVTT(selectedHotspotConfig);

  const onObjectToTrack = async () => {
    try {
      const vttData = await createVttByHint({ objectNameToTrack });
      onVttByHintChange(vttData);
      setVttByHintData(vttData);
    } catch (error) {
      console.error('Error in onObjectToTrack:', error);
    }
  };
  const getVttUrlByName = objectName => {
    const objectEntryInList = vttsData.find(entry => entry.hasOwnProperty(objectName));
    return objectEntryInList
      ? objectEntryInList[objectName]
      : vttByImageData?.[objectName] || vttByHintData?.[objectName];
  };

  const generateVtt = async ({ hotspotId, publicId, requestObject, objectTrackingResultsUrl }) => {
    try {
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ hotspotId, publicId, requestObject, objectTrackingResultsUrl })
      });

      if (response.ok) {
        console.log('Generate VTT response ok:', response);
        const data = await response.json();
        console.log('Generate VTT data:', data);
        return { requestObject, vtt: data.vttUrl };
      } else {
        const errorData = await response.json();
        console.error(' 2 Generate VTT error:', errorData.errors[0].message);
      }
    } catch (error) {
      console.error('Failed POST request to /generate-vtt', error);
    }
  };

  const generateVttsForDetectedObjects = useCallback(async () => {
    const objects = decodeURIComponent(selectedHotspotConfig.detectedObjects).split(',');
    const vttsDataArray = [];

    setIsGeneratingVtts(true);

    for (const objectName of objects) {
      const vttData = await generateVtt({
        hotspotId: selectedHotspotConfig.hotspotId,
        publicId: selectedHotspotConfig.publicId,
        requestObject: objectName,
        objectTrackingResultsUrl: selectedHotspotConfig.objectTrackingResultsUrl
      });
      vttsDataArray.push({ [objectName]: vttData ? vttData.vtt : null });

      await delay(3000); // for some reason some requests failing when using promiseAll for vtt gen. Also if no delay between requests.
    }

    console.log('Generated VTTs:', vttsDataArray);
    setVttsData(vttsDataArray);
    onObjectTrackingResultsWithVttsChange(vttsDataArray);
    setIsGeneratingVtts(false);
  }, [
    onObjectTrackingResultsWithVttsChange,
    selectedHotspotConfig.detectedObjects,
    selectedHotspotConfig.hotspotId,
    selectedHotspotConfig.objectTrackingResultsUrl,
    selectedHotspotConfig.publicId
  ]);

  useEffect(() => {
    if (!vttsData.length && selectedHotspotConfig.objectTrackingResultsUrl) {
      (async () => {
        await delay(3000);
        generateVttsForDetectedObjects();
      })();
    }
  }, [
    generateVttsForDetectedObjects,
    selectedHotspotConfig.objectTrackingResultsUrl,
    vttsData.length
  ]);

  useEffect(() => {
    const fetchAllVtts = async () => {
      setIsFetchingVtts(true);
      const allTimeframes = {};

      await Promise.all(
        vttsData.map(async vttObject => {
          if (!vttObject) {
            return null;
          }

          const objectName = Object.keys(vttObject)[0];
          const vttUrl = vttObject[objectName];
          if (vttUrl) {
            const timeframes = await fetchAndParseVtt(vttUrl);
            allTimeframes[objectName] = timeframes;
          }
        })
      );

      setTimeframesData(allTimeframes);
      setIsFetchingVtts(false);
    };

    if (vttsData.length > 0) {
      fetchAllVtts();
    } else {
      setIsFetchingVtts(false);
    }
  }, [vttsData]);

  useEffect(() => {
    const addVttByImageToTimeframes = async () => {
      if (vttByImageData) {
        const objectName = Object.keys(vttByImageData)[0];
        const vttUrl = vttByImageData[objectName];
        if (vttUrl) {
          const newTimeframes = await fetchAndParseVtt(vttUrl).catch(error => {
            console.error('Error fetching or parsing VTT:', error);
          });
          setCustomTrackingTimeframesData(prevTimeframesData => ({
            [objectName]: newTimeframes,
            ...prevTimeframesData
          }));
        }
      }
    };

    addVttByImageToTimeframes();
  }, [vttByImageData]);

  useEffect(() => {
    const addVttByHintToTimeframes = async () => {
      if (vttByHintData) {
        const objectName = Object.keys(vttByHintData)[0];
        const vttUrl = vttByHintData[objectName];
        if (vttUrl) {
          const newTimeframes = await fetchAndParseVtt(vttUrl).catch(error => {
            console.error('Error fetching or parsing VTT:', error);
          });
          setCustomTrackingTimeframesData(prevTimeframesData => ({
            [objectName]: newTimeframes,
            ...prevTimeframesData
          }));
        }
      }
    };

    addVttByHintToTimeframes();
  }, [vttByHintData]);

  return {
    isFetchingVtts,
    isGeneratingVtts,
    vttByHintData,
    vttByImageData,
    timeframesData,
    customTrackingTimeframesData,
    isCreatingVttByImageTracking,
    isCreatingVttByHintTracking,
    selectedHotspotConfig,
    objectNameToTrack,
    setObjectNameToTrack,
    onObjectToTrack,
    getRootProps,
    getInputProps,
    isImageUploading,
    getVttUrlByName,
    onObjectDescriptionChangeWithVtt,
    imagePublicId
  };
};

export default useTrackAnythingTabContent;
