import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  WizardStepsContainer,
  Step,
  StepLabel,
  StepNumber,
  StepConnector,
  Spinner
} from './WizardSteps.styled';

const getSteps = isVideoUploading => [
  { label: isVideoUploading ? 'Uploading...' : 'Upload', step: 0 },
  { label: 'Type', step: 1 },
  { label: 'Track anything', step: 2 },
  { label: 'Edit content', step: 3 },
  { label: 'Actions', step: 4 },
  { label: 'Preview', step: 5 }
];

const WizardSteps = ({ currentStep, isVideoUploading }) => {
  const navigate = useNavigate();
  const handleStepClick = route => {
    navigate(route);
  };

  return (
    <Fragment>
      <WizardStepsContainer>
        {getSteps(isVideoUploading).map((step, index) => {
          const isCompleted = index < currentStep;
          const isCurrent = index === currentStep;
          return (
            <Fragment key={step.label}>
              {index !== 0 && <StepConnector completed={isCompleted ? 1 : 0} />}
              <Step onClick={() => handleStepClick(step.route)}>
                <StepNumber completed={isCompleted ? 1 : 0} current={isCurrent ? 1 : 0}>
                  {index === 0 && isVideoUploading ? <Spinner /> : index + 1}
                </StepNumber>
                <StepLabel completed={isCompleted ? 1 : 0} current={isCurrent ? 1 : 0}>
                  {step.label}
                </StepLabel>
              </Step>
            </Fragment>
          );
        })}
      </WizardStepsContainer>
    </Fragment>
  );
};

export default WizardSteps;
