export function createUrlFromArrayObjects(baseURL, paramsArray, globalConfig = {}) {
  const url = new URL(baseURL);

  url.search = '';

  paramsArray.forEach(obj => {
    Object.entries(obj).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  });

  Object.entries(globalConfig).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  return url.toString();
}
