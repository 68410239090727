import { CLOUD_NAME } from '../const';
import { getConfigFromUrl } from './getConfigFromUrl';

export const getGroupedHotspots = configs => {
  if (!configs?.length) {
    return {};
  }

  return configs.reduce((acc, hotspot) => {
    if (hotspot.publicId) {
      acc[hotspot.publicId] = acc[hotspot.publicId] || [];
      acc[hotspot.publicId].push(hotspot);
    }
    return acc;
  }, {});
};

export const openPlayerInNewTab = onboardingConfig => {
  const { globalConfig } = getConfigFromUrl();
  const domain = 'https://ive-player.cloudinary.com/';
  let search = window.location.search.replace('currentlyPlayingPublicId', 'public_id');

  let params = new URLSearchParams(search);

  if (onboardingConfig && onboardingConfig.animation) {
    params.set('animation', onboardingConfig.animation);
  }

  if (onboardingConfig && onboardingConfig.pauseOnClick !== undefined) {
    params.set('pauseOnClick', onboardingConfig.pauseOnClick.toString());
  }

  search = `?${params.toString()}`;

  const isAutoplayEnabled = globalConfig?.autoplayEnabled || false;
  const isLoopEnabled = globalConfig?.loopEnabled || false;
  const arePlayerControlsEnabled = globalConfig?.playerControlsEnabled || true;

  const isMuted = isAutoplayEnabled || isLoopEnabled;

  const playerLink = decodeURIComponent(
    `${domain}${search}&cloud_name=${CLOUD_NAME}&player[muted]=${isMuted}&player[autoplay]=${isAutoplayEnabled}&player[loop]=${isLoopEnabled}&player[controls]=${arePlayerControlsEnabled}&source[sourceTypes][0]=mp4&player[playbackRates]=[0.5,1.0,1.5,2.0]`
  );

  return {
    playerLink,
    openPlayerInNewTab: () => window.open(playerLink, '_blank', 'noopener noreferrer')
  };
};

export const getHotSpotText = (item, index) => {
  const indexText = index !== undefined ? `hotspot #${index + 1}` : '';
  const defaultText = item.objectDescription || item.trackingObject || indexText;
  return (!item.trackingObject ? item.parent?.trackingObject : defaultText) || defaultText;
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
