import { createContext, useReducer, useContext } from 'react';

export const initialStore = {
  hotspots: [],
  interactionAreasData: [],
  showPositionSelectorFor: false,
  hasData: false,
  config: [],
  hoveredItemInTimeline: null,
  isTimelineVisible: true
};

export const Store = createContext(initialStore);

export const useStore = () => useContext(Store);

export function StoreProvider(props) {
  const [store, updateStore] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    initialStore
  );

  return <Store.Provider value={{ store, updateStore }}>{props.children}</Store.Provider>;
}
