import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Button } from '@clds/button';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: ${({ $isDisabled }) => ($isDisabled ? 'blur(2px)' : 'blur(0)')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0 2rem 0;
`;

export const LoadingLabel = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;

  * {
    color: ${globalTheme.palette.primary};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid;
  cursor: pointer;
  transition: border-color 0.6s ease, filter 0.3s ease;

  * {
    font-weight: 300;
  }

  border-color: ${({ $isSelected }) =>
    $isSelected ? globalTheme.palette.primary : globalTheme.palette.secondary};

  &:hover {
    border-color: ${globalTheme.palette.primary};
    filter: brightness(120%);
  }
`;

export const Label = styled.div`
  margin: 1rem;
  max-width: 6rem;
  width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Spinner = styled.div`
  border: 0.2rem solid ${globalTheme.palette.primary};
  border-radius: 50%;
  border-top: 0.2rem solid ${globalTheme.palette.surface};
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  background-color: #fffffe;

  div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
`;

export const ImageSearchPreviewWrapper = styled.div`
  margin: 1rem;
  width: 6rem;
  max-width: 6rem;
  display: flex;
`;

export const ImageSearchPreview = styled.img`
  height: 40px;
  border-radius: 0.3rem;
  object-fit: contain;
`;
