import { useState } from 'react';
import { toast } from 'react-toastify';
import { INTERACTIVE_VIDEO_API } from '../../../../const';
import { useOnboarding } from '../../../../hooks/useOnboarding';

const useTrackByImageAndCreateVtt = config => {
  const [isCreatingVtt, setIsCreatingVtt] = useState(false);
  const { setStep } = useOnboarding();

  const trackByImageAndCreateVtt = async (videoPublicId, imagePublicId, productName) => {
    try {
      if (!videoPublicId || !imagePublicId) {
        throw new Error('Failure, no public ID/s provided');
      }

      setIsCreatingVtt(true);

      const response = await fetch(`${INTERACTIVE_VIDEO_API}/track-anything-by-image-or-hint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          videoPublicId,
          imagePublicId,
          hint: productName
        })
      });

      if (!response.ok) {
        setIsCreatingVtt(false);
        throw new Error('Failed to process image and video IDs');
      }

      const result = await response.json();
      const objectTrackingResultsUrl = result.trackAnythingResultsUrl;

      const vttResponse = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt-from-track-anything`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          objectTrackingResultsUrl,
          hotspotId: config.hotspotId
        })
      });

      if (!vttResponse.ok) {
        setIsCreatingVtt(false);
        throw new Error('Failed to create VTT file');
      }

      const vttResult = await vttResponse.json();
      setIsCreatingVtt(false);

      return { [imagePublicId]: vttResult.vttUrl };
    } catch (error) {
      toast.error('Object tracking failed. Navigating back to start page.');
      setTimeout(() => {
        setStep(0);
      }, 3000);
    } finally {
      setIsCreatingVtt(false);
    }
  };

  return { trackByImageAndCreateVtt, isCreatingVtt };
};

export default useTrackByImageAndCreateVtt;
