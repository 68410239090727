import React, { useState, useEffect } from 'react';
import Typography from '@clds/typography';
import { Button } from '@clds/button';
import Toggle from '@clds/toggle';
import WizardSteps from '../../WizardSteps';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import Link from '../../../Hotspots/Link';
import { AnimationSection } from './AnimationSection';
import { ANIMATIONS_ENUMS } from '../../../../const';
import { getImageUrlFromVideoByPublicId } from '../../../../utils/getUrlByPublicId';
import { createUrlFromArrayObjects } from '../../../../utils/createUrlFromArrayObjects';

import {
  Root,
  Wrapper,
  SettingsSection,
  Container,
  InlineSection,
  Separator,
  PreviewSection,
  ContentSectionWrapper,
  NextButtonWrapper
} from './Actions.styled';
import {
  CleanButton,
  StyledInput,
  StyledTypography
} from '../../../EditHotspot/EditHotspot.styled';

const defaultAnimationKey = Object.keys(ANIMATIONS_ENUMS).find(
  key => ANIMATIONS_ENUMS[key] === 'Fade In'
);

const Actions = () => {
  const {
    config,
    currentStep,
    goToNextStep,
    persistDataInOnboardingState,
    setMobileBreakpoint,
    isMobileDevice
  } = useOnboarding();

  const videoThumbnail = getImageUrlFromVideoByPublicId(config.publicId);

  const [pauseOnClick, setPauseOnClick] = useState(config.pauseOnClick);
  const [openInNewTab, setOpenInNewTab] = useState(config.openInNewTab);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);
  const [loopEnabled, setLoopEnabled] = useState(true);
  const [playerControlsEnabled, setPlayerControlsEnabled] = useState(true);
  const [checkOutLink, setCheckOutLink] = useState('https://cloudinary.com/');
  const [hotspotAnimation, setHotspotAnimation] = useState(defaultAnimationKey);

  const persistStateAndGoToNextStep = () => {
    const newConfig = {
      ...config,
      pauseOnClick,
      openInNewTab,
      targetUrl: checkOutLink,
      animation: hotspotAnimation,
      autoplayEnabled,
      loopEnabled,
      playerControlsEnabled
    };

    persistDataInOnboardingState(newConfig);
    const newUrl = createUrlFromArrayObjects(window.location.href, [newConfig], {
      currentlyPlayingPublicId: config.publicId,
      selectedHotspotId: config.hotspotId,
      autoplayEnabled,
      loopEnabled,
      playerControlsEnabled
    });

    window.history.replaceState({ path: newUrl }, '', newUrl);
    goToNextStep();
  };

  useEffect(() => {
    setMobileBreakpoint(700);
  }, [isMobileDevice, setMobileBreakpoint]);

  return (
    <Root>
      {!isMobileDevice && <WizardSteps currentStep={currentStep} />}
      <Container>
        <Typography size="xl">Interaction</Typography>
        <Wrapper>
          <PreviewSection id="hotspot-preview" videoThumbnail={videoThumbnail}>
            <Link
              title={config.hotspotTitle}
              image={config.hotspotActionTypeImageUrl}
              checkOutText={config.hotspotCtaText}
              checkOutLink={checkOutLink}
              theme={config.hotspotTheme}
              animation={hotspotAnimation}
            />
          </PreviewSection>
          <SettingsSection>
            <ContentSectionWrapper>
              <AnimationSection
                hotspotAnimation={hotspotAnimation}
                setHotspotAnimation={setHotspotAnimation}
              />

              <StyledTypography size="xs">Checkout link</StyledTypography>
              <InlineSection>
                <StyledInput
                  placeholder="Enter a checkout link"
                  value={checkOutLink}
                  onChange={event => setCheckOutLink(event.target.value)}
                />
                <CleanButton onClick={() => setCheckOutLink('')}>✕</CleanButton>
              </InlineSection>

              <Separator />

              <InlineSection>
                <Typography size="xs">Pause on click</Typography>
                <Toggle value={pauseOnClick} onChange={event => setPauseOnClick(event)} />
              </InlineSection>

              <InlineSection>
                <Typography size="xs">Open in a new tab</Typography>
                <Toggle value={openInNewTab} onChange={event => setOpenInNewTab(event)} />
              </InlineSection>

              <InlineSection>
                <Typography size="xs">Autoplay</Typography>
                <Toggle value={autoplayEnabled} onChange={event => setAutoplayEnabled(event)} />
              </InlineSection>

              <InlineSection>
                <Typography size="xs">Loop</Typography>
                <Toggle value={loopEnabled} onChange={event => setLoopEnabled(event)} />
              </InlineSection>

              <InlineSection>
                <Typography size="xs">Player controls</Typography>
                <Toggle
                  value={playerControlsEnabled}
                  onChange={event => setPlayerControlsEnabled(event)}
                />
              </InlineSection>
            </ContentSectionWrapper>
          </SettingsSection>
        </Wrapper>
        <NextButtonWrapper>
          <Button onClick={persistStateAndGoToNextStep} isDisabled={!checkOutLink}>
            Next
          </Button>
        </NextButtonWrapper>
      </Container>
    </Root>
  );
};

export default Actions;
