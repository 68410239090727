import React from 'react';
import { useOnboarding } from '../../hooks/useOnboarding';
import { Header } from '../../components/Header/Header';
import StartPage from './Steps/StartPage';
import Type from './Steps/Type';
import Track from './Steps/Track';
import Edit from './Steps/Edit';
import Actions from './Steps/Actions';
import Preview from './Steps/Preview';
import Scraper from './Steps/Scraper';

import {
  EditorEmptyStateBackground,
  Root,
  CloseButton,
  MobileLayoutRoot
} from './OnboardingWizard.styled';

const OnboardingWizard = ({ closeOnboarding, defaultIsFetchingVtts, scraperFetcher }) => {
  const { config, currentStep, isMobileDevice } = useOnboarding();

  const renderStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <Type />;
      case 2:
        return <Track defaultIsFetchingVtts={defaultIsFetchingVtts} />;
      case 3:
        return <Edit />;
      case 4:
        return <Actions />;
      case 5:
        return <Preview closeOnboarding={closeOnboarding} />;

      case 'scraper':
        return <Scraper scraperFetcher={scraperFetcher} />;

      default:
        return <StartPage />;
    }
  };

  return (
    <>
      {!isMobileDevice && (
        <EditorEmptyStateBackground>
          <Root>
            <Header
              rightSlot={<CloseButton onClick={() => closeOnboarding({ config })}>✕</CloseButton>}
            />
            {renderStepComponent()}
          </Root>
        </EditorEmptyStateBackground>
      )}

      {isMobileDevice && (
        <MobileLayoutRoot>
          <Header
            isSticky
            rightSlot={<CloseButton onClick={() => closeOnboarding({ config })}>✕</CloseButton>}
          />
          {renderStepComponent()}
        </MobileLayoutRoot>
      )}
    </>
  );
};

export default OnboardingWizard;
