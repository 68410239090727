import React from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { KeyboardArrowDown, KeyboardArrowUp } from '@clds/icon';
import Typography from '@clds/typography';

export const StyledCollapsibleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.md};
  padding: 0 ${globalTheme.spacing.md};
  cursor: pointer;

  svg path {
    fill: ${globalTheme.palette.contrastHigh};
  }
`;

export const CollapsibleContent = styled.div`
  padding: ${globalTheme.spacing.md};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: ${globalTheme.spacing.md};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    font-size: 1rem;
  }
`;

export const CollapsibleHeader = ({ icon, text, isOpen }) => (
  <StyledCollapsibleHeader>
    <LabelWrapper>
      {icon}
      <Typography>{text}</Typography>
    </LabelWrapper>
    <ArrowWrapper>
      {isOpen ? <KeyboardArrowUp size="sm" /> : <KeyboardArrowDown size="sm" />}
    </ArrowWrapper>
  </StyledCollapsibleHeader>
);

export const CollapsibleSection = ({ trigger, children, isOpen, setIsOpen }) => {
  return (
    <Collapsible
      open={isOpen}
      trigger={trigger}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      <CollapsibleContent>{children}</CollapsibleContent>
    </Collapsible>
  );
};
