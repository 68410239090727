import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { ArrowBack, ArrowDropDown, Check, OpenInNew } from '@clds/icon';
import { Typography } from '@clds/typography';
import { ReactComponent as TrackingIcon } from '../../icons/tracking_icon.svg';
import { Panel, StyledTextField } from '../shared.style';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

export const StyledArrowBackIcon = styled(ArrowBack)`
  margin-right: 0.5rem;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  background-color: ${globalTheme.palette.surface};
  overflow: hidden;
`;

export const PlayerWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

export const TimelineWrapper = styled.div`
  width: 100%;
  background-color: ${globalTheme.palette.surfaceAlt};
  overflow: auto;
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ActionSectionWrapper = styled(Section)`
  margin: 0.5rem 0 1rem;
`;

export const InlineSection = styled(Section)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const TriggerObjectSection = styled(Section)`
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 0.5rem;
`;

export const Triggers = styled.div`
  display: flex;
  margin: 0.5rem 0 0;
  justify-content: center;
  gap: 0.5rem;
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  cursor: not-allowed; // until functionality is done
  pointer-events: none;
`;

export const StyledOpenInNewIcon = styled(OpenInNew)`
  position: absolute;
  left: 10px;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(StyledTextField)`
  margin: 0.5rem 0;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
`;

export const StyledInputWithIcon = styled(StyledInput)`
  padding-left: 2rem;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0.5rem 0;
  width: 100%;
  outline: none;
  padding: 0.5rem;
  background-position: calc(100% - 1rem) center;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
  cursor: pointer;
`;

export const StyledDropDownArrow = styled(ArrowDropDown)`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const Separator = styled.div`
  border: 0.5px solid ${globalTheme.palette.secondaryAlt};
  width: 100%;
  margin: 1rem 0;
`;

export const HotspotActionTypeImageWrapper = styled.div`
  display: flex;
  height: 8vh;
  margin: 0.5rem 0;
  align-items: center;
  justify-content: center;
`;

export const HotspotActionTypeImage = styled.img`
  margin: 0.5rem 0 1rem;
  object-fit: contain;
  flex: 1;
  height: 8vh;
`;

export const CleanButton = styled.button`
  outline: none;
  background: transparent;
  color: #fffffe;
  border: none;
  margin-left: 0.5rem;
  cursor: pointer;
`;

export const Dropzone = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 8vh;
  margin: 0.5rem 0;
  border: 1px dashed ${globalTheme.palette.secondary};
`;

export const UploadSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UploadText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  gap: 0.5rem;
`;

export const PositionTabs = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem 0 1rem;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 4.5rem;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  gap: 0.5rem;
  cursor: pointer;
  color: ${props => (props.$isSelected ? globalTheme.palette.primary : 'rgb(210, 214, 223)')};
  background: ${props =>
    props.$isSelected
      ? 'radial-gradient(108.55% 108.55% at 50% 0%, rgba(70, 152, 247, 0.26) 0%, rgba(255, 255, 255, 0.05) 100%)'
      : globalTheme.palette.surfaceAlt};

  &:first-of-type {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &:last-of-type {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  svg path {
    fill: ${props => (props.$isSelected ? globalTheme.palette.primary : 'rgb(210, 214, 223)')};
  }
`;

export const PositionTab = styled(Tab)`
  flex-direction: row;
  height: 40px;
  border-bottom: 1px solid
    ${props => (props.$isSelected ? globalTheme.palette.primary : 'transparent')};
  transition: all 0.3s ease;

  &:first-of-type {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  &:last-of-type {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0.5rem;
  }
`;

export const StyledTypography = styled(Typography)`
  margin-top: 0.5rem;
`;

export const StyledCheckButton = styled(Check)`
  cursor: pointer;
`;

export const Spinner = styled.div`
  border: 0.2rem solid ${globalTheme.palette.primary};
  border-radius: 50%;
  border-top: 0.2rem solid ${globalTheme.palette.surface};
  width: 2.5rem;
  height: 2.5rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledTrackingIcon = styled(TrackingIcon)`
  cursor: pointer;
`;

export const SmallSpinner = styled.div`
  border: 1px solid ${globalTheme.palette.primary};
  border-radius: 50%;
  border-top: 1px solid #1f242e;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 25%;
  left: calc(50% - 30px);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: ${({ $isDisabled }) => ($isDisabled ? 'blur(2px)' : 'blur(0)')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
`;

export const StyledPanel = styled(Panel)`
  padding: ${globalTheme.spacing.md} 0;
`;

export const StyledCollapsibleWrapper = styled.div`
  width: 100%;
  padding: ${globalTheme.spacing.md} 0;
`;

export const StyledNoneCollapsibleWrapper = styled.div`
  width: 100%;
`;
