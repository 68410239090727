import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStore } from '../utils/store';
import { INTERACTIVE_VIDEO_API } from '../const';

const parseVtt = async vttUrl => {
  try {
    const response = await fetch(vttUrl);
    const data = await response.text();

    return data.split('\n\n').reduce((acc, item) => {
      item.split('\n').forEach(line => {
        const value = {};

        if (line.includes('-->')) {
          const [startTime, endTime] = line.split('--> ');
          value.startTime = timeToSeconds(startTime);
          value.endTime = timeToSeconds(endTime);
        }

        if (Object.keys(value).length) {
          acc.push(value);
        }
      });
      return acc;
    }, []);
  } catch (error) {
    console.error('Error fetching or parsing VTT file:', error);
    return [];
  }
};

const timeToSeconds = time => {
  if (time) {
    const [hours, minutes, seconds] = time.split(':').map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
  }
};

const fetchProcessVttUrls = async configs => {
  if (!configs) {
    return {};
  }

  const values = {};

  for (const hotspot of configs) {
    const result = await parseVtt(hotspot.vtt);

    const mergedTimeframes = result
      .sort((a, b) => a.startTime - b.startTime)
      .reduce((acc, curr) => {
        if (acc.length === 0 || curr.startTime > acc[acc.length - 1].endTime) {
          acc.push({ ...curr, ...hotspot });
        } else {
          acc[acc.length - 1].endTime = Math.max(acc[acc.length - 1].endTime, curr.endTime);
        }
        return acc;
      }, []);

    const parent =
      hotspot.parentConfigId && configs.find(i => i.hotspotId === hotspot.parentConfigId);
    const publicId = parent ? parent.publicId : hotspot.publicId;
    const item = parent
      ? mergedTimeframes.map(i => ({
          ...i,
          parent
        }))
      : mergedTimeframes;

    if (publicId) {
      values[publicId] = values[publicId] || [];
      values[publicId].push(item);
    }
  }

  return values;
};

const vttContext = createContext(undefined);

export const useVtt = () => {
  const value = useContext(vttContext);

  if (!value) {
    throw new Error('You need to wrap your component with VttProvider');
  }

  return value;
};

export const VttProvider = ({ children }) => {
  const { store } = useStore();
  const [vttResults, setVttResults] = useState({});
  const [configs, setConfigs] = useState(null);

  const setCurrentConfigs = currentConfigs => setConfigs(currentConfigs);

  const generateVtt = useCallback(
    async ({
      hotspotId,
      publicId,
      requestObject,
      objectTrackingResultsUrl,
      onVttChange,
      matchingObject
    }) => {
      try {
        const response = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ hotspotId, publicId, requestObject, objectTrackingResultsUrl })
        });

        if (response.ok) {
          console.log('Generate VTT response ok:', response);
          const data = await response.json();
          console.log('Generate VTT data:', data);

          onVttChange(data.vttUrl, matchingObject);
        } else {
          console.error('Generate VTT error:', response);
        }
      } catch (error) {
        console.error('Generate VTT error:', error);
      }
    },
    []
  );

  const generateVttForObjectTracking = useCallback(
    async ({
      publicId,
      requestObject,
      objectTrackingResultsUrl,
      onTrackingObjectChange,
      matchingObject,
      parentConfigId,
      objectTrackingHotspotId
    }) => {
      try {
        const response = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            hotspotId: objectTrackingHotspotId,
            publicId,
            requestObject,
            objectTrackingResultsUrl
          })
        });

        if (response.ok) {
          console.log('Generate VTT response for object tracking ok:', response);
          const data = await response.json();
          console.log('generate VTT data for object tracking:', data);

          onTrackingObjectChange({
            matchingObject,
            objectTrackingHotspotId,
            parentConfigId,
            vttUrl: data.vttUrl
          });

          store.interactionAreaPlugin.setAreasPositionListener([data.vttUrl]);
        } else {
          console.error('generate VTT error for object tracking:', response);
        }
      } catch (error) {
        console.error('generate VTT error for object tracking:', error);
      }
    },
    [store]
  );

  useEffect(() => {
    fetchProcessVttUrls(configs).then(setVttResults);
  }, [configs]);

  const value = useMemo(
    () => ({
      generateVtt,
      generateVttForObjectTracking,
      vttResults,
      setCurrentConfigs
    }),
    [generateVtt, generateVttForObjectTracking, vttResults]
  );

  return <vttContext.Provider value={value}>{children}</vttContext.Provider>;
};

export default useVtt;
