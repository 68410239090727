import React from 'react';
import Typography from '@clds/typography';
import styled from 'styled-components';
import { ANIMATIONS_ENUMS } from '../../../../const';
import { ArrowDropDown } from '@clds/icon';
import { globalTheme } from '@clds/component-theme';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledDropDownArrow = styled(ArrowDropDown)`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0.5rem 0;
  width: 100%;
  outline: none;
  padding: 0.5rem;
  background-position: calc(100% - 1rem) center;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
  cursor: pointer;
`;

export const AnimationSection = ({ hotspotAnimation, setHotspotAnimation }) => {
  return (
    <Section>
      <Typography>Animation</Typography>
      <SelectWrapper>
        <StyledDropDownArrow size="xs" />
        <StyledSelect value={hotspotAnimation} onChange={e => setHotspotAnimation(e.target.value)}>
          {Object.entries(ANIMATIONS_ENUMS).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </SelectWrapper>
    </Section>
  );
};
