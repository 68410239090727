import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { INTERACTIVE_VIDEO_API } from '../../../../const';
import { getImageUrlByPublicId } from '../../../../utils/getUrlByPublicId';

const useImageUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const uploadImage = useCallback(async file => {
    const formData = new FormData();
    formData.append('image', file);

    setIsUploading(true);

    try {
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Image upload successful', data);

        const imageUrl = getImageUrlByPublicId(data.publicId);

        setIsUploading(false);
        setImageUrl(imageUrl);
      } else {
        console.error('Image upload failed', response);
      }
    } catch (error) {
      console.error('Error during an image upload', error);
    }
  }, []);

  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      uploadImage(file);
    },
    [uploadImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    }
  });

  return {
    imageUrl,
    getRootProps,
    getInputProps,
    isDragActive,
    isUploading,
    uploadImage
  };
};

export default useImageUpload;
