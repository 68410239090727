import styled, { keyframes } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Typography from '@clds/typography';

export const WizardStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  width: 15rem;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const StepLabel = styled(Typography)`
  margin-left: 0.5rem;
  font-family: 'Zen Kaku Gothic Antique';
  color: ${({ completed, current }) =>
    current ? globalTheme.palette.primary : completed ? globalTheme.palette.success : 'unset'};
`;

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({ completed, current }) =>
    current ? globalTheme.palette.primary : completed ? globalTheme.palette.success : '#1e232a'};
  color: ${({ completed, current }) =>
    current
      ? globalTheme.palette.background
      : completed
      ? globalTheme.palette.background
      : globalTheme.palette.primary};
`;

export const StepConnector = styled.div`
  height: 1.5rem;
  border-left: 2px solid
    ${({ completed }) => (completed ? globalTheme.palette.success : globalTheme.palette.secondary)};
  margin-left: 0.7rem;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 1px solid ${globalTheme.palette.background};
  border-left-color: darkgrey;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 1s linear infinite;
`;
