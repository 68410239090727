import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { videoPlayer } from 'cloudinary-video-player';
import 'cloudinary-video-player/cld-video-player.min.css';
import { FullscreenEnter, VolumeUp } from '@clds/icon';
import { useStore } from '../../utils/store';
import useEditHotspot from '../EditHotspot/useEditHotspot';
import { getConfigFromUrl } from '../../utils/getConfigFromUrl';
import { getGroupedHotspots } from '../../utils/utils';
import { getWidgetItem } from './widgetItem';
import { themes } from '../EditHotspot/hotspotWidgetThemes';

import {
  Video,
  Controls,
  TimeSection,
  VolumeSection,
  VolumeBar,
  StyledPlayButton,
  StyledPauseButton,
  ControlsSideActions,
  StyledTimelineIcon
} from './Player.styled';
import useResponsiveMediaQuery from '../../hooks/useResponsiveMediaQuery';

const formatTime = seconds => {
  const date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().slice(11, 19);
};

export default function CLDVideoPlayer({
  cloud,
  onReady,
  onOnboardingSetInteractionAreaPlugin,
  isOnboarding = false,
  isLastStepOnboarding = false,
  onboardingConfig
}) {
  const isSmallDevice = useResponsiveMediaQuery('(max-width: 700px)');

  const { configs, globalConfig } = getConfigFromUrl();
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const location = useLocation();
  const { store, updateStore } = useStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const groupedHotspots = useMemo(() => getGroupedHotspots(configs), [configs]);
  const publicIds = Object.keys(groupedHotspots);
  const [currentVideoPublicId, setCurrentVideoPublicId] = useState(
    globalConfig?.currentlyPlayingPublicId || publicIds[0] || ''
  );
  const [currentPublicIdIndex, setCurrentPublicIdIndex] = useState(
    globalConfig?.currentlyPlayingPublicId
      ? publicIds.indexOf(globalConfig?.currentlyPlayingPublicId)
      : 0
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0);
  const [isVolumeBarOpened, setIsVolumeBarOpened] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const pathname = document.location.pathname;
  const isEditMode = pathname.includes('edit');
  const isEmptyState = !(pathname.includes('hotspotId') || configs?.length);

  const hotspotWidgetTheme = useMemo(
    () => configs.find(item => item.hotspotId === globalConfig.selectedHotspotId)?.hotspotTheme,
    [configs, globalConfig.selectedHotspotId]
  );

  const { playerControlsEnabled: playerControlsEnabledFromGlobalConfig } = useEditHotspot();
  const [playerControlsEnabled, setPlayerControlsEnabled] = useState(
    isLastStepOnboarding && onboardingConfig
      ? onboardingConfig.playerControlsEnabled
      : playerControlsEnabledFromGlobalConfig === 'true' ||
          playerControlsEnabledFromGlobalConfig === true
  );

  useEffect(() => {
    if (playerControlsEnabledFromGlobalConfig !== playerControlsEnabled && !isLastStepOnboarding) {
      setPlayerControlsEnabled(
        playerControlsEnabledFromGlobalConfig === 'true' ||
          playerControlsEnabledFromGlobalConfig === true
      );
    }
  }, [isLastStepOnboarding, playerControlsEnabled, playerControlsEnabledFromGlobalConfig]);

  const playerConfig = useMemo(
    () => ({
      autoplay: globalConfig.autoplayEnabled === 'true',
      loop: globalConfig.loopEnabled === 'true',
      controls: false, // in the editor it's always false since there are custom controls, this flag is passed to the external video player in openPlayerInNewTab function
      fluid: true,
      isLastStepOnboarding,
      bigPlayButton: false,
      muted: true,
      hotspotWidgetTheme,
      interactionDisplay: {
        layout: {
          enable: false
        }
      },
      html5: {
        nativeTextTracks: false
      }
    }),
    [
      globalConfig.autoplayEnabled,
      globalConfig.loopEnabled,
      hotspotWidgetTheme,
      isLastStepOnboarding
    ]
  );

  const setCurrentPublicIdIndexHandler = useCallback(
    index => {
      if (index > -1) {
        setCurrentPublicIdIndex(index);
        setCurrentVideoPublicId(publicIds[index]);
        setSearchParams(prev => {
          prev.set('currentlyPlayingPublicId', publicIds[index]);
          return prev;
        });
      }
    },
    [publicIds, setSearchParams]
  );

  const setCurrentVideoPublicIdHandler = useCallback(
    id => {
      const index = publicIds.indexOf(id);
      if (index > -1) {
        setCurrentPublicIdIndexHandler(index);
      }
    },
    [publicIds, setCurrentPublicIdIndexHandler]
  );

  const handlePlay = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      player.play();
    }
  }, [playerRef]);

  const handlePause = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      player.pause();
    }
  }, [playerRef]);

  useEffect(() => {
    const index = publicIds.indexOf(currentVideoPublicId);
    if (index > -1 && index !== currentPublicIdIndex) {
      setCurrentPublicIdIndexHandler(index);
    }
  }, [currentVideoPublicId, currentPublicIdIndex, publicIds, setCurrentPublicIdIndexHandler]);

  useEffect(() => {
    if (
      globalConfig?.currentlyPlayingPublicId &&
      globalConfig?.currentlyPlayingPublicId !== currentVideoPublicId
    ) {
      setCurrentVideoPublicIdHandler(globalConfig?.currentlyPlayingPublicId);
    }
  }, [globalConfig, currentVideoPublicId, setCurrentVideoPublicIdHandler]);

  const sourceConfig = useMemo(
    () => ({
      interactionAreas: {
        syncOffsetTime: true,
        on: true,
        getConfigs: getConfigFromUrl,
        onClick: event => {
          const { configs } = getConfigFromUrl();
          const clickedHotspotId = event.item.id;

          const clickedHotspot = configs.find(
            videoConfig => videoConfig.hotspotId === clickedHotspotId
          );

          const pauseOnClick = isOnboarding
            ? onboardingConfig?.pauseOnClick
            : clickedHotspot?.pauseOnClick === 'true';

          if (pauseOnClick) {
            handlePause();
          }

          const itemHotspotMarkerElement = document.querySelector(
            `.vp-ia-item[data-id='${clickedHotspotId}']`
          ); // the dot

          const theme = themes.find(({ name }) => name === clickedHotspot?.hotspotTheme);
          const { create } = getWidgetItem(
            clickedHotspot,
            theme,
            isOnboarding,
            onboardingConfig,
            itemHotspotMarkerElement
          );
          if (clickedHotspot?.hotspotTitle && clickedHotspot?.hotspotCtaText) {
            create();

            if (itemHotspotMarkerElement) {
              itemHotspotMarkerElement.style.visibility = 'hidden';
            }
          }
        }
      }
    }),
    [handlePause, isOnboarding, onboardingConfig]
  );

  // Initialize the player.
  useEffect(() => {
    if (!playerRef.current) {
      // The player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video');
      videoRef.current.appendChild(videoElement);

      const publicId = isEditMode
        ? configs.find(item => item.publicId === globalConfig?.currentlyPlayingPublicId)?.publicId
        : publicIds[currentPublicIdIndex];

      const player = (playerRef.current = videoPlayer(videoElement, {
        cloudName: cloud,
        publicId,
        ...playerConfig
      }));

      player.on('ready', () => {
        if (onReady) {
          onReady(player);
          if (!isEditMode && !isEmptyState) {
            setCurrentPublicIdIndexHandler(0);
          }
        }

        if (onOnboardingSetInteractionAreaPlugin) {
          onOnboardingSetInteractionAreaPlugin({
            player,
            interactionAreaPlugin: player.videojs.interactionAreaPlugin(player)
          });
        }

        player.volume(0);
      });

      player.on('ended', () => {
        if (!isOnboarding) {
          handlePause();

          if (!isEditMode) {
            setCurrentPublicIdIndexHandler(currentPublicIdIndex + 1);
            setCurrentPublicIdIndex(currentPublicIdIndex + 1);
          }

          if (isEditMode) {
            handlePause();
          }
        }
      });
    }
  }, [
    cloud,
    updateStore,
    store,
    groupedHotspots,
    currentPublicIdIndex,
    publicIds,
    isEditMode,
    configs,
    globalConfig?.currentlyPlayingPublicId,
    location.search,
    pathname,
    isEmptyState,
    handlePause,
    handlePlay,
    setSearchParams,
    setCurrentPublicIdIndexHandler,
    onReady,
    onOnboardingSetInteractionAreaPlugin,
    isOnboarding,
    playerConfig
  ]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      const newSourceConfig = {
        sourceConfig: { ...sourceConfig },
        interactionAreas: {
          ...sourceConfig.interactionAreas
        }
      };

      console.log(
        `### sourceConfig (update), publicId - ${publicIds[currentPublicIdIndex]}`,
        sourceConfig
      );
      console.log('currentPublicIdIndex', currentPublicIdIndex);

      player.source(publicIds[currentPublicIdIndex], newSourceConfig);

      player.videojs.trigger('videoChanged');

      player.on('ended', () => {
        if (!isOnboarding) {
          if (currentPublicIdIndex + 1 >= publicIds.length && !isEditMode) {
            handlePause();

            setCurrentPublicIdIndex(0);
            setCurrentPublicIdIndexHandler(0);

            player.videojs.trigger('videoChanged');

            setSearchParams(prevParams => {
              prevParams.set('currentlyPlayingPublicId', publicIds[0]);
              return prevParams;
            });
          }

          if (currentPublicIdIndex + 1 < publicIds.length && !isEditMode) {
            setCurrentPublicIdIndex(currentPublicIdIndex + 1);
            setCurrentPublicIdIndexHandler(currentPublicIdIndex + 1);
          }

          if (isEditMode) {
            handlePause();
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublicIdIndex]);

  // Dispose the player.
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && player.videojs && !player.videojs.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.on('durationchange', () => {
        setDuration(player.duration());
      });

      player.on('timeupdate', () => {
        setCurrentTime(player.currentTime());
      });

      player.on('play', () => setIsPlaying(true));

      player.on('pause', () => setIsPlaying(false));

      player.on('fullscreenchange', () => {
        player.controls(player.videojs.isFullscreen());
      });

      const togglePlay = event => {
        if (event.code === 'Space') {
          player.videojs.paused() ? handlePlay() : handlePause();
        }
      };

      document.addEventListener('keyup', togglePlay);

      return () => {
        player.off('durationchange');
        player.off('timeupdate');
        document.removeEventListener('keyup', togglePlay);
      };
    }
  }, [playerRef, handlePlay, handlePause]);

  const handleVolumeChange = useCallback(
    event => {
      const newVolume = parseFloat(event.target.value);
      setVolume(newVolume);
      const player = playerRef.current;
      if (player) {
        player.unmute();
        player.volume(newVolume);

        if (newVolume === 0) {
          player.mute();
        }
      }
    },
    [playerRef]
  );

  const onFullScreenClick = useCallback(() => {
    playerRef.current.videojs.requestFullscreen();
  }, [playerRef]);

  const toggleVolumeBar = useCallback(
    () => setIsVolumeBarOpened(!isVolumeBarOpened),
    [isVolumeBarOpened]
  );

  const onToggleTimelineVisibility = useCallback(
    () => updateStore({ isTimelineVisible: !store.isTimelineVisible }),
    [updateStore, store.isTimelineVisible]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        data-vjs-player
        style={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Video ref={videoRef}></Video>
        {playerControlsEnabled && (
          <Controls>
            <ControlsSideActions>
              {isOnboarding ? null : <StyledTimelineIcon onClick={onToggleTimelineVisibility} />}
            </ControlsSideActions>

            {isEmptyState && (
              <TimeSection>
                {isPlaying ? <StyledPauseButton size="md" /> : <StyledPlayButton size="md" />}
                -- &nbsp;&nbsp; / &nbsp;&nbsp; --
              </TimeSection>
            )}

            {!isEmptyState && (
              <TimeSection>
                {isPlaying ? (
                  <StyledPauseButton size="md" onClick={handlePause} />
                ) : (
                  <StyledPlayButton size="md" onClick={handlePlay} />
                )}
                {!isSmallDevice && formatTime(currentTime) + ' / ' + formatTime(duration)}
              </TimeSection>
            )}

            <ControlsSideActions>
              <VolumeSection>
                <VolumeUp onClick={isEmptyState ? null : toggleVolumeBar} />
                {isVolumeBarOpened && (
                  <VolumeBar
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                  />
                )}
              </VolumeSection>
              <FullscreenEnter size="sm" onClick={onFullScreenClick} />
            </ControlsSideActions>
          </Controls>
        )}
      </div>
    </div>
  );
}
