import React from 'react';
import Typography from '@clds/typography';
import Toggle from '@clds/toggle';
import Tooltip from '@clds/tooltip';
import useEditHotspot from './useEditHotspot';

import {
  ActionSectionWrapper,
  InlineSection,
  InputWrapper,
  StyledInputWithIcon,
  StyledOpenInNewIcon,
  StyledTypography
} from './EditHotspot.styled';

export const ActionSection = () => {
  const {
    selectedHotspotConfig,
    hotspotTargetUrl,
    pauseOnClick,
    openInNewTab,
    onOpenInNewTabChange,
    onPauseOnClickChange,
    onHotspotTargetUrlChange,
    autoplayEnabled,
    onAutoplayEnabledChange,
    loopEnabled,
    onLoopEnabledChange,
    playerControlsEnabled,
    onPlayerControlsEnabledChange
  } = useEditHotspot();

  const isChildHotspotConfig =
    Boolean(selectedHotspotConfig?.parentConfigId) || selectedHotspotConfig.type === 'tracking';

  return (
    <>
      <ActionSectionWrapper>
        <StyledTypography size="xs">Action (on click)</StyledTypography>
        <InputWrapper>
          <StyledOpenInNewIcon
            size="sm"
            onClick={() =>
              hotspotTargetUrl
                ? window.open(hotspotTargetUrl, '_blank', 'noopener, noreferrer')
                : null
            }
          />
          <StyledInputWithIcon
            type="text"
            placeholder="https://your-url.com"
            value={hotspotTargetUrl}
            onChange={event => onHotspotTargetUrlChange(event.target.value)}
          />
        </InputWrapper>
      </ActionSectionWrapper>

      <InlineSection>
        <Typography size="xs">Pause on click</Typography>
        <Toggle
          value={String(pauseOnClick) === 'true'} // whether from local state - boolean or url - string
          onChange={event => onPauseOnClickChange(event)}
        />
      </InlineSection>

      <InlineSection>
        <Typography size="xs">Open in a new tab</Typography>
        <Toggle
          value={String(openInNewTab) === 'true'} // whether from local state - boolean or url - string
          onChange={event => onOpenInNewTabChange(event)}
        />
      </InlineSection>

      <InlineSection>
        <Typography size="xs">Autoplay</Typography>

        {!isChildHotspotConfig && (
          <Toggle
            value={String(autoplayEnabled) === 'true'} // whether from local state - boolean or url - string
            onChange={event => onAutoplayEnabledChange(event)}
          />
        )}

        {isChildHotspotConfig && (
          <Tooltip
            placement={'top'}
            title={'You can change this flag in the settings of the first hotspot'}
          >
            <Toggle style={{ cursor: 'not-allowed' }} value={String(autoplayEnabled) === 'true'} />
          </Tooltip>
        )}
      </InlineSection>

      <InlineSection>
        <Typography size="xs">Loop</Typography>

        {!isChildHotspotConfig && (
          <Toggle
            value={String(loopEnabled) === 'true'}
            onChange={event => onLoopEnabledChange(event)}
          />
        )}

        {isChildHotspotConfig && (
          <Tooltip
            placement={'top'}
            title={'You can change this flag in the settings of the first hotspot'}
          >
            <Toggle style={{ cursor: 'not-allowed' }} value={String(loopEnabled) === 'true'} />
          </Tooltip>
        )}
      </InlineSection>

      <InlineSection>
        <Typography size="xs">Player controls</Typography>

        {!isChildHotspotConfig && (
          <Toggle
            value={String(playerControlsEnabled) === 'true'}
            onChange={event => onPlayerControlsEnabledChange(event)}
          />
        )}

        {isChildHotspotConfig && (
          <Tooltip
            placement={'top'}
            title={'You can change this flag in the settings of the first hotspot'}
          >
            <Toggle
              style={{ cursor: 'not-allowed' }}
              value={String(playerControlsEnabled) === 'true'}
            />
          </Tooltip>
        )}
      </InlineSection>
    </>
  );
};
