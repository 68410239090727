import React, { memo, useMemo } from 'react';
import Typography from '@clds/typography';
import maxBy from 'lodash/maxBy';
import UploadCloudIcon from '../../icons/UploadCloudIcon';
import FilmIcon from '../../icons/FilmIcon';
import { getImageUrlFromVideoByPublicId } from '../../utils/getUrlByPublicId';
import useTimeline from './useTimeline';
import { useStore } from '../../utils/store';
import { useVideoUpload } from '../../hooks/useVideoUpload';
import { Ruler } from './Ruler';

import {
  Root,
  TimelineWrapper,
  StyledPoster,
  VideoSectionWrapper,
  VideoSection,
  Indicator,
  TimeframeIndicator,
  VideoSectionRoot,
  SingleTimeframeIndicatorWrapper,
  DragAndDropSection,
  DragAndDropOverlay,
  EmptyStateTextWrapper,
  AnimatedTypography,
  IndicatorWrapper,
  IndicatorWrapperText,
  RulerWrapper
} from './Timeline.styled';

const Timeline = ({ isPlayerReady }) => {
  const { updateStore, store } = useStore();

  const {
    configs,
    currentTime,
    totalDuration,
    vttResults,
    calculateLeftPosition,
    calculateWidthInSection,
    onSelect,
    groupedHotspots,
    currentVideoPublicId,
    selectedHotspotId
  } = useTimeline({ isPlayerReady });

  const pathname = document.location.pathname;
  const isEmptyState = !(pathname.includes('hotspotId') || configs?.length);
  const maxIndicator = useMemo(
    () => maxBy(Object.values(vttResults), value => value.length)?.length,
    [vttResults]
  );

  const { getRootProps, getInputProps, isUploading } = useVideoUpload();

  const onTimelineClick = (event, { publicId, hotspotId, videoDuration }) => {
    onSelect({
      publicId,
      hotspotId
    });

    store.player.pause();

    if (pathname.includes('edit')) {
      setTimeout(() => {
        const present = (event.nativeEvent.offsetX * 100) / event.target.clientWidth;
        store.player.currentTime((present * videoDuration) / 100);
      }, 10);
    }
  };

  if (isEmptyState) {
    return (
      <TimelineWrapper>
        <DragAndDropSection>
          <DragAndDropOverlay {...getRootProps()}>
            <input {...getInputProps()} />

            {isUploading && (
              <AnimatedTypography size="md">Uploading a video... Please wait</AnimatedTypography>
            )}
            {!isUploading && (
              <EmptyStateTextWrapper>
                <UploadCloudIcon />
                <Typography size="md">Drag and drop video</Typography>
              </EmptyStateTextWrapper>
            )}
          </DragAndDropOverlay>
        </DragAndDropSection>
      </TimelineWrapper>
    );
  }

  const groupedHotspotsArray = Object.entries(groupedHotspots);

  return (
    <Root width={100 * groupedHotspotsArray.length}>
      <TimelineWrapper>
        {groupedHotspotsArray.map(([publicId], index) => {
          const values = vttResults[publicId] || [];
          const isCurrentVideo = publicId === currentVideoPublicId;
          const hotspot = groupedHotspots[publicId][0];
          const hotspots = groupedHotspots[publicId];
          const hotspotVideoDuration = Number(hotspot?.videoDuration);
          const widthPercentage = (hotspotVideoDuration / totalDuration) * 100;

          return (
            <VideoSectionRoot key={index} width={widthPercentage}>
              <RulerWrapper
                onClick={event =>
                  onTimelineClick(event, {
                    publicId,
                    hotspotId: hotspot?.hotspotId,
                    videoDuration: hotspotVideoDuration
                  })
                }
              >
                <Ruler totalDuration={hotspotVideoDuration} />
              </RulerWrapper>
              <IndicatorWrapper height={`${maxIndicator * 25}px`}>
                {values.map((value, valueIndex) => {
                  const firstValue = value[0];

                  return (
                    <SingleTimeframeIndicatorWrapper
                      data-selected={firstValue?.hotspotId === selectedHotspotId}
                      key={valueIndex}
                      onMouseEnter={() =>
                        updateStore({ hoveredItemInTimeline: firstValue.hotspotId })
                      }
                      onMouseLeave={() => updateStore({ hoveredItemInTimeline: null })}
                    >
                      {value.map((i, iIndex) => {
                        const left = calculateLeftPosition(i.startTime, hotspotVideoDuration);
                        const width = calculateWidthInSection(
                          i.startTime,
                          i.endTime,
                          hotspotVideoDuration
                        );
                        return (
                          !isNaN(width) && (
                            <TimeframeIndicator key={iIndex} left={left} width={width} />
                          )
                        );
                      })}
                      <IndicatorWrapperText>
                        {hotspots[valueIndex]?.objectDescription}
                      </IndicatorWrapperText>
                    </SingleTimeframeIndicatorWrapper>
                  );
                })}
              </IndicatorWrapper>

              <VideoSectionWrapper key={publicId}>
                <VideoSection>
                  {isCurrentVideo && currentTime > 0 && (
                    <Indicator left={(currentTime / hotspotVideoDuration) * 100} />
                  )}
                  <FilmIcon fillColor={'black'} />
                  <StyledPoster
                    src={getImageUrlFromVideoByPublicId(publicId)}
                    alt=""
                    loading="lazy"
                  />
                  <Typography size="xs" type="contrastInvert">
                    #{index + 1}
                  </Typography>
                </VideoSection>
              </VideoSectionWrapper>
            </VideoSectionRoot>
          );
        })}
      </TimelineWrapper>
    </Root>
  );
};

export default memo(Timeline);
