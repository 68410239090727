import React from 'react';
import { themes } from '../EditHotspot/hotspotWidgetThemes';
import { Root, Title, StyledImage, StyledLinkButton } from './Hotspots.styled';

const Link = ({ title, image, checkOutText, checkOutLink = '', theme, animation }) => {
  const currentTheme = themes.find(t => t.name === theme);

  return (
    <Root
      background={currentTheme['background-color']}
      fontFamily={currentTheme['font-family']}
      backdropFilter={currentTheme['backdrop-filter']}
      animation={animation}
    >
      <Title color={currentTheme['text-color']}>{title}</Title>
      <StyledImage src={image} alt="" />
      {checkOutText && (
        <StyledLinkButton
          shape={currentTheme['button-shape']}
          href={checkOutLink}
          target="_blank"
          rel="noopener noreferrer"
          backgroundColor={currentTheme['button-color']}
          buttonTextColor={currentTheme['button-text-color']}
        >
          {checkOutText}
        </StyledLinkButton>
      )}
    </Root>
  );
};

export default Link;
