import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.$isExpanded ? 'auto' : 'min-content')};
  background-color: ${globalTheme.palette.background};
  align-items: center;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  @media (min-width: 700px) {
    width: 380px;
  }
`;

export const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  font-size: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  border-bottom: 1px solid ${globalTheme.palette.secondaryAlt};
`;

export const PreviewSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const PanelHeaderWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const StyledTextField = styled.input`
  height: 2.5rem;
  width: 100%;
  outline: none;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: ${globalTheme.palette.contrastHigh};
`;
