import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import ShadowIcon from '../../icons/ShadowIcon';
import { Button } from '@clds/button';
import { Del } from '@clds/icon';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  background-color: ${globalTheme.palette.surface};
`;

export const StyledAddButton = styled(Button)`
  background: #fffffe;
`;

export const PlayerWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

export const TimelineWrapper = styled.div`
  width: 100%;
  background-color: ${globalTheme.palette.surfaceAlt};
  overflow: auto;
`;

export const VideoPreviewRow = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: grab;
  opacity: ${props => (props.$isUploading ? 0.6 : 1)};
  pointer-events: ${props => (props.$isUploading ? 'none' : 'all')};
`;

export const StyledImage = styled.img`
  height: 2.5rem;
`;

export const StyledDeleteIcon = styled(Del)`
  display: none;
`;

export const Hotspot = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1rem 1rem 2rem;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  color: ${props => (props.$isHighlighted ? globalTheme.palette.primary : 'unset')};
  border: ${props =>
    props.$isHoveredInTimeline
      ? `1px solid ${props.theme.palette.primary}`
      : '1px solid transparent'};
  border-radius: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: ${globalTheme.palette.primary};
    background: rgba(18, 52, 86, 0.3);
    transition: all 0.3s ease;
  }
`;

export const HotspotLeftSlot = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const StyledShadowIcon = styled(ShadowIcon)`
  // check icon hover
`;

export const AddNewInteractionModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const ExpandButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: end;
`;
