import { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../utils/store';
import { getConfigFromUrl } from '../../utils/getConfigFromUrl';
import { getGroupedHotspots } from '../../utils/utils';
import useVtt from '../../hooks/useVtt';

const useTimeline = ({ isPlayerReady }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { configs, globalConfig } = getConfigFromUrl();
  const { store, updateStore } = useStore();
  const [currentTime, setCurrentTime] = useState(0);
  const { vttResults, setCurrentConfigs } = useVtt();

  const groupedHotspots = useMemo(() => getGroupedHotspots(configs), [configs]);

  const totalDuration = useMemo(() => {
    return Object.values(groupedHotspots).reduce((acc, hotspotGroup) => {
      if (hotspotGroup.length > 0) {
        return acc + Number(hotspotGroup[0].videoDuration);
      }
      return acc;
    }, 0);
  }, [groupedHotspots]);

  const calculateVideoStartTimes = useCallback(groupedHotspots => {
    let accumulatedDuration = 0;
    const startTimes = {};

    Object.entries(groupedHotspots).forEach(([publicId, hotspotGroup]) => {
      startTimes[publicId] = accumulatedDuration;
      if (hotspotGroup.length > 0) {
        accumulatedDuration += Number(hotspotGroup[0].videoDuration);
      }
    });

    return startTimes;
  }, []);

  const videoStartTimes = useMemo(
    () => calculateVideoStartTimes(groupedHotspots),
    [calculateVideoStartTimes, groupedHotspots]
  );

  const currentVideoPublicId = globalConfig?.currentlyPlayingPublicId;
  const selectedHotspotId = globalConfig?.selectedHotspotId;
  const selectedHotspotConfig = useMemo(
    () => configs?.find(hotspot => hotspot.hotspotId === selectedHotspotId),
    [configs, selectedHotspotId]
  );

  const [objectDescription] = useState(selectedHotspotConfig?.objectDescription || '');

  const currentVideoStartTime = videoStartTimes[currentVideoPublicId] || 0;

  useEffect(() => {
    if (selectedHotspotConfig?.objectDescription !== objectDescription) {
      setCurrentConfigs(configs);
    }
    setCurrentConfigs(configs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectDescription, selectedHotspotConfig?.objectDescription]);

  useEffect(() => {
    return () => {
      updateStore({ hoveredItemInTimeline: null });
    };
  }, [updateStore]);

  useEffect(() => {
    if (isPlayerReady) {
      const interval = setInterval(() => {
        setCurrentTime(store.player.videoElement.currentTime);
      }, 50);

      return () => clearInterval(interval);
    }
  }, [isPlayerReady, store.player]);

  const calculateLeftPosition = useCallback(
    (startTime, videoDuration) => (startTime * 100) / videoDuration,
    []
  );

  const calculateWidthInSection = useCallback((startTime, endTime, videoDuration) => {
    const timeframeDuration = endTime - startTime;
    return (timeframeDuration / videoDuration) * 100;
  }, []);

  const onSelect = ({ publicId, hotspotId }) => {
    searchParams.set('selectedHotspotId', hotspotId);
    searchParams.set('currentlyPlayingPublicId', publicId);
    navigate(`/edit?${searchParams.toString()}`);
  };

  return {
    configs,
    currentTime,
    totalDuration,
    currentVideoStartTime,
    vttResults,
    calculateLeftPosition,
    calculateWidthInSection,
    onSelect,
    groupedHotspots,
    currentVideoPublicId,
    selectedHotspotId
  };
};

export default useTimeline;
