import React from 'react';
import { Add } from '@clds/icon';
import Typography from '@clds/typography';
import useImageUpload from '../../hooks/useImageUpload';
import useEditHotspot from './useEditHotspot';

import {
  CleanButton,
  ContentSectionWrapper,
  Dropzone,
  HotspotActionTypeImage,
  HotspotActionTypeImageWrapper,
  InlineSection,
  Spinner,
  StyledInput,
  StyledTypography,
  UploadSection,
  UploadText
} from './EditHotspot.styled';

export const ContentSection = () => {
  const {
    hotspotTitle,
    onHotspotTitleChange,
    onHotspotActionTypeImageUrlChange,
    hotspotActionTypeImageUrl,
    hotspotCtaText,
    onHotspotCtaTextChange
  } = useEditHotspot();

  const { isUploading, getRootProps, getInputProps, isDragActive } = useImageUpload({
    onHotspotActionTypeImageUrlChange
  });

  return (
    <ContentSectionWrapper>
      <StyledTypography size="xs">Content</StyledTypography>
      <InlineSection>
        <StyledInput
          placeholder="Enter a title text"
          value={hotspotTitle}
          onChange={event => onHotspotTitleChange(event.target.value)}
        />
        <CleanButton onClick={() => onHotspotTitleChange('')}>✕</CleanButton>
      </InlineSection>

      {isUploading && (
        <HotspotActionTypeImageWrapper>
          <Spinner />
        </HotspotActionTypeImageWrapper>
      )}

      {hotspotActionTypeImageUrl && hotspotActionTypeImageUrl !== 'null' && !isUploading && (
        <HotspotActionTypeImageWrapper>
          <HotspotActionTypeImage src={hotspotActionTypeImageUrl} alt="" />
          <CleanButton onClick={() => onHotspotActionTypeImageUrlChange('')}>✕</CleanButton>
        </HotspotActionTypeImageWrapper>
      )}

      {(!hotspotActionTypeImageUrl || hotspotActionTypeImageUrl === 'null') && !isUploading && (
        <UploadSection>
          <Dropzone {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <UploadText>
                <Add size="xs" />
                <Typography size="xs">Add an image</Typography>
              </UploadText>
            )}
          </Dropzone>
          <CleanButton>✕</CleanButton>
        </UploadSection>
      )}

      <InlineSection>
        <StyledInput
          placeholder="Enter a CTA text"
          value={hotspotCtaText}
          onChange={event => onHotspotCtaTextChange(event.target.value)}
        />
        <CleanButton onClick={() => onHotspotCtaTextChange('')}>✕</CleanButton>
      </InlineSection>
    </ContentSectionWrapper>
  );
};
