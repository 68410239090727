import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { videoPlayer } from 'cloudinary-video-player';
import { useOnboarding } from '../../hooks/useOnboarding';
import { CLOUD_NAME } from '../../const';

import { OnboardingVideo, OnboardingVideoWrapper } from './Player.styled';

export default function OnboardingTrackingPreviewPlayer({
  videoPublicId,
  onSetInteractionAreaPlugin,
  initialTimestamp
}) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { config } = useOnboarding();

  const publicId =
    videoPublicId ||
    config?.publicId ||
    new URLSearchParams(window.location.search).get('publicId');

  const handlePlay = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      player.play();
    }
  }, [playerRef]);

  const handlePause = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      player.pause();
    }
  }, [playerRef]);

  const sourceConfig = useMemo(
    () => ({
      interactionAreas: {
        syncOffsetTime: true,
        on: true,
        getConfigs: () => [config]
      }
    }),
    [config]
  );

  // Initialize the player.
  useEffect(() => {
    if (!playerRef.current) {
      // The player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videoPlayer(videoElement, {
        cloudName: CLOUD_NAME,
        sourceTypes: ['auto', 'webm/vp9', 'mp4/h265', 'mp4;codecs=avc1'],
        loop: true,
        autoplay: true,
        muted: true,
        fluid: true,
        isOnboarding: true,
        interactionDisplay: {
          layout: {
            enable: false
          }
        },
        html5: {
          nativeTextTracks: false
        }
      }));

      player.on('ready', () => {
        onSetInteractionAreaPlugin({
          player,
          interactionAreaPlugin: player.videojs.interactionAreaPlugin(player)
        });
      });

      console.log('### sourceConfig (create): ', sourceConfig);
      player.source(publicId, sourceConfig);
    } else {
      const player = playerRef.current;

      console.log('### sourceConfig (update): ', sourceConfig);
      player.source(publicId, sourceConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceConfig, handlePlay, handlePause]);

  useEffect(() => {
    const player = playerRef.current;
    const videoElement = document.querySelector('video');

    if (player) {
      if (initialTimestamp) {
        player.currentTime(parseFloat(initialTimestamp));
      }

      const togglePlayWithSpaceKey = event => {
        if (event.code === 'Space') {
          player.videojs.paused() ? handlePlay() : handlePause();
        }
      };

      const togglePlayWithClick = () => {
        if (player.videojs.paused()) {
          handlePlay();
        } else {
          handlePause();
        }
      };

      document.addEventListener('keyup', togglePlayWithSpaceKey);
      videoElement.addEventListener('click', togglePlayWithClick);

      return () => {
        player.off('durationchange');
        player.off('timeupdate');
        document.removeEventListener('keyup', togglePlayWithSpaceKey);
        videoElement.removeEventListener('click', togglePlayWithClick);
      };
    }
  }, [handlePause, handlePlay, playerRef, initialTimestamp]);

  // Dispose the player.
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && player.videojs && !player.videojs.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <OnboardingVideoWrapper data-vjs-player>
      <OnboardingVideo ref={videoRef}></OnboardingVideo>
    </OnboardingVideoWrapper>
  );
}
