import React, { useCallback, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { generateTheme } from '@clds/theme-helper';
import { dark } from '@clds/themes';
import { InteractionAreaPlugin } from '../../plugins/interaction-area/interaction-area.service';
import { useStore } from '../../utils/store';
import Player from '../Player';
import OnboardingWizard from '../OnboardingWizard';
import { Preview } from '../Preview/Preview.js';
import Timeline from '../Timeline';
import { EditHotspot } from '../EditHotspot/EditHotspot.js';
import { CLOUD_NAME } from '../../const.js';
import { VideoUploadProvider } from '../../hooks/useVideoUpload';
import { VttProvider } from '../../hooks/useVtt';
import { OnboardingProvider } from '../../hooks/useOnboarding';
import { EditHotspotProvider } from '../EditHotspot/useEditHotspot';
import { createUrlFromArrayObjects } from '../../utils/createUrlFromArrayObjects.js';
import './App.scss';

export const darkTheme = generateTheme(dark, {
  palette: {
    primary: '#9593FE',
    contrastHigh: '#f9fafb',
    contrastLow: '#d1d6e0',
    contrastInvert: '#000000',
    surface: '#1f242e',
    surfaceAlt: '#14181e',
    background: '#090c0f',
    backgroundAlt: '#000000',
    secondary: '#3d475c',
    secondaryAlt: '#535f7a'
  }
});

window.videojs.default.registerPlugin('interactionAreaPlugin', InteractionAreaPlugin);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateStore } = useStore();
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const onPlayerReady = useCallback(
    player => {
      updateStore({
        player,
        interactionAreaPlugin: player.videojs.interactionAreaPlugin(player, updateStore)
      });

      setIsPlayerReady(true);
    },
    [updateStore]
  );

  const closeOnboarding = ({ config }) => {
    setShowOnboarding(false);

    if (!config) {
      navigate(`/preview${location.search}`);
      window.location.reload();
    }

    if (config) {
      const baseUrl = window.location.href.split('?')[0];
      const queryString = createUrlFromArrayObjects(window.location.href, [config], {
        currentlyPlayingPublicId: config.publicId,
        selectedHotspotId: config.hotspotId
      }).split('?')[1];

      const newUrl = `${baseUrl}preview?${queryString}`;

      window.history.replaceState({ path: newUrl }, '', newUrl);
      window.location.reload();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <VttProvider>
        <VideoUploadProvider>
          <Routes>
            {showOnboarding && (
              <Route
                path="/"
                element={
                  <OnboardingProvider>
                    <EditHotspotProvider>
                      <OnboardingWizard closeOnboarding={closeOnboarding} />
                    </EditHotspotProvider>
                  </OnboardingProvider>
                }
              />
            )}
            <Route
              path="/preview"
              element={
                <EditHotspotProvider>
                  <Preview
                    player={<Player cloud={CLOUD_NAME} onReady={onPlayerReady} />}
                    timeline={<Timeline isPlayerReady={isPlayerReady} />}
                  />
                </EditHotspotProvider>
              }
            />
            <Route
              path="/edit"
              element={
                <EditHotspotProvider>
                  <EditHotspot
                    player={<Player cloud={CLOUD_NAME} onReady={onPlayerReady} />}
                    timeline={<Timeline isPlayerReady={isPlayerReady} />}
                  />
                </EditHotspotProvider>
              }
            />
          </Routes>
        </VideoUploadProvider>
      </VttProvider>
    </ThemeProvider>
  );
}

export default App;
