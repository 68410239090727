import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';

const COMPASS_DIRECTIONS = [
  'north_west',
  'north',
  'north_east',
  'west',
  'center',
  'east',
  'south_west',
  'south',
  'south_east'
];

const CompassGridOuter = styled.div`
  position: relative;
`;

const CompassGridInner = styled.div`
  width: 4rem;
  height: 4rem;
  padding: ${globalTheme.spacing.xxs};
  background: ${globalTheme.palette.surfaceAlt};
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  border-radius: ${globalTheme.radius.lg};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  margin: 32px;
`;

const CompassItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: ${({ $isSelected }) =>
      $isSelected ? globalTheme.palette.primary : globalTheme.palette.secondaryAlt};
    border: 2px solid
      ${({ $isSelected }) =>
        $isSelected ? globalTheme.palette.primary : globalTheme.palette.surfaceAlt};
  }
`;

export const getLegendVerticalPosition = direction => {
  switch (direction) {
    case 'north_east':
    case 'north_west':
    case 'north':
      return 'top: 0;';
    case 'south_east':
    case 'south_west':
    case 'south':
      return 'bottom: 0';
    case 'center':
      return 'top: 50%; transform: translate(-50%, -50%);';
    default:
      return 'top: 50%; transform: translateY(-50%);';
  }
};

export const getLegendHorizontalPosition = direction => {
  switch (direction) {
    case 'south_west':
    case 'north_west':
    case 'west':
      return 'left: 0;';
    case 'south_east':
    case 'north_east':
    case 'east':
      return 'right: 0;';
    case 'center':
      return 'left: 50%; transform: translate(-50%, -50%);';
    default:
      return 'left: 50%; transform: translateX(-50%);';
  }
};

const CompassLegend = styled(Typography)`
  position: absolute;
  padding: ${globalTheme.spacing.xs};
  ${({ direction }) => getLegendHorizontalPosition(direction)};
  ${({ direction }) => getLegendVerticalPosition(direction)};
`;

const Compass = ({ onHotspotPositionChange, hotspotPosition }) => {
  return (
    <CompassGridOuter>
      <CompassGridInner>
        {COMPASS_DIRECTIONS.map(direction => (
          <CompassItem
            key={direction}
            data-test-specifier={`compass:${direction}`}
            onClick={() => {
              onHotspotPositionChange(direction);
            }}
            $isSelected={hotspotPosition === direction}
          />
        ))}
      </CompassGridInner>
      <CompassLegend size="xs" type="regular" direction="east">
        E
      </CompassLegend>
      <CompassLegend size="xs" type="regular" direction="west">
        W
      </CompassLegend>
      <CompassLegend size="xs" type="regular" direction="north">
        N
      </CompassLegend>
      <CompassLegend size="xs" type="regular" direction="south">
        S
      </CompassLegend>
    </CompassGridOuter>
  );
};

export default Compass;
