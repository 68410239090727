export const CLOUD_NAME = 'interactive-video';

export const WIZARD_SAMPLE_VIDEOS_FOLDER_NAME = 'wizard-sample-videos';

export const SAMPLE_VIDEOS = [
  {
    hotspotId: 'woman_with_camera',
    publicId: 'qh46vgvuenxo0qnaz8wr',
    type: 'action',
    videoDuration: 13.76,
    hotspotStateOpen: true,
    position: 'center',
    animation: 'fade-in',
    pauseOnClick: true,
    openInNewTab: true,
    hotspotTheme: 'theme_1',
    objectTrackingResultsUrl:
      'https://res.cloudinary.com/interactive-video/raw/upload/s--dCdn2tvS--/qh46vgvuenxo0qnaz8wr_adjusted_object_tracking.json',
    detectedObjects: 'hat%2Couterwear%2Cpants%2Ctop',
    vttsDataArray: [
      {
        hat: 'https://res.cloudinary.com/interactive-video/raw/upload/v1714988712/qh46vgvuenxo0qnaz8wr_hat.vtt'
      },
      {
        outerwear:
          'https://res.cloudinary.com/interactive-video/raw/upload/v1714988716/qh46vgvuenxo0qnaz8wr_outerwear.vtt'
      },
      {
        pants:
          'https://res.cloudinary.com/interactive-video/raw/upload/v1714988720/qh46vgvuenxo0qnaz8wr_pants.vtt'
      },

      {
        top: 'https://res.cloudinary.com/interactive-video/raw/upload/v1714988731/qh46vgvuenxo0qnaz8wr_top.vtt'
      }
    ]
  },
  {
    hotspotId: 'woman_in_black',
    publicId: 'aldd71sisnoic086iaqn',
    type: 'action',
    videoDuration: 6,
    hotspotStateOpen: true,
    position: 'center',
    animation: 'fade-in',
    pauseOnClick: true,
    openInNewTab: true,
    hotspotTheme: 'theme_1',
    objectTrackingResultsUrl: `https://res.cloudinary.com/interactive-video/raw/upload/aldd71sisnoic086iaqn_object_tracking.json`,
    detectedObjects: 'hat%2Couterwear%2Ctop%2Cpants',
    vttsDataArray: [
      {
        pants:
          'https://res.cloudinary.com/interactive-video/raw/upload/aldd71sisnoic086iaqn_pants.vtt'
      },
      {
        outerwear:
          'https://res.cloudinary.com/interactive-video/raw/upload/aldd71sisnoic086iaqn_outerwear.vtt'
      },
      {
        top: 'https://res.cloudinary.com/interactive-video/raw/upload/aldd71sisnoic086iaqn_top.vtt'
      },
      {
        hat: 'https://res.cloudinary.com/interactive-video/raw/upload/aldd71sisnoic086iaqn_hat.vtt'
      }
    ]
  }
];

export const TRIGGERS_TYPES_ENUMS = {
  OBJECT: 'object',
  TIME: 'time'
};

export const ANIMATIONS_ENUMS = {
  'fade-in': 'Fade In',
  'fade-in-fwd': 'Fade FW',
  'slide-in-blurred-top': 'Fast slide',
  'swing-in-top-fwd': 'Swing',
  'flip-in-diag-2-br': 'Flip',
  'tilt-in-top-1': 'Tilt',
  'rotate-in-top': 'Rotate'
};

const getBaseApi = () => {
  // return 'https://staging-smart-ai-transformations-bff.cloudinary.com';
  // if (window.location.host.includes('staging') || window.location.host.includes('localhost')){
  //   return 'https://staging-smart-ai-transformations-bff.cloudinary.com';
  // }
  //
  return 'https://production-smart-ai-transformations-bff.cloudinary.com';
};

export const INTERACTIVE_VIDEO_API = `${getBaseApi()}/interactive-video`;

export const TERMS_OF_USE_PUBLIC_ID = 'v1712735203/terms_of_use_hkuz8z';
