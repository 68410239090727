import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import BannerBigImage from '../../assets/banner_big_image.png';
import cloudinaryLogo from '../../../../assets/cloudinary-logo.svg';
import { Button } from '@clds/button';
import Typography from '@clds/typography';

export const CloudinaryLogo = styled.img.attrs({ src: cloudinaryLogo, alt: 'Cloudinary' })`
  height: 1.4em;
`;

export const Sections = styled.div.attrs({ className: 'sections' })`
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Section = styled.div.attrs({ className: 'section' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${globalTheme.palette.background};
  justify-content: space-around;

  &:last-of-type {
    padding: 1rem;

    * {
      font-weight: 100;
      font-family: 'Zen Kaku Gothic Antique';
    }
  }

  @media (min-width: 640px) {
    &:last-of-type {
      padding: 0;
      padding-left: 1rem;
      width: 65%;
    }
  }
`;

export const InputSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: baseline;
  padding-top: 0.5rem;

  @media (min-width: 640px) {
    padding-top: 2rem;
  }
`;

export const SectionLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const LeftSectionBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
  padding: 3rem;
  word-break: break-word;
  background-image: url(${BannerBigImage});
  background-size: cover;
  background-position: center;

  * {
    font-weight: 100;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 5rem;
    width: 5rem;
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.3rem;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const BannerFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InlineSeparatorsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: baseline;

  * {
    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledTitleText = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 1rem;

  @media (min-width: 640px) {
    padding-top: 2rem;
    padding-bottom: 0;
  }
`;

export const Separator = styled.div`
  border: 0.5px solid ${globalTheme.palette.secondaryAlt};
  width: 100%;
  margin: 1rem 0;
  @media (min-width: 640px) {
    margin: 3rem 0;
  }
`;

export const DropzoneSectionWrapper = styled.div.attrs({ className: 'dropzone-section-wrapper' })`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  width: 100%;
  height: 20vh;
`;

export const DropzoneWithSamplesWrapper = styled.div.attrs({
  className: 'dropzone-with-samples-wrapper'
})`
  display: flex;
  gap: 1rem;
  flex-grow: 1;

  @media (min-width: 640px) {
    height: 20vh;
  }
`;

export const DragAndDropSection = styled.div.attrs({ className: 'drag-and-drop-section' })`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 2rem;
  background-color: #141719;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23535f7a' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='39' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 13px;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    margin-bottom: 3rem;
    padding: 4rem;
  }
`;

export const SamplesSection = styled.div.attrs({ className: 'samples-section' })`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const PosterWrapper = styled.div.attrs({ className: 'poster-wrapper' })`
  position: relative;
  height: calc(50% - 0.5rem);
`;

export const DurationLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.2rem;
  border-radius: 1rem;
  font-size: 0.6rem;
  margin-bottom: 0.6rem;
  margin-left: 0.3rem;
  backdrop-filter: blur(2px);
`;

export const StyledVideoPoster = styled.img`
  border-radius: 1rem;
  cursor: pointer;
  width: 10rem;
  object-fit: cover;
  height: 100%;
`;

export const TermsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 0.5rem;
  padding-top: 1rem;
`;

// export const TermsSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 0.5rem;

//   @media (max-width: 640px) {
//     text-align: center;
//   }
// `;

export const AnchorsWrapper = styled(Typography)`
  display: flex;
`;

export const StyledAnchor = styled.a`
  display: flex;
  text-decoration: underline;
  color: #fffffe;
  cursor: pointer;

  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:last-of-type {
    margin-left: 0.5rem;
  }
`;

export const InputForm = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const NextButtonWrapper = styled.div`
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};

  * {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
`;

export const StyledButton = styled(Button)`
  div {
    font-weight: 500 !important;
  }
`;
