import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getImageUrlByPublicId } from '../utils/getUrlByPublicId';
import { getConfigFromUrl } from '../utils/getConfigFromUrl';
import { createUrlFromArrayObjects } from '../utils/createUrlFromArrayObjects';
import { INTERACTIVE_VIDEO_API } from '../const';

const useImageUpload = ({ onHotspotActionTypeImageUrlChange }) => {
  const [isUploading, setIsUploading] = useState(false);
  const { configs, globalConfig } = getConfigFromUrl();

  const uploadImage = useCallback(
    async file => {
      const formData = new FormData();
      formData.append('image', file);

      setIsUploading(true);

      try {
        const response = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Image upload successful', data);

          const imageUrl = getImageUrlByPublicId(data.publicId);

          onHotspotActionTypeImageUrlChange(imageUrl);
          setIsUploading(false);

          const updatedConfig = configs.map(item => {
            if (item.hotspotId === globalConfig.selectedHotspotId) {
              return { ...item, hotspotActionTypeImageUrl: imageUrl };
            }
            return item;
          });

          const newUrl = createUrlFromArrayObjects(
            window.location.href,
            updatedConfig,
            globalConfig
          );

          window.history.replaceState({ path: newUrl }, '', newUrl);
        } else {
          console.error('Image upload failed', response);
        }
      } catch (error) {
        console.error('Error during an image upload', error);
      }
    },
    [configs, globalConfig, onHotspotActionTypeImageUrlChange]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      uploadImage(file);
    },
    [uploadImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    }
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isUploading
  };
};

export default useImageUpload;
