import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { themes } from './hotspotWidgetThemes';

const boxStyle = (color, isFirstOfType) => ({
  backgroundColor: color,
  width: '3.5vh',
  height: '3.5vh',
  borderRight: '1px solid #535f7a',
  ...(isFirstOfType ? { borderTopLeftRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' } : {})
});

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: ${props => (props.isOnboarding ? '-14rem' : '0')};
  z-index: 9999;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  background-color: ${globalTheme.palette.surface};
`;

const Root = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
`;

const BoxesWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const FontBox = styled.div`
  flex: 1;
  text-align: center;
  font-weight: bold;
  padding: 0 1rem;

  @media (min-width: 700px) {
    padding: 0 2rem;
  }
`;

export const PaletteRow = ({ selectedTheme = 'theme_1' }) => {
  const theme = themes.find(theme => theme.name === selectedTheme) || themes[0];

  return (
    <Root>
      <BoxesWrapper>
        <div style={boxStyle(theme['text-color'], true)} />
        <div style={boxStyle(theme['background-color'])} />
        <div style={boxStyle(theme['button-color'])} />
      </BoxesWrapper>
      <FontBox style={{ fontFamily: theme['font-family'] }}>Abc</FontBox>
    </Root>
  );
};

export const PaletteModal = ({ onHotspotThemeChange, isOnboarding }) => {
  return (
    <ModalRoot isOnboarding={isOnboarding}>
      {themes.map(theme => (
        <Root key={theme.name} onClick={() => onHotspotThemeChange(theme.name)}>
          <BoxesWrapper>
            <div style={boxStyle(theme['text-color'], true)} />
            <div style={boxStyle(theme['background-color'])} />
            <div style={boxStyle(theme['button-color'])} />
          </BoxesWrapper>
          <FontBox style={{ fontFamily: theme['font-family'] }}>Abc</FontBox>
        </Root>
      ))}
    </ModalRoot>
  );
};
