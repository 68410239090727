import { Section, SelectWrapper, StyledDropDownArrow, StyledSelect } from './EditHotspot.styled';
import Typography from '@clds/typography';
import { ANIMATIONS_ENUMS } from '../../const';
import React from 'react';
import useEditHotspot from './useEditHotspot';

export const AnimationSection = () => {
  const { onHotspotAnimationChange, hotspotAnimation } = useEditHotspot();
  return (
    <Section>
      <Typography size="xs">Animation</Typography>
      <SelectWrapper>
        <StyledDropDownArrow size="xs" />
        <StyledSelect
          value={hotspotAnimation}
          onChange={e => onHotspotAnimationChange(e.target.value)}
        >
          {Object.entries(ANIMATIONS_ENUMS).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </SelectWrapper>
    </Section>
  );
};
