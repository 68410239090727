import React, { useState, useEffect } from 'react';
import Typography from '@clds/typography';
import { Button } from '@clds/button';
import OpenUrlIcon from '../../../../icons/OpenUrlIcon';
import WizardSteps from '../../WizardSteps';
import { useVideoUpload } from '../../../../hooks/useVideoUpload';
import { useOnboarding } from '../../../../hooks/useOnboarding';

import {
  Content,
  MobileContent,
  StickyNextButton,
  SelectTypeSection,
  Types,
  RelativeContainer,
  TypeContainer,
  StyledChip,
  StyledTypography,
  TitleWrapper
} from './Type.styled';
import { Separator } from '../StartPage/StartPage.styled';

const Type = () => {
  const [selectedType] = useState('action');
  const {
    config,
    currentStep,
    goToNextStep,
    persistDataInOnboardingState,
    setMobileBreakpoint,
    isMobileDevice
  } = useOnboarding();
  const { isUploading } = useVideoUpload();

  useEffect(() => {
    setMobileBreakpoint(760);
  }, [setMobileBreakpoint]);

  return (
    <>
      {isMobileDevice && (
        <MobileContent>
          <div style={{ padding: '2rem' }}>
            <Typography size="xl">Choose Type</Typography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', gap: '1rem' }}>
              <TypeContainer $isSelected={selectedType === 'action'}>
                <OpenUrlIcon />
                <Typography>Hotspots</Typography>
              </TypeContainer>

              <RelativeContainer>
                <TypeContainer isDisabled>
                  <img
                    alt=""
                    src="https://res.cloudinary.com/prod/image/upload/v1710936502/video-player/interactive-video/questionsInteraction_munhp5.svg"
                  />
                  <Typography>Interactive Q&A</Typography>
                </TypeContainer>
                <StyledChip label={'Coming soon'} tone="subtle" />
              </RelativeContainer>
            </div>

            <RelativeContainer>
              <TypeContainer isDisabled>
                <img
                  alt=""
                  src="https://res.cloudinary.com/prod/image/upload/v1710936512/video-player/interactive-video/TourInteraction_klqro0.svg"
                />
                <Typography>Guided Tour</Typography>
              </TypeContainer>
              <StyledChip label={'Coming soon'} tone="subtle" />
            </RelativeContainer>
          </div>

          <Separator />

          <StyledTypography>You can always change this later</StyledTypography>
          <StickyNextButton
            onClick={() => {
              persistDataInOnboardingState({ ...config, type: 'action' });
              goToNextStep();
            }}
          >
            Next
          </StickyNextButton>
        </MobileContent>
      )}

      {!isMobileDevice && (
        <Content>
          <WizardSteps currentStep={currentStep} isVideoUploading={isUploading} />

          <SelectTypeSection>
            <TitleWrapper>
              <Typography size="xl">Choose Type</Typography>
              <Typography size="lg">Select the interactivity type for your video</Typography>
            </TitleWrapper>

            <>
              <Types>
                <TypeContainer $isSelected={selectedType === 'action'}>
                  <OpenUrlIcon />
                  <Typography>Hotspots</Typography>
                </TypeContainer>

                <RelativeContainer>
                  <TypeContainer isDisabled>
                    <img
                      alt=""
                      src="https://res.cloudinary.com/prod/image/upload/v1710936502/video-player/interactive-video/questionsInteraction_munhp5.svg"
                    />
                    <Typography>Interactive Q&A</Typography>
                  </TypeContainer>
                  <StyledChip label={'Coming soon'} tone="subtle" />
                </RelativeContainer>

                <RelativeContainer>
                  <TypeContainer isDisabled>
                    <img
                      alt=""
                      src="https://res.cloudinary.com/prod/image/upload/v1710936512/video-player/interactive-video/TourInteraction_klqro0.svg"
                    />
                    <Typography>Guided Tour</Typography>
                  </TypeContainer>
                  <StyledChip label={'Coming soon'} tone="subtle" />
                </RelativeContainer>
              </Types>

              <StyledTypography>You can always change this later</StyledTypography>

              {config.type || selectedType ? (
                <Button
                  onClick={() => {
                    persistDataInOnboardingState({ ...config, type: 'action' });
                    goToNextStep();
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button style={{ visibility: 'hidden' }}>Next</Button>
              )}
            </>
          </SelectTypeSection>
        </Content>
      )}
    </>
  );
};

export default Type;
