import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Chip from '@clds/chip';
import Typography from '@clds/typography';
import { Button } from '@clds/button';

export const Content = styled.div`
  display: flex;
  padding: 0;

  @media (min-width: 640px) {
    padding: 3rem 0;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 100%;
  overflow-y: hidden;
`;

export const SelectTypeSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

export const Types = styled.div`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0;
  margin-bottom: 2rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    border-bottom: 1px solid ${globalTheme.palette.secondary};
  }

  @media (min-width: 640px) {
    padding: 2rem 0;
  }

  @media (max-width: 760px) {
    transform: scale(0.6);
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  width: 10rem;
  opacity: ${props => (props.isDisabled ? 0.6 : 1)};
  filter: ${({ $isSelected }) => ($isSelected ? 'none' : 'grayscale(100%)')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  * {
    color: ${({ $isSelected }) => ($isSelected ? globalTheme.palette.primary : 'unset')};
  }

  & > div:first-child {
    border: 1px solid
      ${({ $isSelected }) => ($isSelected ? globalTheme.palette.primary : '#3f475a')};
  }

  > svg:hover,
  div:hover {
    box-shadow: ${globalTheme.palette.surface} 0px 2px 8px 0px;
  }
`;

export const StyledChip = styled(Chip)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  background-color: ${globalTheme.palette.primary};
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 2rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`;

export const StickyNextButton = styled(Button)`
  display: block;
  position: sticky;
  text-align: center;
  width: 15%;
  bottom: 3rem;
  margin-top: 1rem;
`;
