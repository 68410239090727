import React from 'react';

const VideoIcon = () => {
  return (
    <img
      alt=""
      src="https://res.cloudinary.com/prod/image/upload/v1711007019/video-player/interactive-video/VideoIcon.svg"
    />
  );
};

export default VideoIcon;
