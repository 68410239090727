import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import SoundIcon from '../../icons/timeline_sound_icon.svg';
import TimingBarIcon from '../../icons/timing_bar_icon.svg';
import Typography, { typography } from '@clds/typography';

export const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({ width }) => width}%;
`;

export const TimelineWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const StyledPoster = styled.img`
  max-height: 2rem;
`;

export const VideoSectionWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  background-image: url(${SoundIcon});
  background-repeat: repeat-x;
  background-position: center center;
  background-size: auto 60%;
  border-radius: 0.5rem;
  background-color: ${globalTheme.palette.primary};
  height: 32px;
`;

export const VideoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${globalTheme.palette.primary};
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  height: 100%;
`;

export const Indicator = styled.img.attrs(props => ({
  src: TimingBarIcon,
  style: {
    left: props.left ? `calc(${props.left}% - 7px)` : 0
  }
}))`
  position: absolute;
  top: 0;
  transition: transform 0.2s ease-in-out;
  pointer-events: none;
  will-change: transform;
`;

export const TimeframeIndicator = styled.div`
  display: flex;
  height: 1rem;
  background-color: #b2fce2;
  position: absolute;
  align-items: center;
  width: ${props => props.width}%;
  left: ${props => props.left}%;
  pointer-events: none;
  border-radius: 2px;

  svg {
    margin-left: 0.5rem;
  }

  div {
    margin-left: 0.5rem;
    ${typography.xs.contrastInvert};
  }
`;

export const VideoSectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${props => props.width}%;
  position: relative;
`;

export const RulerWrapper = styled.div`
  width: 100%;
`;

export const SingleTimeframeIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  width: 100%;
  cursor: pointer;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: #527169;

  &:hover {
    & > div {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      filter: brightness(0.7);
      transition: all 0.3s ease;
    }
  }
`;

export const IndicatorWrapperText = styled.span`
  z-index: 2;
  ${typography.xs.contrastInvert};
  margin-left: ${globalTheme.spacing.xs};
  text-transform: capitalize;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => `${height}`};
  padding: 0.5rem 0;
`;

export const DragAndDropSection = styled.div`
  display: flex;
  position: relative;
  height: 10rem;
  width: 100%;
  padding: 2rem;
  cursor: pointer;
  background-color: ${globalTheme.palette.surfaceAlt};
  animation: scale-up-ver-bottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  @keyframes scale-up-ver-bottom {
    0% {
      transform: scaleY(0.4);
      transform-origin: 0% 100%;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 0% 100%;
    }
  }
`;

export const DragAndDropOverlay = styled.div`
  display: flex;
  width: 95%;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${globalTheme.palette.surfaceAlt};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23535f7a' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
`;

export const EmptyStateTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.5rem;
`;

export const AnimatedTypography = styled(Typography)`
  animation: heartbeat;
  animation-duration: 1.5s;
  animation-iteration-count: 3;

  @keyframes heartbeat {
    from {
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
`;
