const theme_1 = {
  name: 'theme_1',
  'text-color': 'black',
  'background-color': 'rgba(255, 255, 255, 0.50)',
  'backdrop-filter': 'blur(4px)',
  'button-color': '#0022F7',
  'button-text-color': 'white',
  'button-shape': 'normal',
  'font-family': 'Ubuntu Condensed'
};

const theme_2 = {
  name: 'theme_2',
  'text-color': 'white',
  'background-color': 'rgba(0, 0, 0, 0.50)',
  'backdrop-filter': 'blur(4px)',
  'button-color': '#9593FE',
  'button-text-color': 'black',
  'button-shape': 'pill',
  'font-family': 'Solway'
};

const theme_3 = {
  name: 'theme_3',
  'text-color': 'black',
  'background-color': '#F4FF53',
  'button-color': 'black',
  'button-text-color': '#F4FF53',
  'button-shape': 'pill',
  'font-family': 'Sniglet'
};

const theme_4 = {
  name: 'theme_4',
  'text-color': 'black',
  'background-color': 'rgba(255, 255, 255, 0.80)',
  'backdrop-filter': 'blur(4px)',
  'button-color': '#FF4B12',
  'button-text-color': 'white',
  'button-shape': 'normal',
  'font-family': 'Varela Round'
};

const theme_5 = {
  name: 'theme_5',
  'text-color': 'white',
  'background-color': 'rgba(0, 0, 0, 0.80)',
  'button-color': '#B2FCE2',
  'button-text-color': 'black',
  'button-shape': 'pill',
  'font-family': 'Alata'
};

const theme_6 = {
  name: 'theme_6',
  'text-color': 'black',
  'background-color': '#9593FE',
  'button-color': 'black',
  'button-text-color': '#9593FE',
  'button-shape': 'pill',
  'font-family': 'Alata'
};

const theme_7 = {
  name: 'theme_7',
  'text-color': 'black',
  'background-color': 'rgba(255, 255, 255, 0.50)',
  'backdrop-filter': 'blur(4px)',
  'button-color': '#7C15FF',
  'button-text-color': 'white',
  'button-shape': 'normal',
  'font-family': 'Roboto Flex'
};

const theme_8 = {
  name: 'theme_8',
  'text-color': 'white',
  'background-color': 'rgba(0, 0, 0, 0.50)',
  'backdrop-filter': 'blur(4px)',
  'button-color': '#FF5469',
  'button-text-color': 'black',
  'button-shape': 'pill',
  'font-family': 'Zilla Slab'
};

const theme_9 = {
  name: 'theme_9',
  'text-color': 'black',
  'background-color': '#00E589',
  'backdrop-filter': 'blur(4px)',
  'button-color': 'black',
  'button-text-color': '#00E589',
  'button-shape': 'pill',
  'font-family': 'IBM Plex Mono'
};

export const themes = [
  theme_1,
  theme_2,
  theme_3,
  theme_4,
  theme_5,
  theme_6,
  theme_7,
  theme_8,
  theme_9
];
