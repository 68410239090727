import styled from 'styled-components';
import { Button } from '@clds/button';
import { globalTheme } from '@clds/component-theme';
import bannerBackground from '../../assets/banner_background.png';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Banner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  height: 7rem;
  gap: 1rem;
  width: 100%;
  background-color: #392766;
  background-color: #392766;
  background-image: url(${bannerBackground});
  background-size: cover;
  background-position: center;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    font-weight: bold;
  }

  &:last-child {
    font-weight: 100;
  }
`;

export const PlayerWrapper = styled.div`
  padding-top: 2rem;
  height: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

// ShareModal styles

export const StyledShareButton = styled(Button)`
  background-color: #fffffe;

  * {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
`;

export const ShareModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: ${globalTheme.palette.background};
  padding: 1.5rem;
  border-radius: 1rem;
  position: absolute;
  right: 0;
  top: 5rem;
  z-index: 2;
  width: inherit;
  animation: scale-up-center 0.5s ease;

  @media (min-width: 700px) {
    width: 40rem;
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ShareModalHeading = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ShareModalCloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ShareModalLinkWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ShareModalLink = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fefffe;
`;

export const ShareModalCopyIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledAnchor = styled.a`
  text-decoration: underline;
  color: #fffffe;
  cursor: pointer;
  margin-left: 0.5rem;
`;
