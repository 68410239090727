import React, { useEffect, useState } from 'react';
import Typography from '@clds/typography';
import { Settings } from '@clds/icon';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Link from '../../../Hotspots/Link';
import { ColorSection } from '../Edit/ColorSection';
import UploadCloudIcon from '../../../../icons/UploadCloudIcon';
import TrackingIcon from '../../../../icons/TrackingIconBig';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import { useScraper } from '../../../../hooks/useScraper';
import useTrackByImageAndCreateVtt from './useTrackByImageAndCreateVtt';
import { AnimationSection } from '../Actions/AnimationSection';
import { ANIMATIONS_ENUMS } from '../../../../const';
import { themes } from '../../../EditHotspot/hotspotWidgetThemes';
import { getPublicIdFromUrl } from '../../../../utils/getPublicIdFromUrl';
import OnboardingTrackingPreviewPlayer from '../../../Player/OnboardingTrackingPreviewPlayer';
import { fetchAndParseVtt } from '../../../../utils/parseVttDataToTimeframes';
import Timeline from '../Track/Timeline';
import VideoReadyBanner from './VideoReadyBanner';
import { createUrlFromArrayObjects } from '../../../../utils/createUrlFromArrayObjects';
import { getImageUrlByPublicId } from '../../../../utils/getUrlByPublicId';

import { Row } from '../Track/Track.styled';
import {
  Root,
  InputForm,
  Content,
  StyledInput,
  StyleBox,
  Video,
  LoadingSkeleton,
  ZIndexWrapper,
  StyledTypography,
  HotspotPreview,
  HotspotPreviewContent,
  HotspotPreviewBox,
  Boxes,
  LoadingSectionLayer,
  Box,
  StyledImage,
  NextButtonStickyWrapper,
  StickyNextButton,
  FlexSpacer
} from './Scraper.styled';

const loadingTextColor = '#9593FE';

const defaultAnimationKey = Object.keys(ANIMATIONS_ENUMS).find(
  key => ANIMATIONS_ENUMS[key] === 'Fade In'
);

const Scraper = ({ scraperFetcher }) => {
  const { config, setStep, persistDataInOnboardingState, isMobileDevice } = useOnboarding();
  const { isScraping, scrapeWebsite, getProductName } = useScraper({ fetcher: scraperFetcher });

  const { trackByImageAndCreateVtt, isCreatingVtt } = useTrackByImageAndCreateVtt(config);
  const [customTrackingTimeframesData, setCustomTrackingTimeframesData] = useState(null);

  const [scraperData, setScraperData] = useState(null);

  const [isError, setIsError] = useState(false);

  const [productName, setProductName] = useState(null);
  const [hotspotTitle, setHotspotTitle] = useState('Title');
  const [hotspotCtaText, setHotspotCtaText] = useState('Buy Now');
  const [hotspotAnimation, setHotspotAnimation] = useState(defaultAnimationKey);
  const [hotspotTheme, setHotspotTheme] = useState(themes[6].name);
  const [vttByImage, setVttByImage] = useState(null);

  const [interactionAreaPluginState, setInteractionAreaPluginState] = useState(null);

  const onSetInteractionAreaPlugin = interactionAreaPlugin => {
    setInteractionAreaPluginState(interactionAreaPlugin);
  };

  useEffect(() => {
    if (config?.targetUrl) {
      getProductName(config.targetUrl).then(data => {
        if (data?.productName) {
          setProductName(data.productName);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.targetUrl]);

  useEffect(() => {
    if (!scraperData && config?.targetUrl) {
      scrapeWebsite(config.targetUrl).then(data => {
        if (!data?.videoUrl || !data?.imageUrl) {
          setIsError(true);
          toast.error("Didn't get the product details. Navigating back to start page.");
          setTimeout(() => {
            setStep(0);
          }, 3000);
        } else {
          setScraperData(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, scraperData, setStep]);

  useEffect(() => {
    if (productName) {
      setHotspotTitle(productName);
    }
  }, [productName]);

  useEffect(() => {
    if (
      scraperData?.imageData?.publicId &&
      scraperData?.videoData?.publicId &&
      productName &&
      interactionAreaPluginState?.interactionAreaPlugin
    ) {
      const fetchVttData = async () => {
        const vttData = await trackByImageAndCreateVtt(
          scraperData?.videoData?.publicId,
          getPublicIdFromUrl(scraperData?.imageData?.publicId),
          productName
        );
        setVttByImage(vttData);

        const vttUrl = Object.values(vttData)[0];

        interactionAreaPluginState.interactionAreaPlugin.setAreasPositionListener(
          [vttUrl],
          true,
          config.hotspotId
        );
      };

      fetchVttData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    interactionAreaPluginState?.interactionAreaPlugin,
    scraperData?.videoData?.publicId,
    scraperData?.imageData?.publicId,
    productName
  ]);

  useEffect(() => {
    const addVttByImageToTimeframes = async () => {
      if (vttByImage) {
        const objectName = Object.keys(vttByImage)[0];
        const vttUrl = vttByImage[objectName];
        if (vttUrl) {
          const newTimeframes = await fetchAndParseVtt(vttUrl).catch(error => {
            console.error('Error fetching or parsing VTT:', error);
          });
          setCustomTrackingTimeframesData(prevTimeframesData => ({
            [objectName]: newTimeframes,
            ...prevTimeframesData
          }));
        }
      }
    };

    addVttByImageToTimeframes();
  }, [vttByImage]);

  const navigateToPreview = () => {
    const newConfig = {
      ...config,
      pauseOnClick: true,
      openInNewTab: true,
      targetUrl: config.targetUrl,
      animation: hotspotAnimation,
      hotspotTheme,
      autoplayEnabled: true,
      loopEnabled: true,
      playerControlsEnabled: true,
      hotspotStateOpen: true,
      type: 'action',
      position: 'center',
      objectDescription: Object.keys(vttByImage)[0],
      publicId: scraperData?.videoData?.publicId,
      hotspotTitle,
      hotspotCtaText,
      ...(scraperData?.imageData?.publicId && {
        hotspotActionTypeImageUrl: getImageUrlByPublicId(scraperData?.imageData?.publicId)
      }),
      vtt: vttByImage[Object.keys(vttByImage)[0]],
      vttByImageData: vttByImage,
      videoDuration: scraperData?.videoData?.originalDurationSeconds
    };

    persistDataInOnboardingState(newConfig);

    const newUrl = createUrlFromArrayObjects(window.location.href, [newConfig], {
      currentlyPlayingPublicId: scraperData?.videoData?.publicId,
      selectedHotspotId: config.hotspotId,
      autoplayEnabled: false,
      loopEnabled: false,
      playerControlsEnabled: true
    });
    window.history.replaceState({ path: newUrl }, '', newUrl);
    setStep(5);
  };

  return (
    <Root>
      <ToastContainer />
      {!isMobileDevice && vttByImage && <VideoReadyBanner onClick={navigateToPreview} />}

      <InputForm>
        <Typography size="xl">Target URL:</Typography>
        <StyledInput type="text" value={config.targetUrl} readOnly />
      </InputForm>
      {isScraping && (
        <StyledTypography size="sm" paddingBottom="1em" textAlign="center">
          <p>Processing your request…</p>
          <p>This may take a few minutes, but it will be worth the wait.</p>
        </StyledTypography>
      )}
      <Content>
        {(isScraping || isError) && (
          <LoadingSectionLayer
            height={isMobileDevice ? '8rem' : '100%'}
            width={isMobileDevice ? '100%' : '50%'}
          >
            <LoadingSkeleton>
              {!isError && (
                <ZIndexWrapper>
                  <UploadCloudIcon color={loadingTextColor} />
                  <Typography>Uploading Video</Typography>
                </ZIndexWrapper>
              )}
            </LoadingSkeleton>
          </LoadingSectionLayer>
        )}

        {!isScraping && scraperData?.videoData?.publicId && (
          <Video width={isMobileDevice ? '100%' : '50%'} borderRadius="1rem">
            <OnboardingTrackingPreviewPlayer
              videoPublicId={scraperData?.videoData?.publicId}
              onSetInteractionAreaPlugin={onSetInteractionAreaPlugin}
            />
          </Video>
        )}

        <HotspotPreview>
          <StyledTypography size="xl" isLoading={isScraping} paddingBottom="2rem">
            Hotspot preview
          </StyledTypography>
          {!isScraping && (
            <HotspotPreviewContent>
              <AnimationSection
                selectedAnimation={hotspotAnimation}
                setHotspotAnimation={setHotspotAnimation}
              />

              <HotspotPreviewBox>
                <Link
                  title={hotspotTitle}
                  image={scraperData?.imageUrl}
                  checkOutText={hotspotCtaText}
                  checkOutLink={config?.targetUrl}
                  theme={hotspotTheme}
                  animation={hotspotAnimation}
                />
              </HotspotPreviewBox>
            </HotspotPreviewContent>
          )}
        </HotspotPreview>

        <Boxes>
          {!isScraping && (
            <Box height="50%">
              <StyledTypography size="xl" isLoading={isScraping} paddingBottom="1rem">
                Edit content
              </StyledTypography>

              <FlexSpacer>
                <StyleBox>
                  <Typography>Title</Typography>
                  <StyledInput
                    type="text"
                    value={hotspotTitle}
                    onChange={e => setHotspotTitle(e.target.value)}
                  />
                </StyleBox>

                <StyleBox>
                  <Typography>Button</Typography>
                  <StyledInput
                    type="text"
                    value={hotspotCtaText}
                    onChange={e => setHotspotCtaText(e.target.value)}
                  />
                </StyleBox>

                <StyleBox>
                  <Typography>Style</Typography>
                  <ColorSection hotspotTheme={hotspotTheme} onHotspotThemeChange={setHotspotTheme} />
                </StyleBox>
              </FlexSpacer>
            </Box>
          )}

          {isScraping && (
            <LoadingSectionLayer height={isMobileDevice ? '6rem' : '50%'}>
              <LoadingSkeleton>
                <ZIndexWrapper>
                  <Settings size="lg" color={loadingTextColor} />
                  <Typography>Fetching Product Details</Typography>
                </ZIndexWrapper>
              </LoadingSkeleton>
            </LoadingSectionLayer>
          )}

          {isScraping && (
            <LoadingSectionLayer height={isMobileDevice ? '6rem' : '35%'}>
              <LoadingSkeleton>
                <ZIndexWrapper>
                  <UploadCloudIcon color={loadingTextColor} />
                  <Typography>Choosing Perfect Image</Typography>
                </ZIndexWrapper>
              </LoadingSkeleton>
            </LoadingSectionLayer>
          )}

          {!isScraping && (
            <Box height="35%">
              <StyledTypography size="xl" paddingBottom="2rem">
                Product image
              </StyledTypography>
              <StyledImage src={scraperData?.imageUrl} alt="" loading="lazy" />
            </Box>
          )}

          {isCreatingVtt && (
            <LoadingSectionLayer height={isMobileDevice ? '6rem' : '15%'}>
              <LoadingSkeleton>
                <ZIndexWrapper>
                  <TrackingIcon color={loadingTextColor} />
                  <Typography>Mapping Object Movements</Typography>
                </ZIndexWrapper>
              </LoadingSkeleton>
            </LoadingSectionLayer>
          )}

          {!isCreatingVtt && isScraping && (
            <Box height={isMobileDevice ? '6rem' : '15%'} alignItems="center">
              <StyledTypography size="xl" paddingBottom="1rem" isLoading>
                Tracking
              </StyledTypography>
              <TrackingIcon color="#555F78" />
            </Box>
          )}

          {!isCreatingVtt && !isScraping && (
            <Box height="15%">
              <StyledTypography size="xl" paddingBottom="2rem">
                Tracking
              </StyledTypography>

              {customTrackingTimeframesData &&
                Object.entries(customTrackingTimeframesData).map(([objectName, timeframes]) => (
                  <Row key={objectName}>
                    <Timeline
                      timeframes={timeframes}
                      isScraper
                      videoDurationFromScraper={scraperData?.videoData?.originalDurationSeconds}
                    />
                  </Row>
                ))}
            </Box>
          )}

          {isMobileDevice && (
            <NextButtonStickyWrapper>
              <StickyNextButton isDisabled={!vttByImage} onClick={navigateToPreview}>
                Next
              </StickyNextButton>
            </NextButtonStickyWrapper>
          )}
        </Boxes>
      </Content>
    </Root>
  );
};

export default Scraper;
