import React, { useState } from 'react';
import { PaletteModal, PaletteRow } from './ThemeColorsPreview';
import useEditHotspot from './useEditHotspot';
import styled from 'styled-components';

const ColorSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: center;
`;

const PaletteRowWrapper = styled.div`
  position: relative;
`;

export const ColorSection = () => {
  const [isPaletteModalOpen, setIsPaletteModalOpen] = useState(false);
  const { hotspotTheme, onHotspotThemeChange } = useEditHotspot();

  return (
    <ColorSectionWrapper>
      <PaletteRowWrapper onClick={() => setIsPaletteModalOpen(!isPaletteModalOpen)}>
        <PaletteRow selectedTheme={hotspotTheme} />
        {isPaletteModalOpen && <PaletteModal onHotspotThemeChange={onHotspotThemeChange} />}
      </PaletteRowWrapper>
    </ColorSectionWrapper>
  );
};
