import React from 'react';
import { Separator, ContentWrapper } from './EditHotspot.styled';
import { useVideoUpload } from '../../hooks/useVideoUpload';
// import { TriggerSection } from './TriggerSection';
import { ActionSection } from './ActionSection';
import { AnimationSection } from './AnimationSection';

const InteractionTabContent = () => {
  const { isUploading, isObjectTracking } = useVideoUpload();

  return (
    <ContentWrapper $isDisabled={isUploading || isObjectTracking}>
      {/* <TriggerSection /> */}
      <ActionSection />
      <Separator />
      <AnimationSection />
    </ContentWrapper>
  );
};

export default InteractionTabContent;
