import React from 'react';
import styled from 'styled-components';
import { useOnboarding } from '../../../../hooks/useOnboarding';

const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #4c6c63;
  border-radius: 0.4rem;
  margin-right: ${props => (props.isScraper ? '0' : '1rem')};
  overflow: hidden;
`;

const Marker = styled.div`
  position: absolute;
  height: 100%;
  background: #b2fce2;
`;

const Timeline = ({ timeframes, isScraper = false, videoDurationFromScraper }) => {
  const { config } = useOnboarding();

  return (
    <TimelineContainer isScraper={isScraper}>
      {timeframes.map((frame, index) => {
        const leftPosition =
          (frame.startTime / (isScraper ? videoDurationFromScraper : config.videoDuration)) * 100;
        const width =
          ((frame.endTime - frame.startTime) /
            (isScraper ? videoDurationFromScraper : config.videoDuration)) *
          100;
        return (
          <Marker
            key={index}
            style={{
              left: `${leftPosition}%`,
              width: `${width + 0.1}%`
            }}
          />
        );
      })}
    </TimelineContainer>
  );
};

export default Timeline;
