import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Typography from '@clds/typography';
import { Button } from '@clds/button';

export const FlexSpacer = styled.div.attrs({ className: 'flex-spacer' })`
  flex-grow: 1;
`

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media (min-width: 640px) {
    margin-top: 0;
  }
`;

export const InputForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  padding: 0 1rem;

  * {
    font-weight: 100;
    font-family: 'Zen Kaku Gothic Antique';
  }

  @media (min-width: 640px) {
    flex-direction: row;
    width: 60%;
    align-items: center;
    padding: 1rem;
    margin-top: 0;
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  height: 40px;
  outline: none;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${globalTheme.palette.secondaryAlt};
  color: #fffffe;
  background-color: ${globalTheme.palette.surface};
`;

export const StyleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1.5rem;

  &:last-of-type {
    padding-bottom: 0;
    gap: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  flex-direction: column;

  @media (min-width: 640px) {
    padding: 0;
    flex-direction: row;
  }
`;

export const Video = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};

  .video-js {
    border-radius: ${props => props.borderRadius};
  }

  [data-vjs-player='true'] {
    border-radius: ${props => props.borderRadius};

    @media (max-width: 640px) {
      height: 20rem;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 100;
  align-self: center;
  text-align: ${props => props.textAlign };
  padding-bottom: ${props => props.paddingBottom};
  color: ${props => (props.isLoading ? '#555F78' : '#FFFFFE')};
`;

export const ZIndexWrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const LoadingSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #4e4f86;

  * {
    color: ${globalTheme.palette.primary};
  }
`;

export const HotspotPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  background-color: ${globalTheme.palette.surface};

  @media (min-width: 640px) {
    width: 25%;
    height: 100%;
  }
`;

export const HotspotPreviewContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
`;

export const HotspotPreviewBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Boxes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;

  @media (min-width: 640px) {
    width: 25%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  border-radius: 1rem;
  height: ${props => props.height};
  background-color: ${globalTheme.palette.surface};
  align-items: ${props => props.alignItems};
`;

export const LoadingSectionLayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 3px solid ${globalTheme.palette.primary};
  position: relative;
  overflow: hidden;
  width: ${props => props.width || '100%'};
  height: ${props => props.height};
  color: ${props => props.color};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #5c5d9e;
    animation: loadAnimation 3s linear infinite;
  }

  @keyframes loadAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;

export const StyledImage = styled.img`
  display: flex;
  align-self: center;
  max-width: 3.5rem;
  border-radius: 0.5rem;

  @media (min-width: 640px) {
    max-width: 5rem;
  }
`;

export const NextButtonStickyWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 2;
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: ${globalTheme.palette.background};
`;

export const StickyNextButton = styled(Button)`
  width: 20%;
  justify-content: center;
`;
