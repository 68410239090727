import forEach from 'lodash/forEach';
import some from 'lodash/some';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { elementsCreator, styleElement } from '../../utils/dom';
import {
  INTERACTION_AREA_LAYOUT_LOCAL_STORAGE_NAME,
  INTERACTION_AREAS_CONTAINER_CLASS_NAME,
  INTERACTION_AREAS_PREFIX,
  INTERACTION_AREAS_THEME
} from './interaction-area.const';
import { themes } from '../../components/EditHotspot/hotspotWidgetThemes';
import { getConfigFromUrl } from '../../utils/getConfigFromUrl';

const defaults = {
  colorsDark: {
    base: '#000000',
    accent: '#0D9AFF',
    text: '#FFFFFF'
  },
  colorsLight: {
    base: '#FFFFFF',
    accent: '#0D9AFF',
    text: '#000000'
  }
};

export const getDefaultPlayerColor = options => {
  return options.skin === 'dark' ? defaults.colorsDark : defaults.colorsLight;
};

const getInteractionAreaItemId = item => item.id || item.type;

export const getInteractionAreaItem = ({ videojsOptions }, item, _index, durationTime, onClick) => {
  const theme = get(
    videojsOptions,
    'interactionDisplay.theme.template',
    INTERACTION_AREAS_THEME.PULSING
  );

  const { configs, globalConfig } = getConfigFromUrl();

  const selectedHotspotConfig = configs.find(videoConfig => videoConfig.hotspotId === item.id);

  const isOnboarding = get(videojsOptions, 'isOnboarding', false);
  const isLastStepOnboarding = get(videojsOptions, 'isLastStepOnboarding', false);

  const hotspotWidgetThemeName = selectedHotspotConfig?.hotspotTheme || themes[0].name;
  const hotspotWidgetTheme = themes.find(item => item.name === hotspotWidgetThemeName);

  const widgetElement = document.querySelector('.vp-ia-widget');
  const widgetElementDataId = widgetElement ? widgetElement.getAttribute('data-id') : null;

  const style = {
    left: `${item.left}%`,
    top: `${item.top}%`,
    width: `${item.width}%`,
    height: `${item.height}%`,
    transitionDuration: `${durationTime}ms`,
    cursor: 'pointer',
    visibility: widgetElementDataId === getInteractionAreaItemId(item) ? 'hidden' : 'visible'
  };

  const isPreviewMode = window.location.pathname.includes('preview');

  return elementsCreator({
    tag: 'div',
    attr: {
      class: `${INTERACTION_AREAS_PREFIX}-item ${item.imageUrl ? '' : `theme-${theme}`}`,
      'data-id': getInteractionAreaItemId(item),
      'data-selected':
        !isPreviewMode &&
        !isLastStepOnboarding &&
        (selectedHotspotConfig?.hotspotId === globalConfig.selectedHotspotId || isOnboarding)
    },
    style,
    event: {
      name: 'click',
      callback: onClick
    },
    children: item.imageUrl
      ? [
          {
            tag: 'img',
            attr: { src: item.imageUrl }
          }
        ]
      : isOnboarding ||
        !selectedHotspotConfig?.hotspotTitle ||
        !selectedHotspotConfig?.hotspotCtaText
      ? []
      : [
          {
            tag: 'div',
            attr: { class: `${INTERACTION_AREAS_PREFIX}-area-marker` },
            children: [
              {
                tag: 'div',
                attr: { class: `${INTERACTION_AREAS_PREFIX}-marker-shadow` },
                style: {
                  background: hotspotWidgetTheme['background-color'],
                  backdropFilter: hotspotWidgetTheme['backdrop-filter'] ?? 'none',
                  '-webkit-backdrop-filter': hotspotWidgetTheme['backdrop-filter'] ?? 'none',
                  opacity: 0.4
                }
              },
              {
                tag: 'div',
                attr: { class: `${INTERACTION_AREAS_PREFIX}-marker-main` },
                style: {
                  background: hotspotWidgetTheme['button-color'],
                  cursor: 'pointer',
                  zIndex: 99999
                }
              }
            ]
          }
        ]
  });
};

export const percentageToFixedValue = (outOf, value) => outOf / (100 / +value);

export function clearAllTextTracks(videojs) {
  const remoteTracks = videojs.remoteTextTracks();
  const remoteTracksArray = Array.from(remoteTracks);
  remoteTracksArray.forEach(track => {
    videojs.removeRemoteTextTrack(track);
  });

  const localTracks = videojs?.textTracks();
  const localTracksArray = Array.from(localTracks);
  localTracksArray.forEach(track => {
    if (track.remove) {
      track.remove();
    }
  });
}

export const setInteractionAreasContainer = (videojs, newInteractionAreasContainer) => {
  const currentInteractionAreasContainer = getInteractionAreasContainer(videojs);

  if (currentInteractionAreasContainer) {
    const watermarkContainer = currentInteractionAreasContainer.querySelector('#watermark');
    const widgetElement = currentInteractionAreasContainer.querySelector('.vp-ia-widget');

    // Remove all children except the watermark container
    Array.from(currentInteractionAreasContainer.children).forEach(child => {
      if (child !== watermarkContainer && child !== widgetElement) {
        currentInteractionAreasContainer.removeChild(child);
      }
    });

    Array.from(newInteractionAreasContainer.children).forEach(child => {
      if (watermarkContainer) {
        currentInteractionAreasContainer.insertBefore(child, watermarkContainer);
      } else {
        currentInteractionAreasContainer.appendChild(child);
      }
    });

    if (!watermarkContainer) {
      currentInteractionAreasContainer.appendChild(newInteractionAreasContainer);
    }
  } else {
    // do not use element.append for ie11 support
    videojs.el()?.appendChild(newInteractionAreasContainer);
  }
};

const getInteractionAreaElementById = (interactionAreasContainer, item, index) =>
  interactionAreasContainer?.querySelector(
    `.vp-ia-item[data-id='${getInteractionAreaItemId(item, index)}']`
  );

export const updateInteractionAreasItem = (
  videojs,
  configs,
  interactionAreasData,
  previousInteractionAreasData,
  durationTime,
  onClick
) => {
  const interactionAreasContainer = getInteractionAreasContainer(videojs);

  forEach(interactionAreasData, (item, index) => {
    const itemElement = getInteractionAreaElementById(interactionAreasContainer, item, index);
    const itemId = getInteractionAreaItemId(item);
    const isExistItem = some(
      previousInteractionAreasData,
      i => getInteractionAreaItemId(i) === itemId
    );

    // in case the element of the item is in the dom and exist in the previous data , it update the element position
    if (isExistItem && itemElement) {
      const widgetElement = document.querySelector('.vp-ia-widget');
      const widgetElementDataId = widgetElement ? widgetElement.getAttribute('data-id') : null;

      const style = {
        left: `${item.left}%`,
        top: `${item.top}%`,
        width: `${item.width}%`,
        height: `${item.height}%`,
        transitionDuration: `${videojs.paused() ? 0 : durationTime}ms`,
        cursor: 'pointer',
        visibility: widgetElementDataId === getInteractionAreaItemId(item) ? 'hidden' : 'visible'
      };

      styleElement(itemElement, style);
      // if the element did not exist before , not in the dom and not in the previous data , it add a new element
    } else if (!isExistItem && !itemElement) {
      // do not use element.append for ie11 support
      interactionAreasContainer.appendChild(
        getInteractionAreaItem(configs, item, index, durationTime, event => {
          onClick({ event, item, index });
        })
      );
    }
  });

  // checking the previous data for element that should be removed if not exist in the new data object.
  forEach(previousInteractionAreasData, (item, index) => {
    const itemElement = getInteractionAreaElementById(interactionAreasContainer, item, index);
    const itemId = getInteractionAreaItemId(item);
    const shouldBeRemoved = !some(
      interactionAreasData,
      i => getInteractionAreaItemId(i) === itemId
    );

    if (itemElement && shouldBeRemoved) {
      // do not use element.remove for ie11 support
      itemElement.parentNode.removeChild(itemElement);
    }
  });
};

export const shouldShowAreaLayoutMessage = interactionAreasConfig => {
  const isLayoutEnabled = get(interactionAreasConfig, 'layout.enable', true);

  return (
    isLayoutEnabled && localStorage.getItem(INTERACTION_AREA_LAYOUT_LOCAL_STORAGE_NAME) !== 'true'
  );
};

export const createInteractionAreaLayoutMessage = (
  videojs,
  onClick = noop,
  showItAgainCheckbox = false
) => {
  let checked = false;

  const id = `checkbox_${Math.round(Math.random() * 10000)}`;

  const onClickInteractionAreaLayoutClick = checked => {
    localStorage.setItem(INTERACTION_AREA_LAYOUT_LOCAL_STORAGE_NAME, JSON.parse(checked));
    onClick();
  };

  const tracksContainer = elementsCreator({
    tag: 'div',
    attr: {
      class: `${INTERACTION_AREAS_CONTAINER_CLASS_NAME} ${INTERACTION_AREAS_PREFIX}-layout-message ${
        showItAgainCheckbox ? '' : 'clickable'
      }`
    },
    onClick: !showItAgainCheckbox ? () => onClickInteractionAreaLayoutClick(checked) : null,
    children: [
      showItAgainCheckbox && {
        tag: 'div',
        attr: { class: `${INTERACTION_AREAS_PREFIX}-layout-message-do-not-show` },
        children: [
          {
            tag: 'input',
            attr: {
              type: 'checkbox',
              class: `${INTERACTION_AREAS_PREFIX}-layout-message-checkbox`,
              id
            },
            event: {
              name: 'input',
              callback: event => {
                checked = event.target.checked;
              }
            }
          },
          {
            tag: 'label',
            attr: { class: `${INTERACTION_AREAS_PREFIX}-layout-message-checkbox-title`, for: id },
            children: 'Don׳t show it again'
          }
        ]
      }
    ].filter(i => i)
  });

  setInteractionAreasContainer(videojs, tracksContainer);
};

const getInteractionAreasContainer = videojs =>
  videojs.el()?.querySelector(`.${INTERACTION_AREAS_CONTAINER_CLASS_NAME}`);

export const removeInteractionAreasContainer = videojs => {
  const interactionAreasContainer = getInteractionAreasContainer(videojs);

  // do not use element.remove for ie11 support
  if (interactionAreasContainer) {
    Array.from(interactionAreasContainer.children).forEach(child => {
      if (!child.classList.contains('vp-ia-widget') && child.id !== 'watermark') {
        interactionAreasContainer.removeChild(child);
      }
    });
  }
};

export const setInteractionAreasContainerSize = (videojs, videoElement) => {
  const interactionAreasContainer = getInteractionAreasContainer(videojs);

  if (!interactionAreasContainer) {
    return;
  }

  const { videoHeight, videoWidth } = videoElement || {};

  if (!videoHeight && !videoWidth) {
    const videoTechElement = videojs.tech().el();

    const originalWidth = videoTechElement?.videoWidth;
    const originalHeight = videoTechElement?.videoHeight;

    const videoAspectRatio = originalWidth / originalHeight;
    const width = videoAspectRatio * videojs.currentHeight();

    interactionAreasContainer.style.width = `${videojs.currentWidth() < width ? '100%' : width}px`;

    interactionAreasContainer.style.height = `${videojs.currentWidth() / videoAspectRatio}px`;
  } else {
    const videoAspectRatio = videoWidth / videoHeight;
    const width = videoAspectRatio * videoElement.clientHeight;

    interactionAreasContainer.style.width = `${
      videoElement.clientWidth < width ? '100%' : width
    }px`;
    interactionAreasContainer.style.height =
      videoElement.clientWidth < width
        ? `${videoElement.clientWidth / videoAspectRatio}px`
        : '100%';
  }
};
