import React from 'react';
import { ContentWrapper } from './EditHotspot.styled';
import { useVideoUpload } from '../../hooks/useVideoUpload';
import { ColorSection } from './ColorSection';
import { PositionSection } from './PositionSection';

const StyleTabContent = () => {
  const { isUploading: isVideoLoading, isObjectTracking } = useVideoUpload();

  return (
    <ContentWrapper $isDisabled={isVideoLoading || isObjectTracking}>
      <ColorSection />
      <PositionSection />
    </ContentWrapper>
  );
};

export default StyleTabContent;
