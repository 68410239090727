import React from 'react';
import styled from 'styled-components';
import { OpenInNew } from '@clds/icon';

const WrapperBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  width: 142px;
  height: 114px;
  border-radius: 14.5px;
`; // styles match other svg icons

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  height: 71px;
  width: max-content;
  border-radius: 15px;
  background-color: rgba(70, 152, 247, 0.18);
`;

const Rectangle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 15px;
  background: rgba(178, 252, 174, 0.18);
`;

const LowerBox = styled.div`
  display: flex;
  padding: 0.2rem;
  align-items: center;
  justify-content: end;
  width: 44px;
  height: 28px;
  border-radius: 8px;
  background: rgba(70, 152, 247, 0.18);
`;

const StyledOpenInNewIcon = styled(OpenInNew)`
  cursor: pointer;
`;

const OpenUrlIcon = () => {
  return (
    <WrapperBox>
      <Container>
        <Rectangle />
        <LowerBox>
          <StyledOpenInNewIcon size="sm" />
        </LowerBox>
      </Container>
    </WrapperBox>
  );
};

export default OpenUrlIcon;
