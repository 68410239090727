export const parseVttDataToTimeframes = vttData => {
  const timeframes = vttData.split('\n\n').reduce((acc, item) => {
    item.split('\n').forEach((line, index, arr) => {
      if (line.includes('-->')) {
        const [startTime, endTime] = line.split(' --> ').map(timeToSeconds);
        const text = arr.slice(index + 1).join(' ');
        acc.push({ startTime, endTime, text });
      }
    });
    return acc;
  }, []);

  return timeframes;
};

const timeToSeconds = time => {
  const [hours, minutes, seconds] = time.split(':').map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
};

export const fetchAndParseVtt = async vttUrl => {
  try {
    const response = await fetch(vttUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const vttData = await response.text();
    return parseVttDataToTimeframes(vttData);
  } catch (error) {
    console.error('Error fetching or parsing VTT file:', error);
    return [];
  }
};
