import { useState } from 'react';
import { INTERACTIVE_VIDEO_API } from '../const';

async function defaultFetcher(url) {
  return await fetch(`${INTERACTIVE_VIDEO_API}/scrape-product-web-page`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ url })
  });
}

export const useScraper = ({ fetcher = defaultFetcher }) => {
  const [isScraping, setIsScraping] = useState(false);

  const scrapeWebsite = async url => {
    setIsScraping(true);

    try {
      const response = await fetcher(url);
      if (!response.ok) {
        throw new Error('Response not ok');
      }
      const data = await response.json();
      const { videoUrl, imageUrl } = data;

      const uploadData = await uploadMedia(videoUrl, imageUrl);

      return { ...data, ...uploadData };
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setIsScraping(false);
    }
  };

  const uploadMedia = async (videoUrl, imageUrl) => {
    try {
      const videoFormData = new FormData();
      videoFormData.append('videoUrl', videoUrl);

      const videoResponse = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
        method: 'POST',
        body: videoFormData
      });

      if (!videoResponse.ok) {
        throw new Error('Video upload failed');
      }

      const imageFormData = new FormData();
      imageFormData.append('imageUrl', imageUrl);

      const imageResponse = await fetch(`${INTERACTIVE_VIDEO_API}/upload`, {
        method: 'POST',
        body: imageFormData
      });

      if (!imageResponse.ok) {
        throw new Error('Image upload failed');
      }

      const videoData = await videoResponse.json();
      const imageData = await imageResponse.json();

      return { videoData, imageData };
    } catch (error) {
      console.error('Failed to upload media:', error);
    }
  };

  const getProductName = async url => {
    const response = await fetch(
      `${INTERACTIVE_VIDEO_API}/get-product-name-from-product-web-page`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url })
      }
    );
    const data = await response.json();
    return data;
  };

  return { scrapeWebsite, getProductName, isScraping };
};
