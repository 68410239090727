import { useState } from 'react';
import { toast } from 'react-toastify';
import { INTERACTIVE_VIDEO_API } from '../const';

const useTrackAnythingByHintAndCreateVTT = config => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreatingVtt, setIsCreatingVtt] = useState(false);

  const createVttByHint = async ({ objectNameToTrack }) => {
    setError(null);
    setIsCreatingVtt(true);

    try {
      const response = await fetch(`${INTERACTIVE_VIDEO_API}/track-anything-by-image-or-hint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          videoPublicId: config.publicId,
          hint: objectNameToTrack
        })
      });

      if (!response.ok) {
        setIsCreatingVtt(false);
        toast.error('Object detection failed.');
        throw new Error('Failed to process videoPublicId and hint');
      }

      const result = await response.json();
      const objectTrackingResultsUrl = result.trackAnythingResultsUrl;

      const vttResponse = await fetch(`${INTERACTIVE_VIDEO_API}/generate-vtt-from-track-anything`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          objectTrackingResultsUrl,
          hotspotId: config.hotspotId
        })
      });

      if (!vttResponse.ok) {
        setIsCreatingVtt(false);
        throw new Error('Failed to create VTT file');
      }

      const vttResult = await vttResponse.json();
      setIsCreatingVtt(false);

      return { [objectNameToTrack]: vttResult.vttUrl };
    } catch (error) {
      console.error('Error in createVttByHint:', error);
      setError(error);
    } finally {
      setIsUploading(false);
    }
  };

  return { isUploading, error, createVttByHint, isCreatingVtt };
};

export default useTrackAnythingByHintAndCreateVTT;
